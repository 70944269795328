import React from "react";
import './text.css';
const Ufa4 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2012/04-04 Уфа</h2>
            <div align={'center'}>
                <img src={require('../images/image-1.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
        </div>
    );
};

export default Ufa4;
