import React from "react";
import './text.css';
const Dvorkin = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Из блогов</h2>
            
            <p><a href="http://alexander-nv.livejournal.com/7075.html">А к нам Дворкин приезжал!</a> </p>
            

            
            <p>А. Королев</p>
            

            
            <p>April 3rd, 23:11</p>
            

            
            <p>К нам в
                город приезжал известный сектовед, профессор богословия А.Л. Дворкин. Будучи по
                своему основному образованию историком, Александр Леонидович известен, прежде
                всего, как борец с сектами и сектантством. Каким образом, он с ними борется?
                Пишет книги, статьи, ездит с лекциями по разным городам, судится с различными
                людьми, «сектами».</p>
            

            
            <p>Что же
                Александр Леонидович приводит в пример характерных черт деструктивных культов?
                У членов сект, как говорит Дворкин, меняется круг друзей, семья отходит на
                второй или даже на третий или пятый план. Но это еще не самое страшное.&nbsp;Самое
                страшное начинается, когда люди начинают нести туда большие средства! Иногда
                даже продают свое имущество и все относят в секту! Да, со стороны человека
                неверующего, светского это действительно может показаться чем-то опасным и
                отталкивающим. Но разве у человека, когда он приходит в Православную Церковь,
                становится церковным человеком, разве в этом случае у него не меняется круг
                друзей? Мне кажется, это просто очевидная вещь. Каждый, кто приходил
                по-настоящему в Церковь, становился практикующим христианином, у такого человека
                всегда менялся круг друзей. Он или покидал или сильно ограничивал свое
                присутствие в прежних кампаниях. И все это, как правило, вызывало нарекания
                окружающих, близких, друзей. Человека в таких случаях начинают обвинять в
                сектантстве, религиозном фанатизме. Но все это верующий обычно слышит от людей
                неверующих, людей нецерковных! Но очень странно подобное слышать от профессора
                богословия, православного христианина, много лет находящегося в Церкви. Далее
                про семью. Разве действительно семья не должна отойти на второй план, у
                человека, пришедшего к Богу в Церковь? Да, для человека неверующего это может
                показаться дикостью и сектантством, но Священное Писание об этом прямо говорит.</p>
            

            
            <p><em>&nbsp;Если
                кто приходит ко Мне и не возненавидит отца своего и матери, и жены и детей, и
                братьев и сестер, а притом и самой жизни своей, тот не может быть Моим учеником</em></p>
            

            
            <p><em>(Лук.14:26).</em></p>
            

            
            <p><em>И враги человеку - домашние его. Кто любит
                отца или мать более, нежели Меня, не достоин Меня; и кто любит сына или дочь
                более, нежели Меня, не достоин Меня</em></p>
            

            
            <p><em>(Матф.10:36,37).</em></p>
            

            
            <p><em>&nbsp;Другой же из учеников Его сказал
                Ему: Господи! позволь мне прежде пойти и похоронить отца моего. Но Иисус сказал
                ему: иди за Мною, и предоставь мертвым погребать своих мертвецов.</em></p>
            

            
            <p><em>(Матф.8:21,22)</em></p>
            

            
            <p>С
                позиции Дворкина Иисус Христос оказывается лидером деструктивной секты! Он
                призывает к тому, чтобы служение Богу, Его Царству было центральным в жизни
                человека! Господь предупреждает, что серьезным препятствием для этого могут
                быть, прежде всего, родные и близкие, что Его ученикам надо будет смело и
                решительно расставить приоритеты, отодвинув семью и близких на второй план!
                Безусловно, с позиции неверующего человека – это ярко выраженное сектантское
                поведение. К сожалению, с позиции Дворкина тоже.</p>
            

            
            <p>А вот
                отрывок из Евангелия, который Александр Леонидович может смело вписать в очередную
                редакцию своего учебника по сектоведению как&nbsp;ярко выраженный пример
                проявления тоталитарно – деструктивной секты!</p>
            

            
            <p><em>&nbsp;И
                пришли Матерь и братья Его и, стоя [вне] дома, послали к Нему звать Его. Около
                Него сидел народ. И сказали Ему: вот, Матерь Твоя и братья Твои и сестры Твои,
                [вне] дома, спрашивают Тебя. И отвечал им: кто матерь Моя и братья Мои? И
                обозрев сидящих вокруг Себя, говорит: вот матерь Моя и братья Мои; ибо кто
                будет исполнять волю Божию, тот Мне брат, и сестра, и матерь (Мар.3:31-35).</em></p>
            

            
            <p>Просто
                типичный случай! Близкие приходят вызволять человека из какого-то опасного, на
                их взгляд, движения! Евангелист Марк при этом еще не скрывает, что они Его
                считали за сумасшедшего (понятно, пребывание в секте деформирует сознание).
                Иисус же демонстрирует, что традиционные родственные связи для него теперь не
                имеют такого значения, что для него важнее связи духовные, с членами Своего
                движения! Как же так, человек разрывает все нормальные круги общения,
                отодвигает семью на второй план! Жаль, что в то время в Палестине не было
                Александра Леонидовича, а то бы он смело обличил молодое мессианское движение
                сына плотника из Галилеи как тоталитарную секту.</p>
            

            
            <p>Что же
                еще является, по мнению Александра Леонидовича, характерным признаком секты?
                Это, конечно же, жертвование больших денежных средств. Как же тогда должен
                выглядеть Господь, поставивший в пример женщину, которая принесла все свое
                пропитание на жертвенник (Мк 12,42-44)? Члены иерусалимской общины, продававшие
                свои имения и несшие все к ногам апостолов, согласно Дворкину, совершенно
                «обработанные» члены какого-то страшного деструктивного культа! А апостолы,
                принимавшие все эти пожертвования? В данном случае – они классический образ
                лидеров сект, обирающих людей. Глава 5 Деяний, описывающая эпизод с Ананией и
                Сапфирой – классический пример манипулирования сознанием с целью оболванивания
                людей. Вы скажете кощунство? Нет, просто мы постарались посмотреть на некоторые
                места Священного Писания, применяя к нему то, что говорил на своей лекции
                А.Л.Дворкин.</p>
            

            
            <p>Какое
                еще впечатление сложилось от лекции Дворкина? Нагнетание страха и агрессии. То
                есть манипулирование этими двумя качествами, которые в искореженных семидесятилетним
                советским режимом душах современных россиян сидят пока еще довольно прочно. И
                вот если Церковь призвана помогать избавляться от всего этого темного наследия,
                от всех духовных ран, нанесенных безбожным режимом, то Дворкин способствует
                противоположному процессу. Он актуализирует все эти темные потенции. Его
                выступления, деятельность очень напоминают деятельность советской пропаганды по
                своем духу и содержанию. Я уже не говорю о том, что это просто некорректно
                постоянно с кафедры лектора именовать представителей иных конфессий сектантами,
                зная или предполагая, что они могут находиться в данный момент в зале.
                Постоянное деление всех людей на "своих" и "чужих". На нас
                "правильных" и их "сектантов". Как в сталинские времена
                человек определялся по классовому признаку, так из уст Дворкина сейчас он
                определяется по конфессиональному. Поэтому есть «кулаки», «буржуи». Это уже не
                люди, это классовые враги, с которыми нужно бороться. Неужели Александр
                Леонидович даже на секунду не может задуматься над тем, что «сектант» может
                быть настоящим живым человеком, искренне верующим в Бога, который имеет свою,
                зачастую сложную, судьбу, свои личные отношения со Христом? Неужели Дворкин ни
                на секунду не может представить, что среди того или иного собрания, которое он
                именует сектой, может действовать Дух Святой, который дышит, как говорит
                Христос, где хочет (сам Дух хочет, а не Александр Леонидович), что люди там
                могут стараться жить по Евангелию, приносить какие-то плоды жизни по вере? Или
                можно с полной уверенностью сказать, что Христа там нет, Дух Святой там не
                дышит?</p>
            

            
            <p>Люди,
                которые сейчас являются членами неправославных церквей у нас в России, в
                большинстве своем являются крещенными в Православной Церкви, многие из них
                пытались делать какие-то шаги в Православии. И, конечно же, для нас,
                православных, это и печаль и наша прямая вина, что люди, крещенные у нас, у нас
                не остались, а ушли. Но Дворкин делает все, чтобы навсегда сжечь все мосты для
                этих людей, чтобы они уже точно никогда не вернулись в Православие! Да, мы
                часто говорим, что Православная традиция гораздо глубже и богаче, чем
                протестантская. Но об этом нужно не говорить, размахивая аргументами, это нужно
                являть жизнью. Мы действительно призваны свидетельствовать о Православии среди
                других христианских конфессий, но Дворкин и здесь перекрывает любые возможности
                диалога и свидетельства.</p>
            

            
            <p>Ну, и
                конечно же, центральной мыслью лекции Дворкина была мысль о происках
                вездесущего Госдепа США! Ничего не поменялось! Как в советские времена вокруг
                были американские, английские шпионы, которые изнутри пытались разрушить
                страну, и на которых работали, судя по количеству осужденных по данной статье,
                миллионы советских граждан, так и сейчас вокруг одни шпионы. И все эти «секты»,
                конечно же, ничто иное, как попытка развалить нашу страну. Просто поразительно,
                насколько похожей остается риторика! Создание образа врага, наращивание страха
                и ненависти. Да, такими людьми гораздо проще манипулировать и управлять. Ни
                этого ли добивается Дворкин? Чьи он в этом случае интересы представляет?</p>
            

            
            <p>Если
                враги есть снаружи, то они, безусловно, должны быть тогда и внутри! И вот тут
                Александр Леонидович берет на себя функции инквизитора, выискивая ереси и секты
                внутри Церкви. Преображенское братство и Свято-Филаретовский институт, по его
                мнению – это тоже структура, связанная с Госдепом США. Александр Леонидович
                сказал, что он не может, конечно, напрямую назвать духовного попечителя
                братства и ректора института священника Георгия Кочеткова агентом ЦРУ, но что
                о. Георгий явно и сильно поддерживается Западом – это для Дворкина очевидно. На
                возражение о том, что институт имеет лицензию Синодального отдела РПЦ МП,
                Александр Леонидович ответил тем, что данную лицензию назвал «дежурным
                благословением». С его слов в нашей Церкви лицензия, выданная синодальным
                отделом, свидетельствующая о православии учебного заведения, является просто
                «дежурной», и ее получить может кто угодно! Далее Александр Леонидович просто
                приводил информацию не соответствующую действительности. Например, что о.
                Георгий запрещает причащаться всем людям, не прошедшим оглашение у себя в
                братстве. Слава Богу, что в зале находились двое членов братства, которые во
                время оглашения причащались еженедельно и смогли об этом во всеуслышание
                засвидетельствовать. Потом Дворкин говорил о том, что о. Георгий делит всю
                Церковь на полных и неполных членов. Что, мол, неполные члены – это разные
                бабушки, которые являются людьми чуть ли не второго сорта. Но Александр
                Леонидович, по всей видимости, не знает, что в братстве есть специальная
                система оглашения для людей пожилых и тяжелобольных, что в братстве занимаются
                воцерковлением людей с задержкой развития, с различными психическими
                отклонениями. В братстве проходят оглашение и поступают в колледж при СФИ люди
                преклонного возраста и есть случаи, когда они заканчивали обучение на отлично.
                В братстве за одним столом можно увидеть и солидного начальника и бабушку-пенсионерку.
                На оглашение собираются люди, которые при других обстоятельствах мало где бы
                встретились. Но все это, повторюсь, Александру Леонидовичу мало известно, и
                создается ощущение, что он и не стремится все это узнать. Дворкин не забыл
                упомянуть и агапы, называя их повторением Евхаристии. Хотя как историк и
                богослов он должен знать, что это древняя традиция Церкви, которой и сейчас
                существует каноническое обоснование. И всегда, возможно, за исключением самых
                ранних христианских времен, в Церкви отличали агапы от Евхаристии. Если же
                Александр Леонидович этого различения не видит, то это все-таки проблема
                Александра Леонидовича, а не братства и о. Георгия.</p>
            

            
            <p>Каковы
                же могут быть плоды подобных лекций профессора Дворкина? На мой взгляд, самые
                неутешительные. Агрессия, страх, ксенофобия и, как результат, разделение,
                раздоры и расколы в нашей Церкви, после многолетних гонений нуждающейся в
                мирном диалоге как внутри себя самой, так и с внешними. Но все это, на самом
                деле, только лишь следствия более глубинного процесса, происходящего в мире и
                Церкви, провозвестником которого и является, пусть, скорее всего, и не
                осознанно, профессор Дворкин. Это в своей глубине и есть самый настоящий
                модернизм, обмирщение. Да, да, именно это. Модернизм может рядиться в
                консервативные одежды, ратовать за незыблемость традиции, за истинность веры,
                но на самом деле все это лишь прикрытие, маска, за которой Евангельская правда
                выхолащивается и изгоняется, живой Бог предается забвению, ценности мира сего,
                который есть «похоть очей и гордость житейская», ставятся в центре жизни. И все
                это пытается спрятаться за ревностью о чистоте веры и пр. И с таких обмирщенных
                позиций Евангельская Весть кажется страной и страшной. Христу разрешается
                присутствовать только в красивых проповедях и благочестивых рассказах, Он
                изгоняется из реальной жизни. Евангельским максимам разрешается жить только на
                бумаге, любая попытка их реализации вызывает яростные окрики, обвинения в
                сектантстве. Христа и Церковь пытаются сделать благочестивым приложением к
                комфортной буржуазной устроенности жизни, эгоцентричной по своей сути. Но глас
                Божий пробивается через все эти тенденции и наслоения, он все равно будоражит
                сердца и умы людей. Снова и снова люди взыскуют «жизни с избытком», снова и
                снова они слышат призыв Христа следовать за Ним, и действительно пытаются быть
                с ним, быть Его учениками. Только очень жаль, что, как и две тысячи лет назад,
                они претерпевают гонения прежде всего не от внешних безбожных и богоборческих
                сил, а от своих же братьев и сестер, хранителей религиозности, которая, как
                известно, может быть тем, что соединяет с Богом, а может быть тем, что надежно
                от Него защищает. Очень хотелось бы призвать Александра Леонидовича
                пересмотреть то, как он пытается служить Богу и Церкви. Очень бы хотелось, чтобы
                Господь ему открыл ту неправду, что содержится в его деятельности и на самом
                деле содействует разорению Церкви, препятствует осуществление Божьего замысла
                на земле. Хотелось бы призвать всех христиан, и православных и неправославных,
                особенно тех, в чей адрес Александр Леонидович произносил несправедливую
                критику, призвать всех помолиться за раба Божьего Александра, чтобы Господь
                открыл ему его заблуждения, дал бы ему возможность в полноте и правде служить
                Христу и приносить добрый плод для Бога и людей. </p>
            
        </div>
    );
};

export default Dvorkin;
