import React from "react";
import './text.css';
const Velsk7 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2014/04-08  Вельск</h2>
            
            <p class="has-text-align-right">Его
                Преосвященству, </p>
            

            
            <p class="has-text-align-right">Преосвященному
                ВАСИЛИЮ,</p>
            

            
            <p class="has-text-align-right">Епископу
                Котласскому и Вельскому</p>
            

            
            <p class="has-text-align-right">прихожан
                Свято-Успенского храма</p>
            

            
            <p class="has-text-align-right">города
                Вельска</p>
            

            
            <p class="has-text-align-center">ПРОШЕНИЕ.</p>
            

            
            <p><strong>ВАШЕ
                ПРЕОСВЯЩЕНСТВО, благословите!</strong></p>
            

            
            <p>Обращаются
                к ВАМ прихожане Свято-Успенского храма города Вельска. Мы являемся прихожанами
                Вельского храма последние 20 лет. На наших глазах и с нашим участием оживал
                Вельский приход после 70-ти лет безбожного глумления. </p>
            

            
            <p>Мы
                пришли в Вельский храм в 1991-м году, приняли Крещение из рук приснопоминаемого
                протоиерея Валентина Таразевича. Благодарную память об отце Валентине храним в
                своих сердцах. Однако в те годы в городе Вельске не было возможности получить
                качественное церковное образование, а нам хотелось жить церковно и верить
                разумно. Встреча со священником Георгием Кочетковым и Преображенским братством,
                которая произошла в те же годы, помогла нам войти в жизнь Православной Церкви.</p>
            

            
            <p>За
                прошедшие двадцать лет мы всегда чувствовали ответственность за единственный
                действующий храм в родном городе. И мы сами, и наши родные, знакомые, близкие,
                друзья регулярно помогали и помогают приходу в хозяйственных делах: в 1990-е
                годы, когда храм отапливался печами, все вопросы с закупкой, колкой, уборкой
                дров лежали на наших плечах. Нашими силами было устроено озвучивание храма. До
                сих пор вопросы, связанные с электричеством, решаются с нашим участием. На наши
                средства был куплен панихидный столик. Мы всегда принимаем участие в сборе
                пожертвований, в том числе и на покупку кирпичей для строительства воскресной
                школы. О покупке моющих средств для уборки храма даже говорить не хочется.</p>
            

            
            <p>Все
                эти годы мы исправно посещали храмовые богослужения: всенощные по субботам, а
                литургии по воскресеньям. В праздничных службах, которые совершаются в рабочие
                дни, участвовали по возможности, потому что среди нас много работающих людей.</p>
            

            
            <p>Однако
                в последние недели нам, постоянным прихожанам Вельского храма, довелось стать
                свидетелями странного зрелища. Наш настоятель протоиерей Андрей Ермилов стал
                распространять непроверенные, а порой и просто клеветнические слухи о тех
                вельских прихожанах, кто пришёл в Русскую Православную Церковь благодаря
                пастырским трудам отца Георгия Кочеткова и Преображенского братства. Мы видим,
                что от недели к неделе агрессия отца Андрея возрастает и передаётся тем, кого
                Господь называет в Евангелии «малые сии» (Мф. 18:6). Особенно горько нам
                слышать, как отец Андрей всюду ссылается на ВАШЕ архипастырское благословение.</p>
            

            
            <p>30
                марта 2014 года, в неделю преподобного Иоанна Лествичника, после литургии отец
                Андрей объявил, что отныне запрещено причащаться «всем последователям
                священника Георгия Кочеткова» и что вопрос о допуске к причастию этих людей отныне
                будет решать епископ Василий.</p>
            

            
            <p>Поскольку
                эти слова вызвали смущение среди многих прихожан, то мы, желая мира церковного,
                хотим прояснить ситуацию.</p>
            

            
            <p>Не
                отрицая и не скрывая факта нашей благодарности отцу Георгию Кочеткову и
                Преображенскому братству в деле нашего воцерковления, мы ответственно заявляем,
                что не являемся &nbsp;«последователями учения
                священника Георгия Кочеткова». Мы — православные христиане, чада Русской
                Православной Церкви Московского Патриархата.</p>
            

            
            <p>Во
                избежание недоразумений и нестроений просим ВАС, ВАШЕ ПРЕОСВЯЩЕНСТВО, ответить
                на несколько наших вопросов:</p>
            

            
            <ol><li>Не
                злоупотребяет ли отец Андрей Ермилов ВАШИМ архипастырским доверием, не
                приписывает ли отец Андрей ВАМ каких-то неведомых ВАМ желаний? В самом
                деле, нам трудно представить, что ВЫ, ВАШЕ ПРЕОСВЯЩЕНСТВО, можете
                благословить отца Андрея на распространение клеветнических слухов в наш
                адрес и на разделение прихожан на враждующие части?! Тем более, что сам
                отец Андрей частным образом повторяет такие слова: «Даже если архиерей благословит
                их причащать, я буду действовать, не взирая на него. Я пойду до конца. Я —
                такой, я — решительный!» </li><li>Не
                можем мы поверить и в то, что ВЫ, ВАШЕ ПРЕОСВЯЩЕНСТВО, могли запретить
                причащаться людям лишь на том основании, что они стали православными христианами
                благодаря встрече со священником Георгием Кочетковым? Мы не можем поверить
                и в то, что запрет на причащение ВЫ могли дать на основании слухов, некачественных
                изданий, не проверив информацию, не встретившись ни с кем из нас лично.</li><li>Если
                всё-таки Вельский благочинный протоиерей Андрей Ермилов строго выполняет
                то послушание, которое ВЫ на него возложили, то тогда просим:<ol type="a"><li>Официально
                    опубликовать список тех лиц, кому запрещено причащаться в Свято-Успенском
                    храме города Вельска. </li><li>Поскольку
                    мы не можем предположить, что такое решение могло приниматься с
                    пренебрежением к каноническому преданию Матери-Церкви, то просим Вас
                    сделать подробное каноническое обоснование данного запрета, а именно:</li></ol></li></ol>
            

            
            <p>а)
                Чётко сформулировать, какие конкретные каноны, догматы и традиции Православной
                Церкви нарушаются на Вельском приходе людьми, пришедшими в Церковь благодаря
                пастырской деятельности священника Георгия Кочеткова.</p>
            

            
            <p>б)
                Какие епитимии и прещения канонически налагаются церковью за эти нарушения и на
                какие сроки?</p>
            

            
            <p><em>Испрашивая ВАШИХ святых молитв,
                архипастырского благословения, прихожане Свято-Успенского храма г. Вельска:</em></p>
            

            
            <p><em>Привалова Т.</em></p>
            

            
            <p><em>Привалова А.</em></p>
            

            
            <p><em>Холзакова В.</em></p>
            

            
            <p><em>Кондакова Т.</em></p>
            

            
            <p>8
                апреля 2014 год</p>
            
        </div>
    );
};

export default Velsk7;
