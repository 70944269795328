import React from "react";
import './text.css';
const Hanty3 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2013/05-21 Нижневартовск</h2>
            <div align={'center'}>
                <img src={require('../images/image-10.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>

        </div>
    );
};

export default Hanty3;
