import React from "react";
import './text.css';
const Letter9 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2008/02/22</h2>
            <p>Московская патриархия<br></br>
                Евсевий<br></br>
                Псковский и Великолукский<br></br>
                <br></br>
                    180006 г. Псков<br></br>
                    ул. Л.Поземского, 83а, тел./факс 72-06-22<br></br>
                    <br></br>
                        № 7<br></br>
                        22 февраля 2008 г.<br></br>
                        <br></br>
                            УКАЗ<br></br>
                            <br></br>
                                протоиерею Павлу Анатольевичу Адельгейму<br></br>
                                <br></br>
                                    Настоящим, в связи со сложившейся непрекращающейся крайне нетерпимой
                                    обстановкой в храме Святых Жен-Мироносиц г. Пскова и вашим отказом от участия в
                                    заседании Псковского Епархиального суда для разрешения возникшего конфликта
                                    между вами и клириком вашего храма священником Владимиром Будилиным, учитывая
                                    также вашу открытую неприязнь к предшествующим клирикам, вы освобождаетесь от
                                    должности настоятеля храма Святых Жен-Мироносиц г. Пскова и остаетесь клириком
                                    того же храма.<br></br>
                                    <br></br>
                                        АРХИЕПИСКОП ПСКОВСКИЙ И ВЕЛИКОЛУКСКИЙ</p>
            
        </div>
    );
};

export default Letter9;
