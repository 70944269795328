import React from "react";
import './text.css';
const Ufa8 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2013/5-23 Уфа</h2>
            <div align={'center'}>
                <img src={require('../images/image-6.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
            <p></p>
            <div align={'center'}>
                <img src={require('../images/image-7.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
            <p></p>
            <div align={'center'}>
                <img src={require('../images/image-8.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
        </div>
    );
};

export default Ufa8;
