import React from "react";
import './text.css';
const Letter10 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2005/03/13</h2>
            
            <p>Его Высокопреосвященству
                Высокопреосвященному
                 Евсевию, архиепископу
                Псковскому и&nbsp;Великолукскому.</p>
            

            
            <p>от&nbsp;священника Павла Адельгейма </p>
            

            
            <p><strong>Покаянное обращение перед
                вступлением в&nbsp;Великий пост</strong></p>
            

            
            <p><em>«Мир имейте между собою»(Мк.9, 50).</em></p>
            

            
            <p>Ваше Высокопреосвященство!
                Третий раз наступает Прощёный день.
                Третий раз мы&nbsp;с&nbsp;Вами не&nbsp;примирёнными вступаем в&nbsp;Великий
                пост. 
                Третий раз Слово Божие воздвигает перед нами неизменное препятствие: </p>
            

            
            <p>«<em>если ты&nbsp;принесёшь дар твой к&nbsp;жертвеннику и&nbsp;там вспомнишь,
                что брат твой имеет что-нибудь против тебя, оставь там дар твой перед
                жертвенником и&nbsp;пойди прежде примирись с&nbsp;братомтвоим, и&nbsp;тогда
                приди и&nbsp;принеси дар твой» (Мф.5, 23−24).</em></p>
            

            
            <p>Мои бесчисленные обращения к&nbsp;Вам с&nbsp;молитвой о&nbsp;прощении
                и&nbsp;примирении не&nbsp;имели успеха. Отвергая моё покаяние, Вы&nbsp;лишаете
                радости небо, ибо <em>«на&nbsp;небесах более радости будет об&nbsp;одном
                    грешнике кающемся, нежели о&nbsp;девяноста девяти праведниках, не&nbsp;имеющих
                    нужды в&nbsp;покаянии» (Лк.15, 7).</em> Это слова Христа Спасителя.</p>
            

            
            <p>Ваша непримиримость печалит и&nbsp;повергает в&nbsp;уныние. Если епископ
                не&nbsp;может преодолеть ущемлённое самолюбие, как спрашивать с&nbsp;клириков
                и&nbsp;мирян? Как совместить непримиримость епископа со&nbsp;словами Евангелия?
                духом богослужения и&nbsp;святоотеческой традиции? <em>«Христос есть мир наш,
                    соделавший из&nbsp;обоих одно и&nbsp;разрушивший&nbsp;— стоявшую посреди преграду,
                    упразднив вражду Плотию Своею…примирив обоих с&nbsp;Богом посредством креста,
                    убив вражду на&nbsp;нем"(Еф.2,14−16).</em></p>
            

            
            <p>Христос указал путь к&nbsp;примирению:
                <em>«Сказал Иисус ученикам: если&nbsp;же согрешит против тебя брат твой,
                    выговори ему; и&nbsp;если покается, прости ему. И&nbsp;если семь раз
                    в&nbsp;день согрешит против тебя, и&nbsp;семь раз в&nbsp;день обратится,
                    и&nbsp;скажет: каюсь,&nbsp;— прости ему» (Лк.17, 1, 3−4). </em>Возможно&nbsp;ли
                отречься от&nbsp;этого пути?</p>
            

            
            <p>Каждый раз я&nbsp;повторяю слова, заповеданные Христом Спасителем: «каюсь,
                прости меня». Они остаются безответными: не&nbsp;услышанными,
                не&nbsp;принятыми, не&nbsp;понятыми, осуждёнными. Вы&nbsp;хотели видеть моё
                покаяние в&nbsp;СМИ. Я&nbsp;исполнил Ваше требование: в&nbsp;газете «МК»
                напечатано моё «покаянное письмо». В&nbsp;газете «Псковская правда» напечатано
                второе покаянное письмо. Что ещё могу сделать для примирения? </p>
            

            
            <p>В&nbsp;примирении участвуют две стороны. Одна просит или не&nbsp;просит
                прощения. Другая прощает или не&nbsp;прощает. Духовная жизнь представляет
                царство свободы. Ищущий примирения не&nbsp;может принудить другую сторону.
                Примирение нельзя навязать силой. Прощение дарят. Но, отказав просящему
                прощения, невозможно молиться: «Отче наш, оставь нам долги наши, как
                и&nbsp;мы&nbsp;оставляем должникам нашим». Если мы&nbsp;не&nbsp;оставляем,
                о&nbsp;чём просим Бога? Вы&nbsp;вынесли мне вечное осуждение, не&nbsp;ожидая
                суда Божия: </p>
            

            
            <p>—&nbsp;<em>определил вам место со&nbsp;всеми клеветниками и&nbsp;диаволу
                уготованное им&nbsp;от&nbsp;вечности</em>. («Благодатные лучи N2 2003
                г.&nbsp;стр.14). Христос учил поступать не&nbsp;так:</p>
            

            
            <p>—&nbsp;<em>Иисус, обратившись к&nbsp;ученикам, запретил
                им&nbsp;и&nbsp;сказал: не&nbsp;знаете, какого вы&nbsp;духа; ибо Сын
                Человеческий пришёл не&nbsp;погублять души человеческие, а&nbsp;спасать"
                (Лк.9, 56).</em></p>
            

            
            <p>Вы&nbsp;назвали меня «клеветником, лжецом, лицемером, фарисеем,
                законопреступником, слугой диавола» («Бл. Лучи», ibid), не&nbsp;указав
                в&nbsp;чём моя вина, не&nbsp;сумев обозначить её&nbsp;конкретное содержание.
                Ваши ярлыки: «подлость, клевета, ложь, лицемерие, фарисейство, сатанинские
                методы лжи и&nbsp;обмана, исполнение воли сатаны» («Бл. Лучи», ibid) наклеены
                на&nbsp;пустом флаконе: письмо не&nbsp;обосновало данные определения. Без
                глагола, обозначающего действие, существительные превращаются в&nbsp;оскорбления,
                подлежащие Евангельскому суду: </p>
            

            
            <p><em>«Всякий, гневающийся на&nbsp;брата своего напрасно, подлежит суду;
                кто&nbsp;же скажет брату своему: „ничтожество“, подлежит синедриону; а&nbsp;кто
                скажет: „безумный“, подлежит геенне огненной» (Мф.5, 22).</em></p>
            

            
            <p>С&nbsp;Вашего благословения в&nbsp;«Псковской правде» и&nbsp;Епархиальных
                листовках опубликован образец покаяния, приносимого обиженному епископу.
                Епархиальный духовник призывает меня написать в&nbsp;газету письмо
                по&nbsp;этому образцу. Образец построен на&nbsp;самобичевании и&nbsp;унижении
                человеческого достоинства: </p>
            

            
            <p><em>«Я, проклятая гадина и&nbsp;мразь, оскорбил Вашу святыню,
                Высокопреосвященнейший Владыка! За&nbsp;совершённую подлость мне не&nbsp;место
                в&nbsp;человеческом обществе. Мне место в&nbsp;выгребной яме…» и&nbsp;прочее.
                    («Псковсая правда» N202 за&nbsp;2004&nbsp;г.)</em></p>
            

            
            <p>Вы&nbsp;требуете, что&nbsp;бы я&nbsp;просил прощения по&nbsp;этому ритуалу.
                В&nbsp;удовлетворение самолюбия Вы&nbsp;требуете жертвоприношения: поругать
                и&nbsp;унизить человеческое достоинство клирика, потерять человеческое лицо.
                Когда Державин пишет: «Я&nbsp;-царь, я&nbsp;-раб; я&nbsp;-червь, я&nbsp;-Бог»,
                он&nbsp;не&nbsp;выходит из&nbsp;пределов христианской антропологии: «образ есмь
                неизречнныя Твоея славы, аще и&nbsp;язвы ношу прегрешений» (Тропарь
                из&nbsp;чина погребения). Газетный вариант покаяния выражает антропологию
                манихеев, которую православные христиане не&nbsp;исповедуют. </p>
            

            
            <p>Христианское примирение может иметь только одну цель: взаимное взыскание
                любви. Пока плач о&nbsp;грехе выражает состояние души, он&nbsp;оправдан. Если
                он&nbsp;является требованием обиженного, то&nbsp;превращается в&nbsp;шантаж.
                Это не&nbsp;христианский образ покаяния, а&nbsp;языческое жертвоприношение.
                Христианин не&nbsp;должен требовать платы за&nbsp;прощение. Иначе оно теряет
                нравственную ценность. Это будет не&nbsp;прощение, а&nbsp;возмещение морального
                ущерба, то&nbsp;есть не&nbsp;нравственный, а&nbsp;правовой акт. Происходит
                подмена: подлинная безмерность вины перед Богом подменяется фальшивой безмерностью
                вины перед человеком. Вина имеет предел. Евангелие не&nbsp;даёт оснований
                отказать просящему в&nbsp;прощении. </p>
            

            
            <p>В&nbsp;литургическом предании мы&nbsp;постоянно именуем себя «грешными
                и&nbsp;недостойными рабами», но&nbsp;не&nbsp;человека, а&nbsp;Бога.
                «Не&nbsp;делайтесь рабами человеков»&nbsp;— предостерегает апостол (1Кор.7,
                23). Мы&nbsp;подчёркиваем своё недостоинство перед Его Святыней. Между
                человеком и&nbsp;Богом всегда остаётся нравственная пропасть.</p>
            

            
            <p>Я- грешник, а&nbsp;Он&nbsp;— свят и&nbsp;пресвят. Эта пропасть
                преодолевается с&nbsp;одной стороны подвигом, а&nbsp;с&nbsp;другой&nbsp;— содействием
                благодати. Преподобные самоуничижались перед Богом, достигая единения
                с&nbsp;Ним. Это не&nbsp;то&nbsp;же самое, что топтать собственное достоинство
                ради человекоугодничества перед епископом. В&nbsp;такой форме выражается
                не&nbsp;покаяние, а&nbsp;«ложное смирение, которое паче гордости».
                В&nbsp;церковной традиции человекоугодие осознаётся как грех. Грех роняет
                человеческое достоинство. Мы&nbsp;называем это грехопадением. Покаяние
                возвышает человека, возводит на&nbsp;высоту, с&nbsp;которой он&nbsp;упал. Если
                покаяние перед Богом должно возвышать человеческое достоинство, тем паче
                покаяние перед человеком не&nbsp;может его унижать.</p>
            

            
            <p>Воздавая честь лицу, носящему священный сан, следует сохранять меру. Когда
                настоятель собора с&nbsp;амвона восклицает: «Владыко, Вы&nbsp;— правая рука
                Бога!», это не&nbsp;лесть, а&nbsp;кощунство. Кто может воссесть «одесную Отца»?
                Это место принадлежит Единородному Сыну Божию. Только Ему одному! Ни&nbsp;один
                епископ не&nbsp;восхитит престол Христов «одесную Отца». Даже в&nbsp;ритуальном
                обожествлении существуют границы дозволенного, и&nbsp;преступать их&nbsp;нельзя.
                Неужели ритуальное обожение стирает действительное различие между епископом
                и&nbsp;Богом?</p>
            

            
            <p>Факты, приведённые в&nbsp;моей книге, задели Ваше самолюбие.
                Вы&nbsp;не&nbsp;можете переступить через него и&nbsp;простить. В&nbsp;книге нет
                клеветы. Она написана не&nbsp;в&nbsp;обиду Вам, а&nbsp;в&nbsp;защиту Церкви.
                Ваше имя упоминается только в&nbsp;подписанных Вами Указах. Если
                я&nbsp;не&nbsp;прав, укажите мою ошибку. Я&nbsp;признаю её,
                и&nbsp;мы&nbsp;примиримся, как заповедал Христос. Я&nbsp;с&nbsp;радостью
                покаюсь, но&nbsp;не&nbsp;соглашусь на&nbsp;самооговор. Чекисты принуждали
                к&nbsp;самооговору пытками и&nbsp;остались злодеями на&nbsp;тёмных страницах
                русской истории. Это моя личная драма: они опорочили и&nbsp;замучили двух моих
                дедов, отца, мать, их&nbsp;друзей и&nbsp;сослуживцев. В&nbsp;течение года меня
                держали в&nbsp;тюрьме госбезопасности, требуя самооговора и&nbsp;отречения
                от&nbsp;Христа в&nbsp;СМИ. Зачем? Потерять лицо, лишиться христианского
                достоинства, принести жертву чужому богу. </p>
            

            
            <p>Прекращение вражды между нами зависит от&nbsp;Вашего Высокопреосвященства.
                Средостение вражды с&nbsp;моей стороны разрушено покаянием. Слова, заповеданные
                Христом, мной повторены многократно: «прости меня, каюсь». Средостение вражды
                сохраняется с&nbsp;Вашей стороны.</p>
            

            
            <p>Если примирение невозможно, вопрос не&nbsp;о&nbsp;моей или Вашей правоте.
                Вопрос о&nbsp;правоте Евангелия, о&nbsp;достоинстве учения Христова. Оно судит
                нас и&nbsp;осуждает:</p>
            

            
            <p><em>«Если&nbsp;же согрешит против тебя брат твой, пойди и&nbsp;обличи его
                между тобою и&nbsp;им&nbsp;одним; если послушает тебя, то&nbsp;приобрёл
                ты&nbsp;брата твоего. Если&nbsp;же не&nbsp;послушает, возьми с&nbsp;собою ещё
                одного или двух, дабы устами двух или трех свидетелей подтвердилось всякое
                слово. Если&nbsp;же не&nbsp;послушает их, скажи церкви; а&nbsp;если и&nbsp;церкви
                не&nbsp;послушает, то&nbsp;да&nbsp;будет он&nbsp;тебе, как язычник
                и&nbsp;мытарь"(Мф.18, 15−17).</em></p>
            

            
            <p>Вы&nbsp;не&nbsp;беседовали со&nbsp;мной наедине. Вы&nbsp;не&nbsp;беседовали
                со&nbsp;мной при свидетелях.</p>
            

            
            <p>Вы&nbsp;не&nbsp;призвали в&nbsp;свидетели церковь. Я&nbsp;просил Вас
                пригласить меня на&nbsp;обсуждение моей книги. Вы&nbsp;отказали и&nbsp;осудили
                меня заочно. Никодим заострил перед иудеями проблему правосудия: «<em>Судит&nbsp;ли
                    закон наш человека, если прежде не&nbsp;выслушают его и&nbsp;не&nbsp;узнают,
                    что он&nbsp;делает?» (Ин.7, 51)</em></p>
            

            
            <p>Иудеям нечего было ответить. Они укорили Никодима галилейским происхождением
                и&nbsp;разошлись. Когда нечего ответить, оскорбляют и&nbsp;бьют. Когда слуга
                ударил Иисуса Христа, Он&nbsp;не&nbsp;ответил обидой. Он&nbsp;поставил
                нравственную проблему о&nbsp;правах сильного. </p>
            

            
            <p><em>«Один из&nbsp;служителей, стоявший близко, ударил Иисуса по&nbsp;щеке,
                сказав: так отвечаешь Ты&nbsp;первосвященнику? Иисус отвечал ему: если
                Я&nbsp;сказал худо, покажи, что худо; а&nbsp;если хорошо, что ты&nbsp;бьёшь
                Меня?» (Ин.18,22−23).</em></p>
            

            
            <p>Неужели Христос не&nbsp;прав? Неужели насилие справедливо? Этим вопросом
                я&nbsp;завершаю своё письмо. Теперь за&nbsp;Вами слово.</p>
            

            
            <p>Прошу прощения за&nbsp;невольно причинённые огорчения, не&nbsp;держу обиды,
                не&nbsp;теряю надежды, что Господь коснётся Вашего сердца и&nbsp;умиротворит
                его. </p>
            

            
            <p>Настоятель храма святых Жен Мироносиц, священник Павел Адельгейм.</p>
            

            
            <p>13.03.05 г.&nbsp;Прощеный день. Псков.</p>
            
        </div>
    );
};

export default Letter10;
