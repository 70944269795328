import React from "react";
import './text.css';
const Fast = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Как преследовали протоиерея Геннадия Фаста за стремление к полноценной катехизации</h2>
            
            <p>«На
                епархиальном совете было решено крестить всех сразу, сводя все оглашение к
                некоторым словам перед крещением»</p>
            

            
            <p>Протоиерей Геннадий (Генрих) Фаст родился в 1954 году в селе
                Чумаково Новосибирской области в глубоко верующей протестантской немецкой семье
                Генриха и Елены Фастов. Родители мальчика оказались в Сибири после заключения в
                лагере по «антисоветской» статье и последующей ссылки. </p>
            

            
            <p>С 1972 по 1976 год Генрих учился на физфаке Карагандинского
                университета, откуда был выгнан за веру. В 1976 году он восстановился на
                физфаке Томского университета. Протестантизм казался ему слишком сухим, и
                поиски истины привели его к знакомству со служившим тогда в Новосибирске
                протоиереем Александром Пивоваровым и исповедником веры Игнатием Лапкиным, жившим
                в Алтайском крае. Игнатий Лапкин к тому времени уже отсидел за веру свой первый
                срок и готовился ко второму. Общение с этими людьми, изучение церковной истории
                и творений святых отцов произвели в душе Генриха полный переворот. На пятом
                курсе (в 1977 году) он принял крещение в православной Церкви с именем Геннадий.
                В 1978 году, уже во время работы на кафедре теоретической физики, он был
                обвинен в организации религиозной ячейки и выгнан из Томского университета. </p>
            

            
            <p>В 1980 году он стал священником, служил в разных приходах
                Тувинской республики, Кемеровской области, но основное служение нес в
                Красноярском крае. Имея большую семью, воспитывая пятерых детей, отец Геннадий
                заочно окончил Московскую духовную семинарию и академию. Здесь же в 1995 году он
                впоследствии защитил диссертацию на степень кандидата богословия.</p>
            

            
            <p>В 1983 году о. Геннадий Фаст стал настоятелем Успенского
                кафедрального собора в Енисейске.&nbsp;Здесь он принял решение о необходимости
                учить народ, приходящий в церковь, проводил катехизические беседы перед
                крещением и готовил себе в помощники других катехизаторов. Его катехизическая
                деятельность не осталась незамеченной советскими властями. В 1985–1986 годах на
                него было заведено уголовное дело по двум статьям: финансовые нарушения (которых
                на самом деле не было) и политическая неблагонадежность. Семь месяцев
                следствия, обысков, допросов могли бы завершиться плачевно для «политически
                неблагонадёжного» священника, но политическая обстановка изменилась, а вскоре
                не стало и советской власти.</p>
            

            
            <p>В 1994 году первым в епархии и вторым в России благочинный
                Геннадий Фаст открыл в Енисейске православную прогимназию свв. Кирилла и
                Мефодия. Следом за ним в этом же благочинии его воспитанником священником
                Андреем Юревичем была открыта в городе Лесосибирске (Красноярский край)
                православная гимназия св. Иоанна Кронштадтского. </p>
            

            
            <table><tbody> <tr><td className="bold">
                В 2004 году
            </td></tr><tr><td>
                Известный проповедник и катехизатор протоиерей Геннадий Фаст
                представлен архиепископом Красноярским и Енисейским Антонием (Черемисовым) к ордену
                преподобного Сергия Радонежского
            </td></tr><tr></tr> <tr><td className="bold">
                В апреле 2010 года
            </td></tr><tr><td>
                Протоиерей Геннадий Фаст назначен председателем епархиального
                Отдела религиозного образования и катехизации, переведён из Енисейска в Красноярск
                и поставлен настоятелем одного из главных храмов краевого центра – Архангело-Михайловской
                церкви. Храму по его прошению придан статус катехизаторского
            </td></tr><tr></tr> <tr><td className="bold">
                В мае 2010 года
            </td></tr><tr><td>
                Отец Геннадий без какого бы то ни было объяснения причин снят с
                должности настоятеля Архангело-Михайловского храма Красноярска и возвращен в
                Енисейск.
            </td></tr><tr></tr> <tr><td className="bold">
                В мае 2010 года
            </td></tr><tr><td>
                Опубликовано открытое письмо одного из учеников о. Геннадия,
                игумена Арсения (Соколова), озаглавленное «Письмо в защиту духовного отца». Вот
                фрагмент письма: «<i>Отец Геннадий охотно
                и с энтузиазмом взялся за выполнение своих новых послушаний: за реорганизацию
                вверенного ему отдела, за подготовку к открытию семинарии. Он остановил в
                Архангело-Михайловском храме требный конвейер<a name="r1" href="#1">[1]</a>
                и ввёл в нём катехизацию. Всего-то от двух до шести бесед, по выбору
                оглашаемых! Но и это для краевого центра – событие, увы, даже в наше время
                церковной весны неслыханное: до сих пор во всех храмах краевого центра
                расписание ежедневных крещений больше напоминает расписание маршрутных такси.
                Неужели, наконец, сдвинулось? – вдруг подумали все. Неужели и в Красноярск
                пришла церковная весна? И вдруг внезапно последовал новый, всех
                обескураживающий архиерейский указ – о возвращении протоиерея Геннадия
                обратно в Енисейск! Архангело-Михайловский храм лишен статуса
                катехизаторского, прекращены огласительные беседы, заработал, как прежде,
                требный конвейер. Остановлена подготовка к открытию семинарии. И богословских
                семинаров, видимо, тоже уже не будет. И катехизации ни в одном из
                многочисленных храмов краевого центра с миллионным населением как не было,
                так и не будет</i>».
            </td></tr><tr></tr> <tr><td className="bold">
                Июнь-июль 2010 года
            </td></tr><tr><td>
                В июньском и июльском
                номере газеты «Православное слово Сибири» опубликована проповедь архиепископа Антония,
                озаглавленная при публикации «Смотрите, како опасно ходите…» (Еф. 5: 15)<a name="r2" href="#2">[2]</a>.
                в которой автор осуждает практику оглашения в качестве обязательной. Он даёт
                собственную интерпретацию каноническим требованиям во время оглашения<a name="r3" href="#3">[3]</a>, осуждает
                целование мира среди мирян на литургии, чтение Писания лицом к народу
                и многое другое.
                <a href="http://www.pravmir.ru/smotrite-kako-opasno-xodite-ef-5-15/#ixzz3PezLw2Lf">http://www.pravmir.ru/smotrite-kako-opasno-xodite-ef-5-15/#ixzz3PezLw2Lf</a>
            </td></tr><tr></tr> <tr><td className="bold">
                4 июля 2010 года
            </td></tr><tr><td>
                Указом архиепископа Антония о. Геннадий освобожден от должности
                председателя епархиального Отдела религиозного образования и катехизации,
                должности благочинного Енисейского церковного округа (которую он занимал с
                1990 года), снят с должности настоятеля Успенского храма после двадцати семи
                лет настоятельства.
            </td></tr><tr></tr> <tr><td className="bold">
                8 октября 2010 года
            </td></tr><tr><td>
                В Журнале Московской Патриархии опубликована статья прот. Геннадия Фаста
                «Идите, научите все народы, крестя их…» В ней он обосновывает необходимость подготовки
                людей ко крещению и делится своим катехизическим опытом: «<i>Крещение — врата церковные. В наше время эти врата порой одиноко стоят
                в чистом поле, которое есть мир (ср.: Мф. 13, 38), и прошедшие через них
                оказываются там же, где и были. Врата в Никуда. Как же приладить их к храму,
                чтобы для вошедших в них мир оказался за дверьми, а сами они — в доме Божием?
                Многие пастыри, да и вся Церковь ищет ныне эти пути</i>».
            </td></tr><tr></tr> <tr><td className="bold">
                Октябрь 2010 года
            </td></tr><tr><td>
                В интернете появляется
                Открытое письмо протоиерея Геннадия
                Фаста (сокращенный вариант письма в виде статьи и был опубликован в ЖМП).
                В&nbsp;&nbsp; этом письме о. Геннадий отвечает на
                обвинения архиеп. Антония, высказанные в статье «Смотрите, како опасно ходите…»<a name="r4" href="#4">[4]</a>.
                В письме отец Геннадий выражает удивление тем, что, с одной
                стороны, патриарх Кирилл пишет: невозможна ситуация крещения без подготовки;
                и отец Геннадий своей деятельностью выполняет задачи, поставленные
                патриархом. С другой стороны, именно за выполнение этих постановлений архиеп.
                Антоний обвиняет его в протестантской ереси. Духовенство Красноярской
                епархии, пишет о. Геннадий, оказалось в странной ситуации противоречия между
                высказываниями архиепископа и рекомендациями Синодального Отдела религиозного
                образования и катехизации.
                http://www.kiev-orthodox.org/site/churchlife/2343
            </td></tr><tr></tr> <tr><td className="bold">
                12 ноября 2010 года
            </td></tr><tr><td>
                Протоиерей Геннадий Фаст переходит служить в Абакано-Кизилскую
                епархию
            </td></tr><tr></tr> <tr><td className="bold">
                30 ноября 2010 года
            </td></tr><tr><td>
                Протоиерей Геннадий Фаст назначен настоятелем Абаканского храма
                в честь святых равноапостольных Константина и Елены (Абаканская епархия).
            </td></tr><tr></tr> <tr><td className="bold">
                Январь 2011
            </td></tr><tr><td>
                Вслед за уходом протоиерея Геннадия Фаста вынужден уехать в
                родную ему Москву настоятель Крестовоздвиженского храма и основатель
                православной гимназии Лесосибирска ученик о. Геннадия прот. Андрей Юревич, за
                двадцать лет до этого избранный приходом к рукоположенннию. Некоторые из
                свидетелей отзывались об этом событии так: «На его место уже прибыл новый
                настоятель, о. Димитрий из Канска. Приехал, собрал весь причт и приказал:
                отныне никакой катехизации, крестить всех подряд и только за деньги. И вообще
                всё должно быть "как в Красноярске". Итак, за разгромом Енисейска
                совершена расправа с Лесосибирском».
            </td></tr><tr></tr></tbody></table>
            

            
            <p>Удивительным образом ситуация в Красноярске повторила уже знакомую
                нам по разделу «дела о непричащении» коллизию. Стремление высшего церковного
                руководства исключить из церковной практики столь распространенное в 1990-х
                -2000-х крещение без подготовки крещаемого или восприемников во многих
                епархиях, во многих храмах входила в противоречие со ставшим уже привычным
                повсеместным отношением к крещению как к требе, главное значение которой – в
                том, что за нее взимается плата. Главным оппонентом о. Геннадия выступил в этой
                ситуации не только и не столько архиерей (служивший в Красноярской епархии с
                1990 года и, значит, рукополагавший избранного прихожанами о. Андрея Юревича и
                не возражавший против катехизический практики о. Геннадия, пока она не стала
                вводиться как общеобязательная), сколько те силы в епархии, для которых
                неправославная практика крещения без какой бы то ни было подготовки казалась
                привычной и вполне православной. </p>
            

            
            <hr class="wp-block-separator"/>
            

            
            <p><a name="1" href="#r1">[1]</a><i> Имеется в виду крещение без какой бы то ни было подготовки всех
                желающих за внушительное «пожертвование» (от чего зависит сумма дохода
                священнослужителей). После «нововведений» о. Геннадия священнослужители
                лишались значительной части этого дохода: ведь люди, рассматривавшие крещение
                как простую платную формальность, отказывались и от предваряющих крещение
                бесед, и от принятия таинства, и от внесения денег. Судя по всему, именно
                задетые этим красноярские священники надавили на архиерея и добились странного,
                непоследовательного решения.</i></p>
            

            
            <p><a name="2" href="#r2">[2]</a><i> 15 июля эта проповедь опубликована также на портале «Православие
                и мир»</i></p>
            

            
            <p><a name="3" href="#r3">[3]</a><i>«В практике Святой Церкви никогда
                не стоял вопрос «крестить или не крестить» в зависимости от уровня познания
                Истины, которая дается катехизатором. … Само желание родителей младенца или
                взрослых, с их согласия, крестить дитя, разве не есть уже проявление их веры? И
                кто смеет из людей, «из них же ни един не свободен от греха», глубину веры
                крещаемых или восприемников измерить до конца, кроме Бога? Никто», - пишет
                архиеп. Антоний в полном противоречии с Апостольскими постановлениями («Когда же приблизится время крестить оглашенного, пусть
                научат его тому, что касается отречения от дьявола и сочетания со Христом. Ибо
                он должен воздерживаться от противного, а к таинствам приступать тогда, когда
                наперед очистит сердце свое от всякого злообычая, нечистоты и порока; тогда
                только он должен приступать к святыне»&nbsp;[Апостольские
                Постановления, 7. 40].)</i></p>


            
            <p><a name="4" href="#r4">[4]</a><i> В частности, о. Геннадий
                объясняет, что он не против крещения детей, в чем его обвиняет архиеп. Антоний,
                но против крещения без подготовки крещаемых или их восприемников. Он пишет: «Не
                случайно очень часто священники сами за крещаемых дают ответы на вопросы, смысл
                которых крещаемым совершенно не ведом. Символ веры читает рядом стоящая
                послушница. Бог у нас что, в плену наших действий или церковной кассы, чтобы
                после так совершаемого крещения все равно посылать благодать? Св. Кирилл
                Иерусалимский говорит о таких крещениях: «Омыл тело водою, но не просветил
                сердца духом; погружалось в воду и вышло из воды тело, а душа не погребалась со
                Христом и не воскресла с Ним… Приимет тебя вода, но не воспримет дух» (Слово
                предогласительное, пп. 2,4)». </i></p>
            
        </div>
    );
};

export default Fast;
