import React from "react";
import './text.css';
const Letter3 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2010/05-2</h2>
            
            <table><tbody><tr><td>
                Протоиерею
                Павлу Адельгейму<br></br>
                <br></br>
                    Ваше Высокопреподобие отец протоиерей<br></br>
                    <br></br>
                        Получил Ваше обращение о якобы Вашем намерении примириться со мной за
                        нанесенные обиды. Но как смотреть на это? Вы желаете примирения и тут же Ваша
                        новая клевета в мой адрес о том, что я Вас не допускал на епархиальные
                        собрания, на общие церковные торжества, откуда все это Вы взяли? Мы с Вами не
                        встречались, по телефону не разговаривали. От кого Вы это распоряжение
                        получили? Уж если примиряться, нужно признать своию сатанинскою клевету,
                        изложенную в Вашей книге "Догмат о Церкви", где Ваша черная,
                        ядовитая клевета в мой адрес распространяется в книгах и в интернете на весь
                        мир. Вот Вы теперь заявите на весь мир о том, что это была Ваша клевета на
                        правящего архиерея. Ведь в Вашей книге ни одного слова правды нет. Ведь
                        только осознанная раскаянность и просьба о прощении может быть основанием для
                        примирения, иначе это будет просто формальность, С новой клеветой, Вы
                        говорите, что я отнял у вас приход, в п.Богданово, но ведь это было после
                        отказа Вашего от этого прихода, о чем свидетельствовали персонал психиатрической
                        больницы п. Богданово. Вы говорите, что я отнял приход в п. Писковичи, но
                        ведь это было после Вашего скандала с о.Евгением Найдиным. Ради мира пришлось
                        вас разделить и дать каждому из вас приход. Это же было при свидетелях всего
                        Епархиального совета. Вы говорите, что я отнял у Вас настоятельство в храме
                        свв.Жен Мироносиц, но это было после невыносимого Вашего отношения к клирику
                        прихода свв.Жен Мироносиц священнику О. Владимиру Будилину. Имеется его
                        письмо об этом. А Ваши клеветнические письма, газетные публикации - что это?
                        Просите о прощении и тут же с новой клеветой в мой адрес. А Ваш отказ
                        преподавать в Псковском Духовном училище - тоже виноват архиерей? Всё это
                        лукавое Ваше - изворотливость и изощренные Ваши уловки. Так вот только после
                        признания своей клеветы, посредством СМИ против своего архиерея, да и всех
                        архиереев РПЦ, может быть взаимное прощение и примирение, и я готов простить
                        и примириться с открытым христианским сердцем.
                <br></br>Ожидаю Вашего согласия. Божией
                        помощи Вам, МИТРОПОЛИТ ПСКОВСКИЙ И ВЕЛИКОЛУКСКИЙ ЕВСЕВИЙ.
            </td></tr></tbody></table>
            
        </div>
    );
};

export default Letter3;
