import React from "react";
import './text.css';
const Krasnousolsk = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Дело о непричащении в селе Красноусольском</h2>
            
            <p>В поселке Красноусольский (до 1924 г. – Богоявленское) Уфимской области с 1930-х годов не было ни одного храма (Богоявленский храм XVIII века был закрыт и разобран на кирпичи). В 1945 году общине православных верующих разрешили приобрести для богослужения здание на улице Карла Маркса, 13. Но в 1960 году деятельность общины снова запретили, был снят крест, колокол с колокольни, а из храма изъяты иконы. Здание церкви было отобрано и использовалось в качестве детсада. В 1989 году православная община на пожертвование одной из прихожанок приобрела жилой дом на улице Куйбышева, который был приспособлен под Покровский молитвенный дом. В 1995 году по просьбе верующих здание на улице Карла Маркса было им возвращено, и с этого времени богослужение проходило в нем. Это был единственный храм в 15-тысячном поселке.</p>
            

            
            <p>С 2004 года настоятелем в храме стал
                свящ. Александр Сухов, с 2010 года назначенный Табынским благочинным. Отец Александр старался сплотить приход, хотел, чтобы люди активнее
                участвовали в богослужении и понимали его.</p>
            

            
            <p>В конце 2010 года из епархии в храм были присланы <a href="/2010-12-14-ufa/">методические указания по социальной и миссионерской деятельности, благословленные архиеп.&nbsp; Уфимским и Стерлитамакским Никоном</a>. В них говорилось о необходимости устроения общинной жизни, а также миссионерской деятельности на приходе<a name="r1" href="#1">[1]</a>. В то время такие изменения в церковной жизни, инициированные патриархом и Синодом, происходили по всем епархиям. Результат попыток воплотить эти указания в жизнь оказался неожиданным для настоятеля и прихожан Покровского храма.</p>
            

            
            <table><tbody><tr><td className="bold">   14 декабря 2010 года   </td></tr><tr><td>   В храм приходит <a href="/2010-12-14-ufa1/">письмо руководителя   миссионерского учебно-методического центра М.Е. Ларионовой благочинным и   настоятелям храмов Уфимской епархии</a>. Среди прочего в нем сказано, что   необходимо «Обязательно проводить совместные с прихожанами трапезы и чаепития, опираясь на традиции агап».    </td></tr><tr></tr><tr><td className="bold">   Сентябрь 2011 года  </td></tr><tr><td>
                В храме начинаются первые миссионерские
                Открытые встречи (предшествующие курсу катехизации). Проводят их катехизаторы
                из Преображенского братства. Во встречах участвуют несколько десятков
                человек.
            </td></tr><tr></tr><tr><td className="bold">
                Осень 2011 года
            </td></tr><tr><td>   Староста храма Василий Калачигов без ведома настоятеля начинает распространять среди   прихожан листовки «Кто такие «кочетковцы»?»    </td></tr><tr></tr><tr><td className="bold">
                С Рождества 2012 года
            </td></tr><tr><td>   По благословению настоятеля храма отца   Александра Сухова псалмы во время богослужения читаются на русском языке, по воскресеньям во время литургии Апостол и Евангелие сначала прочитываются на   церковнославянском языке, а потом&nbsp; в русском переводе. После чтения сразу произносится проповедь.   </td></tr><tr></tr><tr><td className="bold">
                8 января 2012 года
            </td></tr><tr><td>
                Архиепископ Уфимский Никон <a href="http://www.patriarchia.ru/db/text/1930828.html">возведен</a> в сан
                митрополита.
            </td></tr><tr></tr><tr><td className="bold">
                28 марта 2012 года
            </td></tr><tr><td>   Староста храма Василий Калачигов за спиной   настоятеля просит у митрополита распоряжения провести в храме собрание с целью «выяснить у людей: что происходит в храме (кочетковщина) и нужен ли нам   такой священник». Распоряжение получено.   </td></tr><tr></tr><tr><td className="bold">
                31 марта 2012 года
            </td></tr><tr><td>
                Василий Калачигов «ставит в известность» о.
                Александра Сухова о предстоящем на следующий день собрании.
            </td></tr><tr></tr><tr><td className="bold">
                1 апреля 2012 года
            </td></tr><tr><td>   Отец Александр переносит собрание на следующее воскресенье, объясняя это тем, что сам не успел подготовиться.  Василий Калачигов пишет <a href="/2010-04-1-ufa/">письмо митрополиту Никону</a> с жалобой на то, что настоятель не дал «восстановить единство» путем собирания ответов на вопросы «что творится в храме» и «нужен ли нам такой священник», а также на то, что большинство прихожан возмутились инициативой   старосты храма.    </td></tr><tr></tr><tr><td className="bold">
                4 апреля 2012 года
            </td></tr><tr><td>   К письму старосты приложено <a href="/2012-04-04-ufa/">еще одно письмо</a>   – «от прихожан храма» (под ним стоит <strong>три</strong> подписи, одна из которых принадлежит жене старосты Г. Калачиговой), написанное в жанре доноса. На это письмо получен ответ от митр. Никона -   резолюция после завершающих донос не соответствующих действительности слов «…на так называемых агапах и чаепитиях   укрепляется мысль в сознании верующих о несостоятельности церковной иерархии,   и в частности, о руководстве Уфимской епархии». Митрополит пишет, что «не   будет терпеть и вырвет этот нарыв с корнем!»    </td></tr><tr></tr><tr><td className="bold">
                4 апреля 2012
            </td></tr><tr><td>   Прихожане храма Покрова Божьей Матери пишут   <a href="/2012-04-04-1-ufa/">письмо митр. Никону в защиту настоятеля храма о. Александра Сухова</a>,   благодарят за служение о. Александра, за то, что он помог прийти к вере многим из них. «Огорчает нас то, – пишут прихожане, – что некоторое время   назад в наш храм стали приходить люди<a name="r2" href="#2">[2]</a>   и распространять слухи, обвинения в адрес о. Александра… Взываем к Вашей   архипастырской любвеобильной душе с просьбой не верить клеветникам,   пытающимся очернить доброе имя нашего батюшки».   <a href="/2012-04-04-1-ufa/">Под письмом 29 подписей</a>.   </td></tr><tr></tr><tr><td className="bold">
                май 2012 года
            </td></tr><tr><td>   Митрополит Никон, опираясь на письмо Василия   Калачигова с женой, миссионерскую деятельность прихода оценивает как ересь. Требует, чтобы отец Александр убрал со всех служений и не причащал активных   прихожан храма, в течение длительного времени участвовавших в различных   служениях прихода и в миссионерской деятельности. Митрополит также требует отмены катехизации. Отец Александр отказывается выполнять последнее, и владыка   перемещает его на другой приход, в соседний город Стерлитамак. Подписавших письмо в защиту о. Александра прихожан позже по распоряжению митр. Никона принуждают каяться в «кочетковской ереси»<a name="r3" href="#3">[3]</a>. Нераскаявшихся отлучают от причастия. Некоторое время они ездят к о. Александру на службу в Стерлитамак и он их причащает. За это на него опять   пишут доносы.   </td></tr><tr></tr><tr><td className="bold">
                В конце августа 2012 года
            </td></tr><tr><td>   В храм присылают другого священника – отца Боголепа (Артерчука), которого одновременно назначают благочинным. Он сразу убирает со всех служений активных членов прихода, отказывает им в причастии.   Необходимым моментом в разрешении к причастию вводит требование покаяния в   «кочетковской ереси». Проводит особый, созданным им чин над прихожанами,   участвующими в служениях прихода и в оглашении: покаяние в ереси и помазание   маслом. Он называет это елеопомазанием. Проводит  специальные молебны, в которых призываются участвовать все участники огласительных встреч  «для покаяния и очищения».   </td></tr><tr></tr><tr><td className="bold">
                12 октября 2012 года
            </td></tr><tr><td>   Митрополит Никон вызывает о. Александра и велит покаяться в «кочетковской ереси», признать, что отец Георгий Кочетков - еретик. Отец Александр отвечает, что не может этого сделать, потому что это - неправда. Владыка запрещает его в служении, сначала на месяц, «чтоб он одумался, покаялся»<a name="r4" href="#4">[4]</a>      </td></tr><tr></tr><tr><td className="bold">
                Октябрь 2012 года
            </td></tr><tr><td>   На приход присылают второго священника, отца Рафаила (Королева).   </td></tr><tr></tr><tr><td className="bold">
                3 ноября 2012 года
            </td></tr><tr><td>   Отлученные от причастия прихожане пишут <a href="/2012-11-03-ufa/">письмо митрополиту Никону с просьбой разобраться в накалившейся обстановке</a>: отлучении их от причастия, обвинении в ереси.      </td></tr><tr></tr><tr><td className="bold">
                11 ноября 2012 года
            </td></tr><tr><td>   На стенде храма Покрова Пресвятой Богородицы   для прихожан <a href="/2012-11-11-ufa/">вывешен ответ митр. Никона на письмо членов прихода</a> под заголовком «Ответ Высокопреосвященнейшего Митрополита Никона кочетковцам». В обращении в том числе говорится:   <i>«… То, чем с   вами «занимался»&nbsp; о. Александр Сухов –   это настоящая «ересь жидовствующих». Это секта внутри РПЦ, и, если кто из вас   не принесет покаяние – не будет допущен до Причастия…»</i>   <i>&nbsp;</i>  </td></tr><tr></tr><tr><td className="bold">
                Весна 2013 года
            </td></tr><tr><td>   В марте 2013 года о. Боголеп (Артерчук) уезжает в Табынск, где служил раньше, а о. Рафаил становится настоятелем. На каждой исповеди он также требует у членов прихода покаяния в участии в агапах, в том, что «пили из бесовской чаши», признания, что отец Георгий,   братство – еретики, что это ересь. Он даже не допускает до причастия людей, которые общаются с членами прихода, бабушек – за то, что они с ними   здороваются, за то, что при отце Александре участвовали в агапах или в чаепитиях после литургии.   </td></tr><tr></tr><tr><td className="bold">
                23 мая 2013 года
            </td></tr><tr><td>   Прихожане пишут <a href="/2013-5-23-ufa/">письмо в Управление делами Московской патриархии</a> , объясняя, что уже не раз безуспешно обращались к  своему архиерею и теперь не видят другого выхода. В письме они рассказывают, что много лет молились и трудились в храме, всемерно поддерживали приход,   регулярно причащались, но более полугода им отказывают в причастии, а прежний   настоятель, активно занимавшийся миссионерской, просветительской и социальной   деятельностью (в полном соответствии с указаниями, присланными из епархии) находится под прещениями. Все это стало результатом многократных   безосновательных жалоб одного человека, духовного чада священника из другого   прихода. Подчеркивая свою приверженность традиции, прихожане вспоминают   новомучеников и исповедников, среди которых есть и их старшие родственники, и   просят «содействовать восстановлению правды Божией».&nbsp;&nbsp;&nbsp;    </td></tr><tr></tr><tr><td className="bold">
                26 июля 2013 года
            </td></tr><tr><td>   Из Управления делами Московской патриархии   отправлен <a href="/2013-07-26-ufa/">ответ на письмо прихожан Покровского храма</a> (в Уфу он приходит через несколько месяцев).&nbsp; В ответе, в   частности, говорится: «В Уфимскую епархию отосланы подобающие указания о том, что отлучение от причастия может иметь место при должном обосновании». Однако ситуация не изменяется.   </td></tr><tr></tr><tr><td className="bold">
                август 2014 года
            </td></tr><tr><td>   Отца Рафаила переводят в Уфу, а в Покровский   храм присылают отца Димитрия Медведева. Он во всеуслышание в храме говорит: «Пусть кочетковцы не подходят на исповедь, а исповедуются только у   митрополита Никона». При обращении прихожан в епархию из них «выбивают» письменное покаяние: требуют, чтобы они написали неправду – что братство это ересь и секта. Они не соглашаются это сделать и по-прежнему остаются без причастия.   </td></tr><tr></tr><tr><td className="bold">
                октябрь 2014 года
            </td></tr><tr><td>   На одном из богослужений не причащают даже двух девочек (12 и 8 лет) за то, что они «дети кочетковцев». Правда, через неделю после этого священник просит прощения и детей причащает.   </td></tr><tr></tr><tr><td className="bold">
                14 июня 2015 года
            </td></tr><tr><td>   После личной встречи с митрополитом Никоном и просьбы о разрешении ситуации всех начинают причащать   </td></tr><tr></tr><tr><td className="bold">
                28 апреля 2016 года
            </td></tr><tr><td>   Проходивших катехизацию прихожан вновь перестают допускать не только к причастию, но и к исповеди, пока они «не выйдут из Преображенского братства»    </td></tr><tr></tr><tr><td className="bold">
                17 июня 2016 года
            </td></tr><tr><td>   В письме к митрополиту Уфимскому Никону прихожане просят разрешить непонятную ситуацию, сложившуюся в Красноусольском храме. Ответа на письмо не последовало.   </td></tr><tr></tr><tr><td className="bold">
                2 июля 2016 года
            </td></tr><tr><td>   После архиерейской службы в Покровском храме прихожане подходят к митрополиту с просьбой указать канонические причины, по   которым их отказывается исповедовать и причащать настоятель Красноусольского   храма о. Димитрий Медведев. В ходе беседы выясняется, что не допускать их до причастия, пока они не покаются в «кочетковской ереси» и не выйдут из   Преображенского Братства – это указание владыки.       </td></tr><tr></tr></tbody></table>
            

            
            <p>С тех пор гонимые прихожане ездили на литургию в другие храмы и монастыри. Там их допускали и к исповеди, и к причастию. </p>
            

            
            <p>В июне 2019 года, в праздник местночтимой Табынской иконы&nbsp; Божьей Матери (девятая пятница после Пасхи) в Покровский храм приезжал митрополит Никон переосвящать престол после ремонта. Он причастил членов братства, и после этого их в храме причащают. </p>
            

            
            <hr class="wp-block-separator"/>
            

            
            <p></p>
            

            
            <p><a name="1" href="#r1">[1]</a><i> О главных <strong>целях миссионерской деятельности</strong> в документе говорится следующее:
                «главная цель миссионерской деятельности на уровне прихода - <strong>созидание евхаристической общины</strong> на
                территории пастырской ответственности данного прихода. Это возможно лишь тогда,
                когда и настоятель и члены прихода осознают свою миссионерскую ответственность.
                В соответствии с Концепцией миссионерской деятельности РПЦ <strong>миссионерская ответственность настоятеля прихода</strong> подразумевает:</i></p>
            

            
            <p><i>1) Привлечение
                активных прихожан к миссионерскому служению.</i></p>
            

            
            <p><i>2) Ориентирование
                воскресных школ и молодежных организаций на миссионерскую деятельность</i></p>
            

            
            <p><i>3) Воспитание в
                приходском активе миссионерского духа посредством личного примера</i></p>
            

            
            <p><strong><i>Миссионерская ответственность мирянина</i></strong><i> подразумевает:</i></p>
            

            
            <p> <i>1) Знание основ православного вероучения и активное участие в жизни прихода.</i> </p>
            

            
            <p> <i>2) Свидетельствование об истине Православия в соответствии с образованием, способностями и профессиональными навыками».</i> </p>
            

            
            <p><i>Вот что
                говорилось в «Методических указаниях по социальной и миссионерской
                деятельности…» о миссионерских богослужениях: </i><i>«необходимо
                регулярное проведение специальных миссионерских богослужений, на которых
                богослужение сочетается с элементами катехизации, доступным объяснением
                исторического и символического значения элементов богослужения, подробным
                объяснением богослужебных текстов, чтением русского варианта текстов…Частота
                проведения миссионерских богослужений – не менее 2 раз в год». Для понимания сути миссионерских
                богослужений рекомендовалась статья «Объяснение Божественной Литургии» (<a href="http://www.pravmir.ru/article_2664.html">http://www.pravmir.ru/article_2664.html</a>), в которой рекомендовались
                богослужебные тексты приведенные преимущественно в русском переводе по изданию
                «Православное богослужение. Выпуск 1. Вечерня, Утреня, Литургия св. Иоанна
                Златоуста». – М.: Свято-Филаретовский православно-христианский институт,
                2004&nbsp; </i></p>


            
            <p><a name="2" href="#r2">[2]</a><i> Основным
                инициатором писем-доносов был прихожанин храма деревни Ташлы (расположена в 160
                км от Красноусольска), духовное чадо игумена Силуана (Филиппова) Иоанн-Радик
                Ишмухаметов.</i></p>
            

            
            <p><a name="3" href="#r3">[3]</a><i> Священник Георгий Кочетков является служащим священником Русской православной церкви, ведет огласительную практику с конца 70-х годов XX века, является духовным попечителем Преображенского братства. Начиная с 25-летия братства в 2015 году в дни празднования Преображения Господня служит в храме Христа Спасителя в Москве и говорит проповеди с амвона. Более подробно см. Христианский Вестник №№3 и 4.</i></p>
            

            
            <p><a name="4" href="#r4">[4]</a><i> О. Александр Сухов, отец четверых детей, на
                2017 г. остается под запретом, живет в Москве, работает на светской работе.</i></p>
            
        </div>
    );
};

export default Krasnousolsk;
