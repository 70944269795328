import React from "react";
import './text.css';
const Velsk2 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2014/08-01 Вельск</h2>
        </div>
    );
};

export default Velsk2;
