import React from "react";
import './text.css';
const Letter5 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2008/03</h2>
            <p>Начальнику
                УВД по г. Пскову полковнику милиции Петрову В.И. г. Псков, ул. М. Горького, д.
                15, кв.7<br></br>
                    Обозному К.П.<br></br>
                    ПОСТАНОВЛЕНИЕ<br></br>
                    о передаче сообщения для разрешения по существу<br></br>
                    <br></br>
                        И.о. прокурора города Пскова младший советник юстиции Тельнов П.Ю. рассмотрев
                        заявление Обозного Константина Петровича от 05.03.08, № 64 пр-08,<br></br>
                        <br></br>
                            УСТАНОВИЛ:<br></br>
                            <br></br>
                                05.03.08 в прокуратуру города Пскова поступило заявление Обозного К.П. о
                                самоуправных действиях настоятеля Храма Святых Жен Мироносиц священника Сергия
                                Иванова. Учитывая, что в заявлении содержатся сведения о совершении
                                преступления, предусмотренного ст. 330 УК РФ, относящегося к подследственности
                                органов внутренних дел, руководствуясь ст.ст. 37, 150, 151, УПК РФ,<br></br>
                                <br></br>
                                    ПОСТАНОВИЛ:<br></br>
                                    <br></br>
                                        Направить заявление Обозного К.П.. КУСП № 64пр-08 для разрешения по существу в
                                        порядке ст.144-145, УПК РФ по подследственности начальнику УВД по г.Пскову<br></br>
                                        <br></br>
                                            Приложение: на 5 л.<br></br>
                                            <br></br>
                                                И.о.прокурора города младший советник юстиции П.Ю.Тельнов</p>
            
        </div>
    );
};

export default Letter5;
