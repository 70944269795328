import React from "react";
const Title = () => {
    return (
        <div align={'center'}>
            <img src={require('../images/title-cutted-edited.jpg')} alt={"title"} width={"50%"} align={"center"}/>
        </div>
    );
};

export default Title;
