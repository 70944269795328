import React from "react";
import './text.css';
const Letter7 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2008/02/23-1</h2>
            
            <p>
                Москва 034 Чистый 5 Патриарху<br></br><br></br>
                Ваше Святейшество!<br></br><br></br>
                22 февраля 08 г архиепископ Евсевий снял меня с настоятельства в приходе Жен
                Мироносиц, который я восстановил из руин и отдал ему 20 лет жизни. С моим
                уходом неизбежно закрывается единственная в Псковской епархии
                общеобразовательная школа регентов и приют для сирот-инвалидов. Они содержались
                на приходские средства 15 лет. <br></br><br></br>
                Прошу срочно защиты и содействия. <br></br><br></br>
                Протоиерей Павел Адельгейм.



            </p>
            
        </div>
    );
};

export default Letter7;
