import React from "react";
import './text.css';
const Ufa5 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2010/12-14 Уфа</h2>
            <div align={'center'}>
                <img src={require('../images/image.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
        </div>
    );
};

export default Ufa5;
