import React from "react";
import './text.css';
const Velsk3 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2014/12-06 Вельск</h2>
            
            <p class="has-text-align-right">Его Высокопреосвященству,</p>
            

            
            <p class="has-text-align-right">Высокопреосвященному ВАРСОНОФИЮ,</p>
            

            
            <p class="has-text-align-right">митрополиту Санкт-Петербургскому и
                Ладожскому,</p>
            

            
            <p class="has-text-align-right">управляющему делами Московской
                Патриархии</p>
            

            
            <p class="has-text-align-right">прихожан Свято-Успенского храма г.
                Вельска</p>
            

            
            <p class="has-text-align-right">Котласской и Вельской епархии</p>
            

            
            <p class="has-text-align-center">ПРОШЕНИЕ</p>
            

            
            <p>ВАШЕ
                ВЫСОКОПРЕОСВЯЩЕНСТВО, благословите!</p>
            

            
            <p>Прежде
                всего мы благодарим Вас за Ваше участие в разрешении той тревожной ситуации,
                которая сложилась за последние десять месяцев в нашем родном приходе. Мы уже
                писали Вам, что являемся постоянными прихожанами Свято-Успенского храма города Вельска
                на протяжении последних двадцати лет. Двадцать лет мы регулярно участвовали в
                таинственной жизни Церкви — регулярно исповедовались и причащались. Однако в
                этом году ситуация резко изменилась — нам стали отказывать в Причастии лишь на
                том основании, что мы пришли к Богу и в Церковь благодаря катехизаторским
                усилиям священника Георгия Кочеткова и Преображенского братства, что мы активно
                делимся с нашими родными, близкими и друзьями своим свидетельством о том, что в
                наше непростое время единственный источник Утешения, Радости, Надежды и Любви
                мы нашли для себя в благодатной жизни Русской Православной Церкви. </p>
            

            
            <p>Сейчас
                в Вельском приходе сложилась парадоксальная и опасная ситуация: мы стараемся
                помогать людям войти в жизнь Церкви (за двадцать лет не менее тридцати человек
                стали прихожанами Вельского храма), а новый настоятель и благочинный Вельского
                округа протоиерей Андрей Ермилов ведёт против нас клеветническую кампанию:
                проводит приходские собрания с распространением клеветы, искусственно
                противопоставляет нас другой части прихожан, натравливает её на нас,
                немотивированно отлучает нас от Причастия. За последний год обстановка в
                приходе стала раскалённой, нездоровой, агрессивной. Мы писали Вам, что дважды
                обращались к Преосвященному ВАСИЛИЮ, епископу Котласскому и Вельскому, но
                ситуация на приходе только усугубилась.</p>
            

            
            <p>Большим
                утешением и надеждой стал для нас ответ архимандрита САВВЫ (Тутунова) на наше
                письмо: «в Котласскую епархию отправлены соответствующие указания о том, что
                причины, изложенные в Вашем письме, не могут быть основанием для запрета на
                участие в Таинстве» (РКПД-01-0104/3717; 01.08.14; Брильков
                А.С.). Мы очень
                надеялись, что Ваше обращение в Котласскую епархию снимет все надуманные
                проблемы. Получив письмо, мы продолжали каждую субботу приходить на Всенощное
                бдение, а после него подходили на личную исповедь. Каждый раз мы получали разрешительную
                молитву, но не получали благословения на Причастие.</p>
            

            
            <p>Однажды
                настоятель храма протоиерей Андрей Ермилов не выдержал и сказал одному из нас,
                что запрет идёт от владыки ВАСИЛИЯ, что сам отец Андрей этот запрет снять с нас
                не может, что владыка ВАСИЛИЙ знает, какое большое значение имеет для нас
                участие в Литургии и Причастии, а поэтому они используют отлучение нас от
                Причастия как единственную меру воздействия на нас.</p>
            

            
            <p>Таким
                образом выяснилось, что духовенство Вельского храма использует Тело и Кровь
                Христовы не как Божий дар спасения и возрождения, а как рычаг давления, как
                средство шантажа. Не менее удивило нас и полное игнорирование Вашего обращения
                в Котласскую епархию в ответ на наше письмо. Имея много друзей в разных
                епархиях нашей Русской Православной Церкви, мы с радостью узнаём о том, что за
                последнее время благополучно разрешаются все недоразумения, связанные с
                регулярным причастием мирян. Мы с благодарностью и благоговением воспринимаем
                ясную позицию и просветительские усилия Святейшего Патриарха КИРИЛЛА в области
                приближения мирян к ответственному и регулярному участию в Святых Таинствах, но
                нам непонятно, почему настоятель и благочинный Вельского прихода Котласской
                епархии упорно и последовательно движется в обратном направлении, создавая
                раскол не только внутри прихода, но и выводя Вельский приход из-под влияния
                Патриарха КИРИЛЛА. Впрочем, отец Андрей и сам неоднократно говорил, что <em>«рыба гниёт с головы, надо быть осторожным.
                    Вся гадость начнётся от епископов и патриархов. Церкви как организации уже
                    давно не существует. Корабль церковный утонул, остались только шлюпки с
                    батюшками, вот они и спасаются»</em> <em>(сентябрь
                    2014-го года, аудиозапись).</em></p>
            

            
            <p>ВАШЕ
                ВЫСОКОПРЕОСВЯЩЕНСТВО! Мы обращаемся к Вам, как к Управляющему делами Московской
                Патриархии c просьбой положить конец тому расколу Вельского прихода, который
                совершается по инициативе его настоятеля и восстановить общецерковное отношение
                к Святым Таинствам!</p>
            

            
            <p>Испрашивая
                Вашего архипастырского благословения,</p>
            

            
            <p>6
                декабря 2014 года</p>
            
        </div>
    );
};

export default Velsk3;
