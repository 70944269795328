import React from "react";
import './text.css';
const Letter4 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2010/05-1</h2>
            
            <p></p>
            

            
            <p><strong>Первое
                письмо о. Павла Адельгейма</strong></p>
            

            
            <p>Ваше
                Высокопреосвященство!</p>
            

            
            <p>Поводом к
                моему смиренному письму послужило решение Общецерковного суда о взаимном примирении.
                Хочется надеяться, что долгожданное событие осуществится. Христос Спаситель
                призвал: "мирись с соперником твоим скорее" (Мф.5, 25).
                    Примирение не означает отказ от своих принципов. Можно иметь разные взгляды, и
                    жить в мире. Примирение я понимаю как преодоление вражды и агрессии в сердце.
                    Восемь лет между епископом и священником разорвано личное общение. Вы не
                    отвечаете на мои письма, посылаемые к Вам лично. Несколько раз я исполнил Ваше
                    требование попросить прощения за причинённые огорчения публично через СМИ,
                    опубликовал свои просьбы о прощении в газетах. Моя просьба о прощении не
                    означает признание за собой вины. Это просьба о примирении, ради которого
                    можно, жертвуя своим самолюбием, сказать "прости меня". Мое
                    стремление к примирению наталкивалось на стену непонимания. Меня просили не
                    посещать епархиальные собрания и торжества, не попадаться Вам на глаза, не
                    писать писем. Я был вынужден жить в изоляции. После снятия меня с
                    настоятельства исчезла необходимость деловых отношений, а служебный подлог
                    о.Сергия Иванова и судебное противостояние вовсе разделили нас, и всякая
                    возможность общения утратилась. Год за годом угасала надежда на примирение.</p>
            

            
            <p>17 мая 2010
                года состоялся Общецерковный суд. Он принял решение нам с Вами примириться.
                Решение суда вынесено в специальное определение и оживило мою надежду на Ваше
                благое произволение. Примирение заповедует Христос, предлагают уважаемые нами
                иерархи, я тоже хочу примирения и отзываюсь на него своей совестью. Моё сердце
                готово забыть всяческие обиды и вместить Вас. Двери моего дома открыты так же,
                как и двери сердца. У меня нет ни секретаря, ни охраны.
                    К Вам не войти без Вашего соизволения. Остаётся ожидать с надеждой, что моё
                    нынешнее обращение пробудит к примирению Ваше сердце, и Вы тоже отзовётесь
                    пастырской любовью. Дай Бог нам всем взаимного прощения и примирения. С любовью
                    во Христе, свящ. Павел. 27 мая 2010 года, Псков.</p>
            

            
            <p><strong>Второе
                письмо о. Павла Адельгейма</strong></p>
            

            
            <p><a href="http://adelgeim.livejournal.com/42518.html">http://adelgeim.livejournal.com/42518.html</a></p>
            

            
            <p>Высокопреосвященному
                Евсевию,митрополиту Псковскому и Великолукскому.</p>
            

            
            <p>Ваше
                Высокопреосвященство!</p>
            

            
            <p>"Снисходя
                друг другу и прощая взаимно,если кто на кого имеет жалобу:
                    как Христос простил вас, так и вы"(Кол.3,12-13).</p>
            

            
            <p>По внушению
                христианской совести, вновь прошу Вашей милости и терпения к моему настойчивому
                и многолетнему поиску примирения. Теперь мои усилия обрели поддержку в решении
                Общецерковного суда. Рекомендательное решение ни к чему не обязывает совесть.
                Но мнением пяти прелатов, облечённых судебной властью, нельзя пренебречь. Может
                ли христианин иметь врагов? Может, если Христос Спаситель заповедал:"любите
                враги ваша". Как понимать слово "враг"? По определению В.Даля,
                "недоброжелатель"- человек, который желает мне зла, старается
                причинить неприятности и злорадствует, когда со мной случается несчастье.
                Христианин может иметь врагов, но сам не может быть врагом никому. Отношение к
                другому -другу или врагу- которое заповедал Бог, называют "любовь":
                "Любите врагов ваших, благословляйте проклинающих вас, благотворите
                ненавидящим вас и молитесь за обижающих вас и гонящих вас"(Мф.5,44). Кто
                любит и прощает, не может быть врагом. Понятие "враг" в словах Христа
                Спасителя выражает одностороннее, а не взаимное отношение. Имея врагов,
                христианин сам не может быть врагом никому. Я отказываюсь быть Вашим врагом и
                желаю Вам блага. Жития святых Никифора и Саприкия, Тита и Евагрия выражают
                церковную традицию: благодать Божия оставляет тех, чьё сердце затаило месть и
                не умеет прощать. Эта новозаветная традиция запечатлена в Священном Писании.</p>
            

            
            <p>"Дети
                Божии и дети диавола узнаются так: не есть от Бога не любящий брата. Не любящий
                брата пребывает в смерти. Всякий, ненавидящий брата, есть
                человекоубийца"(1Ин.3,10- 15). "Кто говорит: я люблю Бога, а брата
                своего ненавидит, тот лжец. Мы имеем от Него заповедь, чтобы любящий Бога любил
                и брата своего" (Ин.4, 20-21).</p>
            

            
            <p>Если мы верим
                словам апостола Любви, а не только используем их для проповеди, как может
                совесть наша оставаться безмятежной, пока в сердце живёт вражда?
                    Вы и я предстоим одному и тому же Святому Престолу, совершаем Божественную
                    Литургию и зовём: "возлюбим друг друга"! Встаёт неизбежный вопрос:
                    наши слова выражают крик души или ханжество, за которое Христос осуждал
                    фарисеев? Да не будет! Я не подхожу под определение "врага",
                    поскольку постоянно молюсь Богу о Вас, Вашем здоровье и благополучии и желаю Вам
                    искренне прощения и милости от Бога.</p>
            

            
            <p>Много раз я
                письменно просил у Вас прощения за огорчение, невольно причинённое публикацией
                книги. Мои письма к Вам безответны. Только раз Вы ответили на моё покаяние
                письмом, содержавшим обличения, оскорбления, вечное осуждение на "место,
                всем клеветникам и диаволу уготованное от вечности" и пожелание
                "принести всенародное покаяние через средства массовой
                информации"*(*"Благодатные лучи" №2 (81) 2005 г. стр.9-11).</p>
            

            
            <p>Понимаю Вашу
                обиду. Не понимаю её неутолённости в течение восьми лет.
                    Я давно готов придти к Вашему Высокопреосвященству, чтобы просить примирения,
                    но Вы отказали мне в "кабинетном примирении". Вы требуете, что бы я
                    отрёкся от написанной книги, поскольку считаете её личной инвективой против
                    Вас. Это не верно. Книга состоит из трёх частей. Первая часть раскрывает
                    православную екклезиологию. Вторая часть раскрывает учение о Церкви с позиций
                    действующего Устава РПЦ, а третья показывает, как Устав РПЦ осуществляется и
                    нарушается в практике епархиальной жизни. Достоверность требует свидетельства
                    очевидца, готового отвечать за свои слова. Поэтому я пишу о том, что вижу
                    своими глазами и слышу своими ушами. Разве удивительно, что я привожу факты из
                    жизни Псковской епархии? Когда я служил в Ташкентской епархии, то писал о
                    деятельности архиепископа Ермогена Голубева, которая оставила неизгладимое
                    впечатление. Теперь я служу в Псковской епархии и пишу о Вашей деятельности,
                    которая совершается на моих глазах.
                    Много раз я писал Вам в своих письмах, докладных записках и прошениях обо всём,
                    что повторила книга. В книге нет ничего нового, о чём бы Вы не прочли в
                    предыдущие годы. Моя книга возникла из внутренней необходимости пережить
                    десятилетнее гонение, воздвигнутое Вами с 1993 года, понять причины и духовный
                    смысл событий недопустимых, но происходящих в церкви Христовой.</p>
            

            
            <p>Мне пришлось
                вторично пережить нелепую ситуацию, когда меня вынуждали озлобляться и
                вытесняли в ряды врагов. В те времена моим злым гением было Советское
                государство, Коммунистическая партия и их репрессивный аппарат. Логика преследования
                была проста. Расстреляли дедов и отца, посадили в тюрьму мать. Отсюда вывод-сын
                должен озлобиться и мстить. Нужны превентивные меры. Вот и поехал восьмилетний
                преступник в казахстанскую ссылку следом за матерью. А затем, пожизненная опека
                госбезопасности всюду, где бы ни жил. Выгнали из средней школы, выгнали из
                Духовной семинарии, препятствовали венчаться, препятствовали служить, осудили,
                посадили, отрезали ногу – всё было понятно: сын "врагов народа"
                должен погибнуть, хотя никто из нашего рода не причинил вреда советской власти.
                Напротив: дед, отец и мать созидали её материальные и культурные ценности.
                    Впервые моё уважение к епископу подверглось испытанию при ознакомлении с
                    судебным делом, когда я прочёл донос, написанный епископом Макарием Свистуном,
                    другом моей юности. Это была очевидная клевета. Она положена в основу моего
                    приговора. Весь лагерный срок я молился Богу, чтобы мне не озлобиться и
                    благодушно понести крест. Бог услышал мою молитву и даровал мир моему сердцу.
                    Слава Богу за всё.</p>
            

            
            <p>С Вашим
                приходом в Псковскую епархию репрессивная история повторилась. Как не
                задуматься о смысле происходящего? За десять лет совместного служения я Вас
                ничем не огорчил. Вы не можете сообщить ни одного порочащего меня факта или
                причинённой Вам обиды. Свои обиды я забыл, когда родилась книга. Ваше
                Высокопреосвященство!

                На закате наших дней вспомним путь, пройденный за
                70 лет служения в церкви, и примиримся на пороге грядущего суда Божия! Вместе
                попросим у Бога прощения грехов, веруя, "если двое из вас согласятся на
                земле просить о всяком деле, то чего бы ни попросили, будет им от Отца Моего
                небесного" (Мф.18,19). Благодать и милость Божия да пребудут с Вами, свящ.
                Павел Адельгейм 20.06.2010 года.



            </p>
            
        </div>
    );
};

export default Letter4;
