import React from "react";
import './text.css';
const Ufa2 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2010/04-1 Уфа</h2>
            <div align={'center'}>
                <img src={require('../images/image-2.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
        </div>
    );
};

export default Ufa2;
