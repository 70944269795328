import React from "react";
import './text.css';
const Letter12 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2004/02/18</h2>
            
            <p><strong><em>Письмо прот. Павла Адельгейма архиепископу Псковскому и&nbsp;Великолукскому Евсевию с просьбой о примирении от 18.02.04 г.</em></strong></p>
            

            
            <p>МОСКОВСКАЯ
                ПАТРИАРХИЯ
                    ПСКОВСКАЯ ЕПАРХИЯ
                    ХРАМ СВЯТЫХ ЖЕН-МИРОНОСИЦ
                    180016&nbsp;г.&nbsp;Псков, ул.&nbsp;Коммунальная д.25а</p>
            

            
            <p class="has-text-align-right"><strong>Его Высокопреосвященству
                Высокопреосвященному Евсевию, архиепископу Псковскому и&nbsp;Великолукскому.</strong></p>
            

            
            <p class="has-text-align-right"><em>«</em><em>якоже</em><em> </em><em>и&nbsp;мы&nbsp;оставляем</em><em> 
            </em><em>должником</em><em> </em><em>нашим»</em><em>.</em></p>
            

            
            <p><strong>Ваше Высокопреосвященство</strong>!</p>
            

            
            <p>Наступили дни
                примирения, покаяния и&nbsp;воздержания. Войти в&nbsp;святые дни Великого поста
                мне хотелось&nbsp;бы примирённым с&nbsp;Вами, как завещал Христос:</p>
            

            
            <p><em>«если ты&nbsp;принесёшь дар твой к&nbsp;жертвеннику и&nbsp;там вспомнишь,
                что брат твой имеет что-нибудь против тебя, оставь там дар твой перед
                жертвенником и&nbsp;пойди прежде примирись с&nbsp;братом твоим, и&nbsp;тогда
                приди и&nbsp;принеси дар твой"(Мф.5,23). </em></p>
            

            
            <p>Каждый
                христианин знает по&nbsp;опыту как трудно совершить этот подвиг: просить
                прощения и&nbsp;прощать. Однако, нам некуда деться от&nbsp;непререкаемого
                завета Христа. Бог не&nbsp;примет ни&nbsp;воздержание, ни&nbsp;покаяние, если
                мы&nbsp;не&nbsp;захотим простить друг друга и&nbsp;примириться. Говорят, время
                лечит раны и&nbsp;обиды. Прошло два года. Второй раз наступает Прощённый день.
                В&nbsp;каждом письме я&nbsp;прошу простить причинённое Вам огорчение
                и&nbsp;примириться. Святое Евангелие позволяет отказать в&nbsp;прощении
                по&nbsp;двум причинам. Одна из&nbsp;них лишает прощения в&nbsp;этом веке.
                Другая вина не&nbsp;проститься ни&nbsp;в&nbsp;сем веке, ни&nbsp;в&nbsp;будущем.
            </p>
            

            
            <p>Во-первых,
                невозможно даровать прощение тому, кто об&nbsp;этом не&nbsp;просит. Это
                косвенный вывод из&nbsp;ответа Христа Спасителя апостолу Петру: </p>
            

            
            <p><em>«Если согрешит против тебя брат твой, выговори ему; и&nbsp;если покается,
                прости ему. И&nbsp;если семь раз в&nbsp;день согрешит против тебя, и&nbsp;семь
                раз в&nbsp;день обратится, и&nbsp;скажет: «каюсь»,&nbsp;— прости ему» (Лк.17,
                2−4). </em></p>
            

            
            <p>Кто
                не&nbsp;просит прощения, тот не&nbsp;сможет его принять. Прощение останется
                бездейственным. Я&nbsp;прошу у&nbsp;Вас прощения и&nbsp;повторяю: «каюсь».</p>
            

            
            <p>Есть другое
                предупреждение: 
                    <em>«Всякий грех и&nbsp;хула простятся человекам: а&nbsp;хула на&nbsp;Духа
                        не&nbsp;простится человекам. Если кто скажет слово на&nbsp;Сына Человеческого,
                        простится ему; если&nbsp;же кто скажет на&nbsp;Духа Святого, не&nbsp;простится
                        ему ни&nbsp;в&nbsp;сем веке, ни&nbsp;в&nbsp;будущем» (Мф.12, 22).</em></p>
            

            
            <p>Обиду,
                причинённую епископу, нельзя рассматривать как хулу на&nbsp;Святого Духа.</p>
            

            
            <p>Епископ
                поставляется Духом Святым. Епископ священнодействует, управляет и&nbsp;учит Его
                властью. Но&nbsp;персону епископа нельзя отождествить с&nbsp;личностью Святого
                Духа. Это не&nbsp;одно лицо, а&nbsp;две различные личности. Святой Дух является
                Божественной Ипостасью. Епископ являет человеческий лик, нуждающийся, как
                и&nbsp;прочие, в&nbsp;преображении и&nbsp;спасении благодатию Святого Духа.
                Если хула на&nbsp;Сына Человеческого простится, может&nbsp;ли епископ требовать
                вечного осуждения своему обидчику за&nbsp;причинённую обиду?</p>
            

            
            <p><em>«Всякий первосвященник, из&nbsp;человеков избираемый, для человеков
                поставляется на&nbsp;служение Богу, чтобы приносить дары и&nbsp;жертвы
                за&nbsp;грехи, могущий снисходить невежествующим и&nbsp;заблуждающимся; потому
                что и&nbsp;сам обложен немощью"(Евр.5, 1−2).</em></p>
            

            
            <p>По&nbsp;этой
                причине епископ не&nbsp;может отказать в&nbsp;прощении своему брату, просящему
                снисхождения. Отказ противоречит словам апостола. Он&nbsp;не&nbsp;имеет
                основания в&nbsp;Священном Писании и&nbsp;христианской традиции. Предложенная
                Вами форма «<em>всенародного покаяния через СМИ</em>» настораживает совесть.
                Христианская традиция не&nbsp;знает такой формы покаяния. Эта форма
                не&nbsp;нашла отражения в&nbsp;святоотеческом опыте, в&nbsp;опыте
                новомучеников, не&nbsp;имеет церковного смысла. </p>
            

            
            <p>«<em>Пожелание
                покаяться через СМИ</em>» вызывает грустные ассоциации с&nbsp;рекламной
                кампанией 1955−64 года. Множество священников отрекались от&nbsp;Бога через
                СМИ, чтобы обеспечить себе благополучие. Они каялись в&nbsp;том, что верили
                в&nbsp;Бога и&nbsp;обманывали людей ради личной выгоды. Мне тоже предлагали
                отречься от&nbsp;Христа через газету, чтобы не&nbsp;идти в&nbsp;тюрьму.
                Я&nbsp;предпочёл тюремный срок. Рекламная акция дискредитировала сущность
                покаяния: продавали первородство за&nbsp;чечевичную похлёбку. Газета
                несопоставима с&nbsp;книгой. Книги претендуют на&nbsp;суд истории. Газеты
                живут, как мотыльки, одним днём.</p>
            

            
            <p>Примирение
                нельзя использовать для порабощения личности. Во&nbsp;имя примирения грех
                отрекаться от&nbsp;веры, от&nbsp;своих убеждений и&nbsp;человеческого
                достоинства: «<em>не&nbsp;делайтесь рабами человеков» (1Кор.7, 23).</em>
                Христианское примирение может иметь только одну цель: взаимное взыскание любви.
            </p>
            

            
            <p>У&nbsp;меня нет
                возражений против публикации моего письма. Из&nbsp;своего обращения к&nbsp;Вам
                я&nbsp;не&nbsp;делаю тайны и&nbsp;готов перед всем миром свидетельствовать, что
                прошу у&nbsp;Вас прощения за&nbsp;причинённую скорбь.</p>
            

            
            <p>В&nbsp;Прощённое
                воскресение Церковь призывает к&nbsp;покаянию, прощению и&nbsp;примирению.</p>
            

            
            <p>Каждый пастырь
                обратится к&nbsp;народу Божию с&nbsp;призывом простить друг друга. Неужели
                слово Божие пройдёт через меня, как сквозь медную трубу, не&nbsp;задевая
                совесть? Или «<em>слово Божие живо и&nbsp;действенно и&nbsp;острее всякого меча
                    обоюдоострого: оно проникает до&nbsp;разделения души и&nbsp;духа, составов
                    и&nbsp;мозгов, и&nbsp;судит помышления и&nbsp;намерения сердечные. И&nbsp;нет
                    твари, сокровенной от&nbsp;Него, но&nbsp;всё обнажено и&nbsp;открыто перед
                    очами Его: Ему дадим отчёт"(Евр.4, 12−13).</em></p>
            

            
            <p>Прошу
                у&nbsp;Вас прощения и&nbsp;повторяю чудотворное слово: «каюсь» с&nbsp;надеждой
                быть услышанным. Не&nbsp;осудим слово Божие небрежением, чтобы оно
                не&nbsp;осудило нас, «<em>ибо не&nbsp;послал Бог Сына Своего в&nbsp;мир,
                    да&nbsp;судит мирови, но&nbsp;да&nbsp;спасется Им&nbsp;мир"(Ин.3, 17).</em></p>
            

            
            <p>Прошу у&nbsp;Вас
                прощения и&nbsp;примирения, чтобы разрушилось между нами средостение вражды,
                и&nbsp;мы&nbsp;примирились с&nbsp;Богом.</p>
            

            
            <p>Настоятель
                храма Святых Жен Мироносиц, кающийся грешник, иерей Павел Адельгейм</p>
            

            
            <p>Псков 18.02.04
                г.</p>
            

            
            <p>Источник: <a href="https://rusk.ru/st.php?idar=6622">Молитва и&nbsp;борьба за&nbsp;правду</a>    </p>
            
        </div>
    );
};

export default Letter12;
