import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import {Link} from "react-router-dom";
import './GroupOrientation.css';
import {
    createTheme,
    ThemeProvider,
    alpha,
    getContrastRatio,
} from '@mui/material/styles';

// Augment the palette to include a violet color

// Update the Button's color options to include a violet option

const violetBase = '#1976d2';
const violetMain = alpha(violetBase, 0.7);

const theme = createTheme({
    palette: {
        violet: {
            main: violetMain,
            light: '#42a5f5',
            dark: '#1565c0',
            contrastText: getContrastRatio(violetMain, '#74d266') > 4.5 ? '#2555bb' : '#111',
        },
    },
});
// const theme = createTheme({
//     palette: {
//         violet: {
//             main: 'rgba(24,52,52,0.2)',
//             light: alpha(violetBase, 0.5),
//             dark: alpha(violetBase, 0.9),
//             contrastText: getContrastRatio(violetMain, '#74d266') > 4.5 ? '#2555bb' : '#111',
//         },
//     },
// });
const themeSalmon = createTheme(theme, {
    // Custom colors created with augmentColor go here
    palette: {
        salmon: theme.palette.augmentColor({
            color: {
                main: 'rgba(255,51,82,0.71)',
            },
            name: 'salmon',
        }),
    },
});
const showDetails = () => {
    return [
        <Link to="/nachalo/"><Button color="violet" key="key1" className="Menu-button" variant="contained" onClick={()=>{window.location="/nachalo/"}}>Предисловие</Button></Link>,
        <Link to="/fatheradelgeim/"><Button color="violet" key="key2" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/fatheradelgeim/"}}>Дело о. Павла Адельгейма</Button></Link>,
        <Link to="/chronologyadelgeim/"><Button color="violet" key="key3" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/chronologyadelgeim/"}}>1. «Не дав ничего, Вы отняли у меня всё»</Button></Link>,
        <Link to="/strugglechurch/"><Button color="violet" key="key4" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/strugglechurch/"}}>2. Борьба за приходской устав</Button></Link>,
        <Link to="/zaostrov/"><Button color="violet" key="key5" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/zaostrov/"}}>«Заостровское дело»</Button></Link>,
        <Link to="/zaos1/"><Button color="violet" key="key6" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/zaos1/"}}>1. Новый епископ «пристреливается»</Button></Link>,
        <Link to="/zaos2/"><Button color="violet" key="key7" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/zaos2/"}}>2. Доносчики и обвинители</Button></Link>,
        <Link to="/zaos3/"><Button color="violet" key="key8" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos3/"}}>3. Верните нашего брата - о. Павла!</Button></Link>,
        <Link to="/zaos4/"><Button color="violet" key="key9" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos4/"}}>4. Канонада всё явственнее</Button></Link>,
        <Link to="/zaos5/"><Button color="violet" key="key10" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos5/"}}>5. Захват Заостровья</Button></Link>,
        <Link to="/zaos6/"><Button color="violet" key="key11" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/zaos6/"}}>6. После битвы</Button></Link>,
        <Link to="/non-licet/"><Button color="violet" key="key12" className="Medium-menu-button" variant="contained" onClick={()=>{window.location="/non-licet/"}}>Не должно вам быть</Button></Link>,
        <Link to="/tver/"><Button color="violet" key="key13" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/tver/"}}>1. О непричащении в Твери</Button></Link>,
        <Link to="/krasnousolsk/"><Button color="violet" key="key14" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/krasnousolsk/"}}>2. О непричащении в Красноусольском</Button></Link>,
        <Link to="/nizhnevartovsk/"><Button color="violet" key="key15" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/nizhnevartovsk/"}}>3. О непричащении в Нижневартовске</Button></Link>,
        <Link to="/velsk/"><Button color="violet" key="key16" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/velsk/"}}>4. О непричащении в Вельске</Button></Link>,
        <Link to="/fast/"><Button color="violet" key="key17" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/fast/"}}>Преследование прот. Геннадия Фаста</Button></Link>,
        // <Link to="/berdyansk/"><Button color="violet" key="key18" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/berdyansk/"}}>Община и спонсор</Button></Link>,
        <Link to="/konec/"><Button color="violet" key="key19" className="Menu-button" variant="contained" onClick={()=>{window.location="/konec/"}}>Заключение</Button></Link>
        // <Link to="/2015-08-16-velsk/"><Button color="violet" key="key20" className="Menu-button" variant="contained" onClick={()=>{window.location="/2015-08-16-velsk/"}}>2015/08-16 Вельск</Button></Link>,
        // <Link to="/2014-08-01-velsk/"><Button color="violet" key="key21" className="Menu-button" variant="contained" onClick={()=>{window.location="/2014-08-01-velsk/"}}>2014/08-01 Вельск</Button></Link>,
        // <Link to="/2014-12-06-velsk/"><Button color="violet" key="key22" className="Menu-button" variant="contained" onClick={()=>{window.location="/2014-12-06-velsk/"}}>2014/12-06 Вельск</Button></Link>,
        // <Link to="/2015-04-28-velsk/"><Button color="violet" key="key23" className="Menu-button" variant="contained" onClick={()=>{window.location="/2015-04-28-velsk/"}}>2015/04-28 Вельск</Button></Link>,
        // <Link to="/2015-06-22-velsk/"><Button color="violet" key="key24" className="Menu-button" variant="contained" onClick={()=>{window.location="/2015-06-22-velsk/"}}>2015/06-22 Вельск</Button></Link>,
        // <Link to="/2014-07-08-velsk/"><Button color="violet" key="key25" className="Menu-button" variant="contained" onClick={()=>{window.location="/2014-07-08-velsk/"}}>2014/07-08 Вельск</Button></Link>,
        // <Link to="/2014-04-08-velsk2/"><Button color="violet" key="key26" className="Menu-button" variant="contained" onClick={()=>{window.location="/2014-04-08-velsk2/"}}>2014/04-08 Вельск</Button></Link>,
        // <Link to="/2014-04-08-velsk/"><Button color="violet" key="key27" className="Menu-button" variant="contained" onClick={()=>{window.location="/2014-04-08-velsk/"}}>2014/04-08 Вельск</Button></Link>,
        // <Link to="/hanty-deti/"><Button color="violet" key="key28" className="Menu-button" variant="contained" onClick={()=>{window.location="/hanty-deti/"}}>Из воспоминаний Марии Никитиной</Button></Link>,
        // <Link to="/2012-2-hanty/"><Button color="violet" key="key29" className="Menu-button" variant="contained" onClick={()=>{window.location="/2012-2-hanty/"}}>2012/2 - Нижневартовск</Button></Link>,
        // <Link to="/2013-05-14-hanty/"><Button color="violet" key="key30" className="Menu-button" variant="contained" onClick={()=>{window.location="/2013-05-14-hanty/"}}>2013/05-14 Нижневартовск</Button></Link>,
        // <Link to="/dvorkin-here/"><Button color="violet" key="key31" className="Menu-button" variant="contained" onClick={()=>{window.location="/dvorkin-here/"}}>Из блогов</Button></Link>,
        // <Link to="/2012-11-03-ufa/"><Button color="violet" key="key32" className="Menu-button" variant="contained" onClick={()=>{window.location="/2012-11-03-ufa/"}}>2012/11-03 Уфа</Button></Link>,
        // <Link to="/2010-04-1-ufa/"><Button color="violet" key="key33" className="Menu-button" variant="contained" onClick={()=>{window.location="/2010-04-1-ufa/"}}>2010/04-1 Уфа</Button></Link>,
        // <Link to="/2012-04-04-1-ufa/"><Button color="violet" key="key34" className="Menu-button" variant="contained" onClick={()=>{window.location="/2012-04-04-1-ufa/"}}>2012/04-04/1 Уфа</Button></Link>,
        // <Link to="/2012-04-04-ufa/"><Button color="violet" key="key35" className="Menu-button" variant="contained" onClick={()=>{window.location="/2012-04-04-ufa/"}}>2012/04-04 Уфа</Button></Link>,
        // <Link to="/2010-12-14-ufa1/"><Button color="violet" key="key36" className="Menu-button" variant="contained" onClick={()=>{window.location="/2010-12-14-ufa1/"}}>2010/12-14 Уфа</Button></Link>,
        // <Link to="/2010-12-14-ufa/"><Button color="violet" key="key37" className="Big-menu-button" variant="contained" onClick={()=>{window.location="/2010-12-14-ufa/"}}>Методическое пособие по миссионерской деятельности в Уфимской епархии</Button></Link>,
        // <Link to="/2012-09-12/"><Button color="violet" key="key38" className="Menu-button" variant="contained" onClick={()=>{window.location="/2012-09-12/"}}>2012/09-12</Button></Link>,
        // <Link to="/6-11-2011/"><Button color="violet" key="key39" className="Menu-button" variant="contained" onClick={()=>{window.location="/6-11-2011/"}}>2011/11-6</Button></Link>,
        // <Link to="/2010-05-2/"><Button color="violet" key="key40" className="Menu-button" variant="contained" onClick={()=>{window.location="/2010-05-2/"}}>2010/05-2</Button></Link>,
        // <Link to="/2010-05-1/"><Button color="violet" key="key41" className="Menu-button" variant="contained" onClick={()=>{window.location="/2010-05-1/"}}>2010/05-1</Button></Link>,
        // <Link to="/2008-03/"><Button color="violet" key="key42" className="Menu-button" variant="contained" onClick={()=>{window.location="/2008-03/"}}>2008/03</Button></Link>,
        // <Link to="/2008-03-04/"><Button color="violet" key="key43" className="Menu-button" variant="contained" onClick={()=>{window.location="/2008-03-04/"}}>2008/03/04</Button></Link>,
        // <Link to="/2008-02-23-1/"><Button color="violet" key="key44" className="Menu-button" variant="contained" onClick={()=>{window.location="/2008-02-23-1/"}}>2008/02/23-1</Button></Link>,
        // <Link to="/2008-02-23-2/"><Button color="violet" key="key45" className="Menu-button" variant="contained" onClick={()=>{window.location="/2008-02-23-2/"}}>2008/02/23-2</Button></Link>,
        // <Link to="/22-02-2008/"><Button color="violet" key="key46" className="Menu-button" variant="contained" onClick={()=>{window.location="/22-02-2008/"}}>2008/02/22</Button></Link>,
        // <Link to="/13-03-2005/"><Button color="violet" key="key47" className="Menu-button" variant="contained" onClick={()=>{window.location="/13-03-2005/"}}>2005/03/13</Button></Link>,
        // <Link to="/1-03-2005/"><Button color="violet" key="key48" className="Menu-button" variant="contained" onClick={()=>{window.location="/1-03-2005/"}}>2005/03/01</Button></Link>,
        // <Link to="/18-04-2004/"><Button color="violet" key="key49" className="Menu-button" variant="contained" onClick={()=>{window.location="/18-04-2004/"}}>2004/02/18</Button></Link>,
        // <Link to="/2012-11-11-ufa/"><Button color="violet" key="key50" className="Menu-button" variant="contained" onClick={()=>{window.location="/2012-11-11-ufa/"}}>2012/11-11 Красноусольск</Button></Link>,
        // <Link to="/2013-5-23-ufa/"><Button color="violet" key="key51" className="Menu-button" variant="contained" onClick={()=>{window.location="/2013-5-23-ufa/"}}>2013/5-23 Уфа</Button></Link>,
        // <Link to="/2013-07-26-ufa/"><Button color="violet" key="key52" className="Menu-button" variant="contained" onClick={()=>{window.location="/2013-07-26-ufa/"}}>2013/07-26 Уфа</Button></Link>,
        // <Link to="/2013-05-21-hanty/"><Button color="violet" key="key53" className="Menu-button" variant="contained" onClick={()=>{window.location="/2013-05-21-hanty/"}}>2013/05-21 Нижневартовск</Button></Link>,
        // <Link to="/2014-08-1-velsk/"><Button color="violet" key="key54" className="Menu-button" variant="contained" onClick={()=>{window.location="/2014-08-1-velsk/"}}>2014/08-1 Вельск</Button></Link>,
    ];
}
export default function GroupOrientation() {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column',justifyContent: 'space-around',alignItems: 'stretch' }}>
            <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                <ThemeProvider theme={themeSalmon} >
                {showDetails()}
                </ThemeProvider>
            </ButtonGroup>
        </Box>
    );
}
