import React from "react";
import './text.css';
const Velsk4 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2015/04-28 Вельск</h2>
            
            <p class="has-text-align-right">Его Высокопреосвященству,</p>
            

            
            <p class="has-text-align-right">Высокопреосвященному ВАРСОНОФИЮ,</p>
            

            
            <p class="has-text-align-right">митрополиту Санкт-Петербургскому и
                Ладожскому,</p>
            

            
            <p class="has-text-align-right">управляющему делами Московской
                Патриархии</p>
            

            
            <p>ВАШЕ
                ВЫСОКОПРЕОСВЯЩЕНСТВО, благословите! Христос воскрес!</p>
            

            
            <p>В
                эти светлые и радостные&nbsp; пасхальные дни,
                с болью в сердце и неизменной надеждой, мы обращаемся&nbsp; к Вашему Высокопреосвященству с повторной
                просьбой принять участие в разрешении церковной ситуации в г.Вельске
                Архангельской области. &nbsp;&nbsp;&nbsp;&nbsp;</p>
            

            
            <p>Мы
                уже писали Вам, что являемся постоянными прихожанами Свято-Успенского храма
                города Вельска на протяжении последних двадцати лет. Двадцать лет мы регулярно
                участвовали в таинственной жизни Церкви — регулярно исповедовались и
                причащались. Однако в этом году ситуация резко изменилась — нам стали
                отказывать в Причастии лишь на том основании, что мы пришли к Богу и в Церковь
                благодаря катехизаторским усилиям священника Георгия Кочеткова и
                Преображенского братства, что мы активно делимся с нашими родными, близкими и
                друзьями своим свидетельством о том, что в наше непростое время единственный
                источник Утешения, Радости, Надежды и Любви мы нашли для себя в благодатной
                жизни Русской Православной Церкви. Исключением не стала и пасхальная Литургия,
                на которой настоятель&nbsp; единственного в
                г.Вельске храма о.Андрей&nbsp; Ермилов, объявил
                что &nbsp;для нас Причастие будет в искушение.
                Ответ архимандрита САВВЫ (Тутунова) на наше&nbsp;
                первое письмо (РКПД-01-0104/3717; 01.08.14; Брильков А.С.).не возымел
                никакого действия. о.Андрей Ермилов сказал, что причащать нас все равно не
                будет и это его личная позиция. О.Андрей открыто высказывает недоверие &nbsp;не только Патриарху КИРИЛЛу, но и вообще
                священноначалию РПЦ. Кроме этого, для него не имею никакого значения и
                документы МП. Например, «Об участии верных в Евхаристии».</p>
            

            
            <p>&nbsp;За последний год обстановка в приходе стала
                крайне раскалённой,
                нездоровой, агрессивной.
                &nbsp;о.Андрей&nbsp;
                Ермилов неотступно сеет и всячески подогревает вражду межу прихожанами.
                У нас вызывает большое опасение духовное состояние части прихода, которых
                провоцирует настоятель. &nbsp;о.Андрей во
                время Литургии молится о преодолении раскола, об избавлении города от сект.
                Люди заражены немирным, враждебным духом. Во время Литургии прихожане
                возмущенно выгоняют нас в конец храма. Кричат, что нам нужно стоять в строго
                отведенном месте. ВАШЕ ВЫСОКОПРЕОСВЯЩЕНСТВО, город наш маленький, все друг
                друга знают и такие проявления сеют вражду не только в Церкви, но и в обществе.
                Откровенно враждебное поведение настоятеля, неизменно ведет к церковному и
                социальному взрыву. </p>
            

            
            <p>ВАШЕ
                ВЫСОКОПРЕОСВЯЩЕНСТВО! Мы обращаемся к Вам, как к Управляющему делами Московской
                Патриархии c просьбой положить конец тому расколу Вельского прихода, который
                совершается по инициативе его настоятеля и восстановить общецерковное отношение
                к Святым Таинствам!</p>
            

            
            <p>Испрашивая
                Вашего архипастырского благословения,</p>
            

            
            <p><em>прихожане Свято-Успенского храма г.
                Вельска:</em></p>
            

            
            <p><em>Привалова Т.</em></p>
            

            
            <p><em>Привалова А.</em></p>
            

            
            <p><em>Холзакова В.</em></p>
            

            
            <p><em>Кондакова Т.</em></p>
            

            
            <p><em>Кудрявцев Е.</em></p>
            
        </div>
    );
};

export default Velsk4;
