import React from "react";
import './text.css';
const Velsk6 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2014/07-08 Вельск</h2>
            
            <p class="has-text-align-right">Его Высокопреосвященству,</p>
            

            
            <p class="has-text-align-right">Высокопреосвященному ВАРСОНОФИЮ,</p>
            

            
            <p class="has-text-align-right">митрополиту Санкт-Петербургскому и
                Ладожскому,</p>
            

            
            <p class="has-text-align-right">управляющему делами Московской
                Патриархии</p>
            

            
            <p class="has-text-align-right">копия:</p>
            

            
            <p class="has-text-align-right">Его Преосвященству, </p>
            

            
            <p class="has-text-align-right">Преосвященному ВАСИЛИЮ,</p>
            

            
            <p class="has-text-align-right">епископу Котласскому и Вельскому</p>
            

            
            <p class="has-text-align-right">прихожан Свято-Успенского храма г.
                Вельска</p>
            

            
            <p class="has-text-align-right">Котласской и Вельской епархии</p>
            

            
            <p>ВАШЕ
                ВЫСОКОПРЕОСВЯЩЕНСТВО, благословите!</p>
            

            
            <p>Просим
                Вас оказать содействие в разрешении ситуации, сложившейся на Свято-Успенском
                приходе города Вельска.</p>
            

            
            <p>Большинство
                из нас стали прихожанами Свято-Успенского храма 20 лет назад. За эти годы
                произошло наше воцерковление. Теперь мы не мыслим своей жизни без Бога и
                Русской Православной Церкви. Около 20 лет мы регулярно участвуем в таинственной
                жизни Церкви. Однако полгода назад настоятель Вельского прихода (он же Вельский
                благочинный) протоиерей Андрей Ермилов стал отказывать нам в регулярном
                причастии лишь на том основании, что мы пришли в Русскую Православную Церковь
                благодаря помощи и поддержке священника Георгия Кочеткова и Преображенского
                братства. Несмотря на то, что каждый раз мы готовимся ко Святому Причащению
                (соблюдаем посты, читаем положенные молитвы, каждую субботу посещаем Всенощное
                бдение, избегаем тяжких (смертных) грехов), нам дают разрешительную молитву, но
                отказывают в Святом Причащении. Так происходит каждую неделю последние четыре
                месяца.</p>
            

            
            <p>Несколько
                раз мы пытались поговорить с нашим настоятелем протоиереем Андреем Ермиловым,
                чтобы понять канонические причины нашего отлучения от Причастия. Вместо ответа
                отец Андрей стал натравливать на нас других прихожан, провёл провокационное
                «приходское собрание», пропитанное клеветой на нас. В частных беседах стал
                говорить, что он бы всех нас перестрелял, что он пользуется полной поддержкой
                владыки ВАСИЛИЯ. После «приходского собрания» (от 31 марта 2014 года) мы
                написали письмо епископу Котласскому и Вельскому ВАСИЛИЮ. После письма мы
                дважды встречались с владыкой ВАСИЛИЕМ (30 апреля, когда он посещал город
                Вельск с архипастырским визитом и 16 июня, когда мы специально ездили к нему в
                Епархиальное управление). К сожалению, и после встреч с владыкой ВАСИЛИЕМ ситуация
                в Вельском приходе усугубляется. Агрессивное поведение отца Андрея даёт дурные
                плоды. Обстановка на приходе накаляется. Вольно или невольно наш настоятель
                создаёт нездоровую обстановку и устраивает раскол. </p>
            

            
            <p>ВАШЕ
                ВЫСОКОПРЕОСВЯЩЕНСТВО, обращаемся к ВАМ, как к Управляющему делами Московской
                Патриархии! Мы убедительно просим ВАС оказать содействие в разрешении ситуации,
                связанной с неправедным отлучением нас от Тела Христова.</p>
            

            
            <p><em>Испрашивая ВАШИХ святых молитв,
                архипастырского благословения, прихожане Свято-Успенского храма г. Вельска:</em></p>
            

            
            <p><em>Привалова Т.</em></p>
            

            
            <p><em>Привалова А.</em></p>
            

            
            <p><em>Холзакова В.</em></p>
            

            
            <p><em>Кондакова Т.</em></p>
            

            
            <p><em>Кудрявцев Е.</em></p>
            

            
            <p><em>08 июля 2014г</em></p>
            
        </div>
    );
};

export default Velsk6;
