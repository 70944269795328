import React from "react";
import './text.css';
const Letter6 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2008/03/04</h2>
            <p>Прокурору г. Пскова от Обозного
                Константина Петровича прож. Псков, 180007, ул. Горького 15 кв.7<br></br>
                    <br></br>
                        <strong>Заявление</strong><br></br>
                        <br></br>
                            В порядке надзора за соблюдением прав и свобод человека и гражданина
                            руководителями некоммерческих организаций прошу провести проверку правомерности
                            действий священника Сергия Иванова, являющегося настоятелем Храма Святых Жен
                            Мироносиц при изъятии им обращения к Митрополиту и листов с подписями у
                            прихожан храма.<br></br>
                            <br></br>
                                Указанные события имели место 1 марта 2008 года около 13 часов.<br></br>
                                <br></br>
                                    Войдя в помещение школы регентов по адресу г. Псков ул. Коммунальная 13,
                                    священник Сергий изъял у работников школы обращение к Митрополиту и листы с
                                    подписями в поддержку этого обращения, потребовал прекратить сбор подписей.
                                    Сбор подписей был организован членами Приходского совета храма с целью
                                    доведения до Митрополита Псковского и Великолукского Евсевия мнения прихожан.
                                    Изъяв указанные документы, отец Сергий попытался пресечь возможность реализации
                                    прихожанами права на свободу слова и волеизъявления, гарантированные
                                    Конституцией РФ.<br></br>
                                    <br></br>
                                        Прошу провести поверку имеются ли в указанных действиях священника Сергия
                                        Иванова признаки преступления, предусмотренного ст. 330 УК РФ
                                        "Самоуправство" и обязать его вернуть изъятые документы.<br></br>
                                        <br></br>
                                            либо<br></br>
                                            <br></br>
                                                Прошу провести поверку законности самоуправных действий священника Сергия
                                                Иванова и обязать его вернуть изъятые документы.</p>
            
            </div>
    );
};

export default Letter6;
