import React from "react";
import './text.css';
const Hanty2 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2013/05-14 Нижневартовск</h2>
            
            <p>Ваше Высокопреосвященство! </p>
            

            
            <p>Поздравляю Вас со светлым праздником Христова Воскресения - праздником победы Жизни над смертью, Смысла и Истины над пустотой и ложью, Красоты над всяким безобразием! В Воскресении Христа открывается тайна Боголюбия и братолюбия, тайна мира и прощения. Светлый праздник Христова Воскресения обновляет в нас память о крестном пути любви и свободы в этом мире, ведь именно этот путь даёт силу преодолевать всякую неправду, вражду и разделение. Не забудем и слов Спасителя: «Если же согрешит против тебя брат твой, пойди и обличи его между тобою и им одним» (Мф 18:15), не прибегая ни к какому лжесвидетельству посредников. Пусть же воскресший Христос дарует Вам силы и вдохновение для участия в богочеловеческом деле созидания истинной Церкви, преодоления всякого разделения и отчуждения, всякой пустоты и богооставленности, а также для воплощения евангельского духа Православия в жизни. Устремим наши взоры на Спасителя, чтобы нам всегда ходить во Свете Его Воскресения и нести этот Свет миру! </p>
            

            
            <p class="has-text-align-right">С любовью во Христе, </p>
            

            
            <p class="has-text-align-right">профессор священник Георгий Кочетков. </p>
            

            
            <p>Воистину воскресе Христос!</p>
            
        </div>
    );
};

export default Hanty2;
