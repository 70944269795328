import React from "react";
import './text.css';
const Velsk1 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2015/08-16 Вельск</h2>
            
            <p class="has-text-align-right">Его
                Высокопреосвященству,</p>
            

            
            <p class="has-text-align-right">Высокопреосвященному
                ВАРСОНОФИЮ,</p>
            

            
            <p class="has-text-align-right">митрополиту
                Санкт-Петербургскому и Ладожскому,</p>
            

            
            <p class="has-text-align-right">управляющему
                делами Московской Патриархии</p>
            

            
            <p>ВАШЕ
                ВЫСОКОПРЕОСВЯЩЕНСТВО, благословите!<em></em></p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Благодарим
                Вас за детальное рассмотрение нашего обращения в Управление делами Московской Патриархии
                по поводу ситуации в Свято-Успенском храме города Вельска. Ваш ответ от
                22.06.2015 г. (исх. №01/3513) несколько прояснил нам причины, послужившие
                поводом для нашего отлучения от Причастия Святых Христовых Тайн на протяжении полутора
                лет. Тем не менее мы снова дерзаем обратиться к Вам, Ваше Высокопреосвященство,
                потому что в Вашем ответе есть такие слова:</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; «По
                сведениям Котласского епархиального управления, на территории Вельского
                благочиния без благословения правящего архиерея было создано братство во имя
                святителя Стефана Великопермского, являющегося частью «Преображенского
                братства». Епископ Котласский Василий неоднократно призывал Вас прекратить
                различные параллельные Успенскому храму огласительные собрания, произвести
                роспуск братства и войти в духовное подчинение Успенскому храму».</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; К глубокому сожалению, Вы введены в
                заблуждение предоставленной Вам информацией из Котласского епархиального
                управления.</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Мы неоднократно пытались объяснить
                настоятелю нашего храма протоиерею Андрею Ермилову, что мы не являемся
                организацией или структурой, которую можно создавать или распускать. Дело в
                том, что мы живём в маленьком городе, где всё переплетено родственными,
                деловыми и приятельскими отношениями. Мы вместе росли, воспитывали детей, были
                коллегами по работе. Это продолжалось не годы, а десятилетия!</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; В начале и середине 1990-х годов многие
                из нас приняли Святое Крещение в Русской Православной Церкви, уверовали в
                Господа нашего Иисуса Христа, воцерковились, в том числе благодаря пастырским
                усилиям священника Георгия Кочеткова и Преображенского братства. Несмотря на
                то, что все эти годы мы активно помогали друг другу приходить к Богу и в
                Церковь, путь к Святому Крещению, первой Исповеди или Святому Причастию у
                каждого из нас был свой. Поэтому кто-то из нас, воцерковившись, становился
                активным прихожанином Вельского храма и помогал батюшкам в решении приходских,
                хозяйственных и социальных проблем, а кто-то просто посещал храм по мере своих
                сил и возможностей.</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Вдохновляемые и укрепляемые примерами
                святых новомучеников и исповедников Российских, их рассказами и свидетельствами
                о братской жизни православных христиан в трудных условиях, мы полюбили и всем
                своим сердцем восприняли слово «Братство» («Всех почитайте, братство любите,
                Бога бойтесь, царя чтите» (1 Пет. 2:17)). Для нас вся Русская Православная
                Церковь является не только Телом Христовым, но и Братством Христовым, в котором
                мы призваны жить, которое нужно любить и о котором нужно заботиться. </p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Таким образом, никакого братства мы не
                создавали, никаких учредительных собраний не проводили, никаких юридических регистраций
                не имеем. Что же есть? — Есть многодесятилетний родственный, дружеский круг, для
                которого вера Христова стала главной святыней и движущей силой жизни. Что нам
                теперь делать? — Отречься от веры во Христа? — Но мы не можем предположить,
                чтобы православный епископ хотел именно этого. Как нам «произвести роспуск
                братства»? — Разорвать семейные и дружеские связи? — Но ведь Господь говорит:
                «что Бог сочетал, того человек да не разлучает» (Мк. 10:9). Ещё раз повторим:
                мы не создавали никакого братства, мы просто осознали, что православная вера
                Христова соединяет нас глубже и крепче, чем родственные связи. Это произошло
                больше 10 лет назад при покойном владыке Тихоне (Степанове). Владыка Тихон знал
                о существовании нашего круга, иногда, если у настоятелей нашего прихода
                возникали к нам вопросы, помогал в их мирном и конструктивном разрешении.</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; К сожалению, ситуация резко изменилась за
                последние два года. С назначением нового настоятеля — протоиерея Андрея
                Ермилова жизнь прихода стала взбудораженной. Почему-то отец Андрей считает
                возможным раскалывать приход на разные части и натравливать одних прихожан на
                других. Атмосфера в храме стала агрессивной: нас хватают за руки, толкают в
                спины, распространяют о нас ложные слухи. Больше года нас не причащают. Мы
                неоднократно пытались поговорить с отцом Андреем, владыкой Василием, чтобы с
                нас сняли необоснованный, немотивированный запрет на Причастие или дали
                письменное каноническое обоснование нашего отлучения.</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ваше Высокопреосвященство! Мы, конечно,
                глубоко скорбим, что снова отвлекаем Ваше внимание на жизнь Вельского прихода,
                но ведёт нас к этому святая любовь к Матери-Церкви.</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Мы снова и снова просим Вас вникнуть в
                нашу ситуацию, потому что без Вашей помощи и участия она разрешиться не может.
                Если мы в чём-то виновны пред Господом Богом и Матерью-Церковью, то пусть нам
                дадут весомое каноническое обоснование, а если нет, то надо положить конец
                манипулированию Святым Причастием («Мы вас причащать не будем, потому что вы
                нам не нравитесь!»).</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Просим прощения за возможные огорчения
                и Вашего архипастырского участия и благословения!</p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Недостойные богомольцы </p>
            

            
            <p>Самухин М.</p>
            

            
            <p>Кудрявцев Е.</p>
            

            
            <p>Петухова Г.</p>
            

            
            <p>Привалова А. </p>
            

            
            <p>Кондакова Т. </p>
            

            
            <p><strong>16.08.2015г</strong></p>
            
        </div>
    );
};

export default Velsk1;
