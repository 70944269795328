import React from "react";
import './text.css';
const Letter11 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2005/03/01</h2>
            
            <p><strong><em>Письмо свящ. Павла Адельгейма архиепископу Псковскому и&nbsp;Великолукскому Евсевию с просьбой о
                примирении от 01.03.2005 г.</em></strong></p>
            

            
            <p>Его Высокопреосвященству
                Высокопреосвященному
                Евсевию, архиепископу 
                Псковскому и&nbsp;Великолукскому.</p>
            

            
            <p><strong>Ваше Высокопреосвященство!</strong></p>
            

            
            <p><em>«Снисходя друг другу и&nbsp;если кто на&nbsp;кого имеет жалобу Христос
                простил вас, так и&nbsp;вы» (Кол.3,12−13</em>).</p>
            

            
            <p>Январский номер
                Псковского епархиального вестника* опубликовал «Деяния епархиального собрания»
                и&nbsp;«Обращение к&nbsp;Святейшему Патриарху Московскому и&nbsp;всея Руси
                Алексию П» <strong><em>(текст публикации не найден)</em></strong>, осуждающее <em>«поступки,
                    недостойные звания православного священнослужителя, одного из&nbsp;клириков
                    Псковской епархии протоиерея Павла Адельгейма» </em>[ «Благодатные лучи» N1 (81)
                2005&nbsp;г.&nbsp;стр.9−11]. Жалоба и&nbsp;публикация не&nbsp;указывают конкретных
                поступков, квалифицированных епархиальной прессой в&nbsp;качестве
                «недостойных». Общие фразы не&nbsp;разъясняют истоки вражды к <em>«одному
                    из&nbsp;клириков Псковской епархии»</em>, не&nbsp;предлагают пути
                к&nbsp;её&nbsp;преодолению.</p>
            

            
            <p>Выполняя Волю
                Вашего Высокопреосвященства, я&nbsp;опубликовал в&nbsp;«МК» своё покаянное
                письмо, испрашивая у&nbsp;Вас прощения за&nbsp;невольную обиду, причинённую
                публикацией моей книги «Догмат о&nbsp;Церкви». Поскольку реакция Вашего
                Высокопреосвященства осталась мне неизвестной, ещё раз выполняю Ваше пожелание
                и&nbsp;приношу своё покаяние через «Псковскую правду». Подчиняясь требованию
                христианской совести, снова прошу у&nbsp;Вас милости и&nbsp;терпения
                к&nbsp;моему настойчивому поиску примирения. </p>
            

            
            <p>Может&nbsp;ли
                христианин иметь врагов? Может, если Христос Спаситель заповедал: «любите враги
                ваша». Как понимать слово «враг»? По&nbsp;определению В. Даля,
                «недоброжелатель"&nbsp;— человек, который желает мне зла, старается
                причинить неприятности и&nbsp;злорадствует, когда со&nbsp;мной случается
                несчастье. Христианин может иметь врагов, но&nbsp;сам не&nbsp;может быть врагом
                никому. Отношение к&nbsp;другому&nbsp;— другу или врагу,&nbsp;— которое
                заповедал Бог, называется любовью: <em>«Любите врагов ваших, благословляйте
                    проклинающих вас, благотворите ненавидящим вас и&nbsp;молитесь
                    за&nbsp;обижающих вас и&nbsp;гонящих вас» (Мф.5,44). </em></p>
            

            
            <p>Тот, кто любит
                и&nbsp;прощает, не&nbsp;может быть врагом. Понятие «враг» в&nbsp;словах Христа
                Спасителя выражает одностороннее, а&nbsp;не&nbsp;взаимное отношение. Христианин
                может иметь врагов, но&nbsp;сам никому врагом быть не&nbsp;может.
                Я&nbsp;отказываюсь быть Вашим врагом и&nbsp;желаю Вам блага. Жития святых
                Никифора и&nbsp;Саприкия, Тита и&nbsp;Евагрия выражают церковную традицию:
                благодать Божия оставляет тех, чьё сердце затаило месть и&nbsp;не&nbsp;умеет
                прощать. Эта новозаветная традиция запечатлена в&nbsp;Евангелии
                и&nbsp;апостольских посланиях. </p>
            

            
            <p><em>«Дети Божии и&nbsp;дети диавола узнаются так:</em><em>не</em><em> </em><em>есть</em><em> </em><em>от&nbsp;Бога</em><em> </em><em>не&nbsp;любящий</em><em> </em><em>брата»</em><em> (1</em><em>Ин</em><em>.3,10).
            </em><em>«Не&nbsp;любящий</em><em> </em><em>брата</em><em> </em><em>пребывает</em><em> </em><em>в&nbsp;смерти»</em><em> (1</em><em>Ин</em><em>.3, 14).
            </em><em>«Всякий</em><em>, </em><em>ненавидящий</em><em> </em><em>брата</em><em> </em><em>своего</em><em>, </em><em>есть</em><em> </em><em>человекоубийца</em><em>(1</em><em>Ин</em><em>.3, 15). 
            </em><em>«Кто</em><em> </em><em>говорит</em><em>: </em><em>я&nbsp;люблю</em><em> </em><em>Бога</em><em>, </em><em>а&nbsp;брата</em><em> </em><em>своего</em><em> </em><em>ненавидит</em><em>, </em><em>тот</em><em> </em><em>лжец</em><em>"(</em><em>Ин</em><em>.4, 20). 
            </em><em>«Мы&nbsp;имеем</em><em> от&nbsp;Него заповедь, чтобы
                любящий Бога любил и&nbsp;брата своего» (Ин.4, 21). </em></p>
            

            
            <p>Если
                мы&nbsp;верим истине этих слов, а&nbsp;не&nbsp;только используем для проповеди,
                как может совесть наша оставаться в&nbsp;безмятежности, пока в&nbsp;сердце
                живёт вражда?</p>
            

            
            <p>Вы
                и&nbsp;я&nbsp;предстоим одному и&nbsp;тому&nbsp;же Святому Престолу, совершая
                Божественную Литургию, и&nbsp;зовём: «Возлюбим друг друга»! Встаёт неизбежный
                вопрос: «Наши слова выражают крик души или ханжество, за&nbsp;которое Христос
                осуждал фарисеев»? Да&nbsp;не&nbsp;будет! Я&nbsp;не&nbsp;подхожу под
                определение «врага», поскольку постоянно молюсь Богу о&nbsp;Вас, Вашем здоровье
                и&nbsp;благополучии и&nbsp;желаю Вам искренне прощения и&nbsp;милости
                от&nbsp;Бога.</p>
            

            
            <p>Много раз
                я&nbsp;письменно просил у&nbsp;Вас прощения за&nbsp;огорчение, невольно
                причинённое публикацией книги. Мои письма к&nbsp;Вам всегда безответны. Однажды
                Вы&nbsp;ответили на&nbsp;моё покаяние письмом, содержавшим обличения,
                оскорбления, вечное осуждение на&nbsp;«место, со&nbsp;всеми клеветниками
                и&nbsp;диаволу уготованное им&nbsp;от&nbsp;вечности» и&nbsp;пожелание «принести
                всенародное покаяние через средства массовой информации». Я&nbsp;понимаю Вашу
                обиду.</p>
            

            
            <p>Я&nbsp;не&nbsp;понимаю
                её&nbsp;неутолённости в&nbsp;течение трёх лет. Я&nbsp;давно готов придти
                к&nbsp;Вашему Высокопреосвященству, просить примирения,
                но&nbsp;Вы&nbsp;отказываете мне в&nbsp;«кабинетном примирении». </p>
            

            
            <p>Вы&nbsp;требуете,
                что&nbsp;бы я&nbsp;отрёкся от&nbsp;написанной книги, поскольку считаете
                её&nbsp;личной инвективой против Вас. Это не&nbsp;верно.</p>
            

            
            <p>Книга состоит
                из&nbsp;трёх частей. Первая часть раскрывает православную екклезиологию. Вторая
                часть раскрывает учение о&nbsp;Церкви с&nbsp;позиций действующего Устава РПЦ,
                а&nbsp;третья показывает как Устав РПЦ осуществляется (и, к&nbsp;сожалению,
                нарушается) в&nbsp;практике епархиальной жизни. Достоверность требует
                свидетельства очевидца, готового отвечать за&nbsp;свои слова. Поэтому
                я&nbsp;пишу о&nbsp;том, что вижу своими глазами и&nbsp;слышу своими ушами.
                Разве удивительно, что я&nbsp;привожу факты из&nbsp;жизни Псковской епархии? Когда
                я&nbsp;служил в&nbsp;Ташкентской епархии, то&nbsp;писал о&nbsp;деятельности
                архиепископа Ермогена (Голубева), которая оставила неизгладимое впечатление.
                Теперь я&nbsp;служу в&nbsp;Псковской епархии и&nbsp;пишу о&nbsp;том, что
                происходит на&nbsp;моих глазах. </p>
            

            
            <p>Обо всём, что
                написано в&nbsp;книге, я&nbsp;много раз писал Вам в&nbsp;своих письмах,
                докладных записках и&nbsp;прошениях. В&nbsp;книге нет ничего нового, о&nbsp;чём
                Вы&nbsp;не&nbsp;читали в&nbsp;предыдущие годы. Моя книга возникла
                из&nbsp;внутренней необходимости пережить воздвигнутое на&nbsp;меня гонение,
                понять причины и&nbsp;духовный смысл событий недопустимых, но&nbsp;происходящих
                в&nbsp;церкви.</p>
            

            
            <p>Мне пришлось
                вторично пережить нелепую ситуацию, когда меня вынуждали озлобиться
                и&nbsp;стать врагом. Прежде злым гением было Советское государство,
                Коммунистическая партия и&nbsp;их&nbsp;репрессивный аппарат. Логика
                преследования была проста: расстреляли дедов и&nbsp;отца, посадили
                в&nbsp;тюрьму мать&nbsp;— сын должен озлобиться и&nbsp;мстить: вот
                и&nbsp;поехал я&nbsp;в&nbsp;казахстанскую ссылку. </p>
            

            
            <p>А&nbsp;затем,
                пожизненная опека госбезопасности, где&nbsp;бы ни&nbsp;жил. Выгнали
                из&nbsp;средней школы, выгнали из&nbsp;Духовной семинарии, препятствовали
                венчаться, препятствовали служить, осудили, посадили, отрезали ногу&nbsp;— всё
                было понятно: сын «врагов народа» должен погибнуть, хотя никто из&nbsp;нашего
                рода не&nbsp;причинил вреда советской власти, напротив: созидал
                её&nbsp;материальные и&nbsp;духовные ценности.</p>
            

            
            <p>Впервые моё
                уважение к&nbsp;епископу подверглось испытанию при ознакомлении с&nbsp;судебным
                делом, когда я&nbsp;прочёл донос, написанный епископом, другом моей юности. Это
                была очевидная клевета. Она легла в&nbsp;основу моего приговора. Весь лагерный
                срок я&nbsp;молился Богу, чтобы мне не&nbsp;озлобиться и&nbsp;благодушно
                понести крест. Бог услышал мою молитву и&nbsp;даровал мир моему сердцу. Слава
                Богу за&nbsp;всё. </p>
            

            
            <p>С&nbsp;Вашим
                приходом в&nbsp;Псковскую епархию репрессивная история повторилась. Как
                не&nbsp;задуматься о&nbsp;смысле происходящего? За&nbsp;десять лет совместного
                служения я&nbsp;Вас ничем не&nbsp;огорчил. Вы&nbsp;не&nbsp;можете сообщить
                ни&nbsp;одного порочащего меня факта или причинённой Вам обиды. </p>
            

            
            <p>Свои обиды
                я&nbsp;забыл, когда родилась книга. </p>
            

            
            <p>Ваше
                Высокопреосвященство! </p>
            

            
            <p>На&nbsp;закате
                наших дней вспомним путь, пройденный за&nbsp;70 лет служения в&nbsp;церкви,
                и&nbsp;примиримся у&nbsp;порога Великого поста и&nbsp;грядущего суда Божия!
                Попросим вместе у&nbsp;Бога прощения грехов, веруя, «что если двое из&nbsp;вас
                согласятся на&nbsp;земле просить о&nbsp;всяком деле, то&nbsp;чего&nbsp;бы
                ни&nbsp;попросили, будет им&nbsp;от&nbsp;Отца Моего небесного» (Мф.18,19).</p>
            

            
            <p>Благодать
                и&nbsp;милость Божия с&nbsp;Вами, недостойный иерей Павел Адельгейм.
                    01марта 05&nbsp;г.&nbsp;Понедельник недели «о&nbsp;Страшном суде». </p>
            

            
            <p><strong><em>Примечание: </em>Архиепископ Евсевий ничего не&nbsp;ответил на&nbsp;это письмо.</strong> </p>
            

            
            <p>Источник: <a href="http://rusk.ru/st.php?idar=6622">http://rusk.ru/st.php?idar=6622</a></p>
            
        </div>
    );
};

export default Letter11;
