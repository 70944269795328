import React from "react";
import './text.css';
const Ufa1 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2012/11-03 Уфа</h2>
            
            <p>Его
                Высокопреосвященству, </p>
            

            
            <p>Высокопреосвященнейшему
                Никону, </p>
            

            
            <p>Митрополиту
                Уфимскому и Стерлитамакскому</p>
            

            
            <p>от
                прихожан храма Покрова Божией Матери, </p>
            

            
            <p>с.
                Красноусольский,10-го благочиния </p>
            

            
            <p><strong>Ваше
                Высокопреосвященство, Высокопреосвященнейший &nbsp;Владыко! Благословите!</strong></p>
            

            
            <p>Мы, прихожане храма Покрова Божией Матери с.
                Красноусольский, обращаемся к Вам со смиренной &nbsp;просьбой помочь нам разобраться в той
                затруднительной ситуации, которая возникла с нами и со многими прихожанами
                нашего храма. </p>
            

            
            <p>Мы трудимся в храме уже не один год, а
                некоторые из нас – более 7 лет, исполняя послушания певчих, просфорницы, продавца
                церковной лавки, уставщика, псаломщика. Все силы мы старались отдавать на
                служение Матери-Церкви, считая это основным делом своей жизни. За время нашего
                служения мы не получали никаких нареканий, всегда были верны нашему храму,
                настоятелю и Вам, Ваше Высокопреосвященство. Вся наша жизнь проходит на виду у
                прихожан и настоятеля храма.</p>
            

            
            <p>После смены настоятеля, в августе этого
                года нас в одночасье сняли со всех должностей. Мы с миром приняли это решение
                нового настоятеля о. Боголепа (Артемчука) и подчинились ему. Для нас главное - чтобы
                на приходе был мир и согласие. Мы продолжаем посещать храм каждый воскресный
                день. </p>
            

            
            <p>На Божественной литургии 30 сентября настоятель
                о. Боголеп не допустил нас до святого причастия. 21 и 28 октября не допустил
                нас до святого причастия и служащий священник о. Рафаил (Королёв). Причины применения
                столь серьезной меры, по словам о. Боголепа и о. Рафаила, следующие:</p>
            

            
            <p>1. Они обвиняют нас в некоей «кочетковской
                ереси». </p>
            

            
            <p>Ваше
                Высокопреосвященство, мы не поддерживаем никаких ересей! Мы всегда старались и
                стараемся строго держаться Святого Православия, которое для нас дороже жизни.
                Мы готовы ответственно подтвердить свою верность учению нашей Матери-Церкви и Ее
                священноначалию.</p>
            

            
            <p>Простите, Владыко, но нам не понятно, как
                можно без церковного суда обвинять кого-либо в ереси. Насколько мы знаем, делать
                вывод, что кто-то &nbsp;отошёл от Православия
                могут только общецерковные инстанции, такие как Синодальная богословская
                комиссия, Церковный Собор или Священный Синод. Ведь иначе в Церкви не будет
                порядка, все смогут обвинять друг друга в чём угодно. </p>
            

            
            <p>Мы не знакомы со свящ. Георгием Кочетковым
                лично. Знаем только, что он много лет служит в Москве в Новодевичьем монастыре
                под началом митр. Крутицкого и Коломенского Ювеналия. Если он является автором ереси,
                то как такое может быть? Мы читали, что его труды разбирала Синодальная
                богословская комиссия и ереси в них не нашла. </p>
            

            
            <p>Если обвинения нас касаются нашего участия
                в богослужении, то мы хотим сказать, что мы любим наше традиционное
                православное богослужение и категорически против каких-либо искажений его
                порядка, духа и смысла. Нам, конечно, еще не хватает знаний в области
                Православия, но мы стараемся их постепенно приобретать, насколько в наших
                немощных силах.</p>
            

            
            <p>2. Нас обвиняют в участии в «агапах». </p>
            

            
            <p>Агапа для нас – просто общая трапеза после
                богослужения, возможность пообщаться, обсудить насущные вопросы, поделиться
                друг с другом радостью о Господе. О желательности проведения таких совместных
                трапез мы прочли в «Методическом пособии по приходской социальной деятельности»,
                которое вышло в нашей епархии по благословлению Вашего Высокопреосвященства. Среди
                обвинений говорилось о том, что агапа – это как бы «вторая литургия».
                Разумеется, мы никак не поддерживаем такого взгляда. Для нас именно Евхаристия
                всегда была в центре духовной жизни. </p>
            

            
            <p><strong>Дорогой
                Владыка!</strong> Кроме нас о. Рафаил не допустил до причастия в воскресенье
                28 октября с.г. и других людей только за то, что они с нами здороваются, общаются.
                Вы, наверное, лучше нас знаете, как тяжело бывает, когда одни православные
                выступают против других. Ведь это может привести к потере доверия Церкви,
                священнослужителям, вызвать насмешки людей неверующих. </p>
            

            
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ваше Высокопреосвященство! О. Боголеп
                и о. Рафаил, совершая такие действия, ссылаются на то, что они, якобы,
                исполняют Вашу волю. Мы этому не верим. Мы знаем Вас как Ангела Церкви, как
                достойнейшего служителя Христова, который всегда творил только добро, мир и
                милость, всегда заботясь обо всех нас, о мире церковном, об исполнении
                церковных канонов. </p>
            

            
            <p>Мы обращаемся к Вам, как к миротворцу, и покорнейше
                просим Ваше Высокопреосвященство проявить милость к нам грешным и недостойным
                чадам Русской Православной Церкви, остановить необоснованные и смущающие народ
                действия священников, (которые, как мы надеемся,&nbsp; совершаются просто по какому-то
                недоразумению) и, тем самым, восстановить мир и нормальные церковные отношения
                на нашем приходе.</p>
            

            
            <p>Уповаем на Вашу милость, Владыка.</p>
            

            
            <p>Испрашивая
                Вашего святительского благословения, </p>
            

            
            <p>Вашего
                Высокопреосвященства смиренные послушники и верные чада,</p>
            

            
            <p>с
                горячей молитвой о Вас, </p>
            

            
            <p>прихожане храма Покрова Божией Матери в с. Красноусольский</p>
            
        </div>
    );
};

export default Ufa1;
