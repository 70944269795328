import React from "react";
import './text.css';

const Nachalo = () => {
    return (
        <div align={'start'} className="text1">
           <h2>Предисловие</h2>
            
            <p>Этот сборник
                продолжает предыдущие выпуски «Христианского вестника». Начиная с 1993 года они
                рассказывают не просто о проблемах и конфликтах, сопровождавших жизнь Русской
                православной церкви после снятия антицерковных законов, но о настоящей войне
                против попыток подлинного её возрождения. Там, где в центре церковной жизни
                стояло не истребление многочисленных мнимых «врагов», не жесткое охранительство
                с девизом «всем стоять на своих местах!», а просвещение, строительство братств,
                рождение общин, неизменно возникали спровоцированные немногочисленной (а иногда
                и очень многочисленной и информационно оснащенной) группой «ревнителей» конфликты,
                чаще всего заканчивавшиеся уничтожением и так немногочисленных живых ростков
                церковной жизни. </p>
            

            
            <p>На фоне возвращения
                по крайней мере части церковных зданий, ремонта разрушенных и строительства новых
                храмов, &nbsp;преподавания Основ православной
                культуры в школе, открытия семинарий и богословских институтов и массового
                обращения людей к церкви в начале 1990-х годов (а дальше – больше: закона об
                оскорблении чувств верующих, закона Яровой, будто бы ставящих Русскую
                православную церковь в привилегированное положение) то, о чем рассказывается в наших
                сборниках, производит впечатление абсурда. Часто люди, попадавшие под описанный
                в них удар маховика, терялись в догадках: что это? зависть к тем, кто «вырвался
                вперед на белом коне»? страх перед жизнью в ответственности&nbsp; и свободе? просто обскурантизм, часто
                принимающий уродливые формы?</p>
            

            
            <p>Особенно загадочной ситуация стала после того, как церковь возглавил патриарх Кирилл. В первые годы после этого катехизация перед крещением и возрождение общинной жизни на приходах стали одним из главных требований высшей иерархии ко всем, кто отвечал за церковную жизнь «на местах». Однако несмотря на это и иногда в полном противоречии с рассылавшимися по приходам рекомендациями «придушивание» всех, кто начал воплощать эти распоряжения последовательно и всерьез, продолжалось в самых разных епархиях.</p>
            

            
            <p>Какова же
                причина этой войны? И действительно ли она продолжается лишь последние тридцать
                лет? Уж не наследники ли тех, кто стоял за изъятием церковных ценностей, обновленческим
                расколом, почти полным физическим уничтожением церковной иерархии и десятков
                тысяч священнослужителей и активных мирян (прежде всего членов общин и
                братств), разрушением десятков тысяч храмов и монастырей продолжают свою
                деятельность? Ведь именно они хотели если не полностью уничтожить церковь, то хотя
                бы свести ее лишь к культу, к обряду, сделать полностью контролируемой, «ручной».
                Ведь то, что разрушалось так долго не только в стране, но и в людях, не могло
                остаться без последствий…</p>
            

            
            <p>Мы думаем,
                что каждый из тех, кто будет читать эти материалы, найдет свой ответ на эти
                вопросы. Мы же, не претендуя на исчерпывающую полноту картины, просто предоставляем
                читателям хронологию событий в некоторых епархиях в 2006-2020 годах.</p>
            
        </div>
    );
};

export default Nachalo;
