import React from "react";
import './text.css';
const Nizhnevartovsk = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Дело о непричащении в городе Нижневартовске</h2>
            
            <p>Нижневартовск находится в 30 км от границы с Томской областью на
                берегу Оби в непосредственной близости от Самотлорского нефтяного месторождения
                и является одним из крупнейших центров российской нефтяной промышленности.</p>
            

            
            <p>Население&nbsp;города  –  300000 человек.</p>
            

            
            <p>Нижневартовск – молодой город, большей частью состоящий из людей,
                когда-то приехавших сюда на заработки, и их детей, а порой уже и внуков. Но
                оказывается, у этой земли, на которой, казалось, до появления нефтяных вышек жили
                только коренные (по преимуществу языческие) народности, тоже есть своя
                христианская история. К сожалению, за безбожные годы советской власти история,
                как и во всей стране, забылась, и мало кто из горожан знает, что до появления
                города Нижневартовска уже с XIX века здесь был поселок Вартовский. На
                территории Тобольской епархии в &nbsp;конце XIX – начале XX века были
                свои братства, которые вели обширную миссионерскую и благотворительную
                деятельность. В советское время на этой земле, так же как и по всей России, лилась
                кровь новомучеников, осквернялись церкви.</p>
            

            
            <p>После снятия антицерковных законов в 1989 году в городе официально
                была создана православная община, в 1990 году освящён закладной камень, а в
                1993 году начато строительство храма Рождества Христова. Освятил храм 4 июля
                1999 года архиепископ Тобольский и Тюменский Димитрий. Более 10 лет храм
                оставался единственным в городе.</p>
            

            
            <p>При храме
                быстро образовалась молодежная группа («приходская молодёжка»), председателем
                которой был один из клириков храма, а неформальным лидером стал один из
                преподавателей православной гимназии при храме Александр Королев. Многие члены
                «молодёжки» активно участвовали в жизни прихода. </p>
            

            
            <p>С 2009 года в
                Русской православной церкви началось оживление миссионерской и катехизаторской
                деятельности; это приветствовалось церковным руководством. Среди тех мирян,
                которые стремились свидетельствовать и искали научения, это встретило живой
                отклик; это касалось и нижневартовской «молодежки».&nbsp; </p>
            

            
            <table><tbody><tr></tr><tr><td className="bold">
                2010-2011 годы
            </td></tr><tr><td>    В   Нижневартовске собирается группа людей, желающих пройти научение христианской   вере и жизни – катехизацию. Они начинают встречаться под руководством   катехизатора, которого пригласили из Преображенского православного братства, имеющего большой опыт этого служения.  На   приходе постепенно возникает конфликтная ситуация, связанная с тем, что   катехизация проводится по системе Преображенского братства, которую некоторые   клирики храма считают «еретической», опираясь на недобросовестную информацию,   почерпнутую в интернете (например, на не утвержденное патриархом Алексием II и многократно опровергнутое заключение т. наз. «правдолюбовской»   комиссии<a name="r1" href="#1">[1]</a> и т.п.). По этим же причинам летом 2010 и 2011 года паломников из Преображенского братства в этом храме не допускают до причастия без каких бы то ни было оснований, называют их сектантами, угрожают вызовом милиции.    </td></tr><tr></tr><tr><td className="bold">
                30 мая 2011 года
            </td></tr><tr><td>   Из состава Тобольско-Тюменской епархии (управляющий – митрополит Димитрий (Капалин)) выделена Ханты-Мансийская епархия.&nbsp;   </td></tr><tr></tr><tr><td className="bold">
                12 июня 2011 года
            </td></tr><tr><td>   Правящим архиереем новой епархии, к которой теперь принадлежит   город Нижневартовск, становится новопоставленный епископ Ханты-Мансийский и   Сургутский Павел (Фокин)<a name="r2" href="#2">[2]</a>.   </td></tr><tr></tr><tr><td className="bold">
                В течение 2011 года
            </td></tr><tr><td>   Один из клириков храма, иерей Федор Максимов, которого назначили председателем приходской молодёжки, начинает (возможно, из зависти) настраивать прихожан и молодёжку против Александра Королёва, не только неформального лидера приходской молодёжи, но и одного из главных инициаторов миссионерской и катехизаторской деятельности на приходе, пытается собрать подписи против него в воскресной школе. Отец Фёдор вовлекает в этот конфликт ещё одного из клириков, иерея Димитрия Белозёрова, который пишет А. Королёву смс, где призывает того «к барьеру» и объявляет войну.&nbsp; Настоятель храма, о. Георгий Безнутров, пишет письмо на имя духовника епархии архим. Зосимы. В письме говорится, что он провел с А. Королёвым беседу и ереси не обнаружил. Отец Георгий убирает о. Федора Максимова с председательства молодежки, так как тот все развалил. Отец Димитрий Белозеров<a name="r3" href="#3">[3]</a>, распространяет клевету о братстве и о членах прошедшей катехизацию в Нижневартовске группы среди членов прихода и родителей учащихся православной гимназии   </td></tr><tr></tr><tr><td className="bold">
                октябрь&nbsp; 2011 года
            </td></tr><tr><td>   Зам. директора гимназии по воспитательной работе Елена Никитина, часть учителей, родителей и прихожан передают епископу Павлу письмо с жалобой на действия о. Димитрия. Примерно в то же время другая группа родителей посылает епископу   Павлу письмо с просьбой&nbsp; уволить&nbsp; из гимназии Елену Никитину и Александра Королёва (учителя английского) в связи с тем, что они «оказывают влияние на   детей, будучи членами опасной секты».   </td></tr><tr></tr><tr><td className="bold">
                Конец октября
                2011 года
            </td></tr><tr><td>   На совещании у епископа, где присутствует часть клира епархии, оглашено второе из писем (о секте). Насколько можно судить, о первом письме речь не идёт.   </td></tr><tr></tr><tr><td className="bold">
                31 октября 2011 года
            </td></tr><tr><td>   Директор православной гимназии отец Сергий Бандурин сообщает Елене   Никитиной о совещании у епископа и предлагает ей публично принести покаяние: отказаться от братства и остаться работать в гимназии простым учителем.    </td></tr><tr></tr><tr><td className="bold">
                7 ноября 2011 года
            </td></tr><tr><td>   На расширенном   заседании родительского комитета православной гимназии (пришла часть родителей и некоторые прихожане) Елену Никитину отказываются выслушать (как   опасный элемент, который занимается «духовной педофилией»). На входе завхоз&nbsp; гимназии И.Г. Алексеенко<a name="r4" href="#4">[4]</a> по собственной инициативе   раздаёт всем распечатки сайта «Благодатный огонь»<a name="r5" href="#5">[5]</a> с клеветой на   Преображенское братство и его духовного попечителя отца Георгия Кочеткова.   </td></tr><tr></tr><tr><td className="bold">
                Декабрь 2011 года
            </td></tr><tr><td>   Александр   Королёв уволен из гимназии.   </td></tr><tr></tr><tr><td className="bold">
                &nbsp;Февраль 2012 года
            </td></tr><tr><td>   Елена   Никитина уволена из гимназии. В издании храма - Приходском вестнике начинают   печататься статьи, направленные против братства. Как и в большинстве подобных   случаев, они представляют собой перепечатки фрагментов старых, давно   опровергнутых материалов<a name="r6" href="#6">[6]</a>. Александр Королев пишет новому настоятелю храма о. Максиму Дурневу <a href="/2012-2-hanty/">письмо</a>, где последовательно опровергает выдвинутые обвинения.</td></tr><tr></tr><tr><td className="bold">
                18
                марта 2012 года
                &nbsp;
            </td></tr><tr><td>   В   Нижневартовск приезжает А. Дворкин, которого пригласили для выступления перед   прихожанами и родителями гимназии. <a href="/dvorkin-here/">Александр Королёв и другие члены братства вступают с Дворкиным в публичную полемику</a>, свидетельствуя о том, что он оперирует   лживыми фактами. Вот лишь один пример: после того, как Дворкин заявляет, что о. Георгий Кочетков у себя в братстве запрещает причащаться всем людям, не   прошедшим оглашения, двое членов братства, которые во время оглашения   причащались еженедельно, заявляют об этом во всеуслышание.    </td></tr><tr></tr><tr><td className="bold">
                1 января 2013 года
            </td></tr><tr><td>   Настоятелем храма становится протоиерей Сергий Шевченко. Бывшего настоятеля, протоиерея Максима Дурнева, отправляют в село Березово.    </td></tr><tr></tr><tr><td className="bold">
                18 Января 2013 года
            </td></tr><tr><td>   Отец Сергий отказывает прихожанам, прошедшим катехизацию, в исповеди и причастии.   </td></tr><tr></tr><tr><td className="bold">
                Февраль 2013 года
            </td></tr><tr><td>   Прихожане, прошедшие катехизацию,  встречаются с настоятелем и прихожанами, настроенными против катехизации и братства (разговор длится 5 часов). После разговора отец Сергий соглашается с тем, что группа,   прошедшая катехизацию, – не секта и не еретическое течение, но продолжает считать, что она раскалывает церковь. Он не имеет ничего против того, чтобы эти   прихожане причащались в других храмах, просто им «с ним не повезло». Предлагает   написать письмо епископу; письмо написано и отправлено.    </td></tr><tr></tr><tr><td className="bold">
                15 апреля 2013
            </td></tr><tr><td>   Члены братства Александр Королёв, Артём Мунтян и Мария Галацан встречаются с епископом Павлом, который в ходе разговора благословляет их «не смущать народ», призывает к смирению.   </td></tr><tr></tr><tr><td className="bold">
                14 мая 2013 года
            </td></tr><tr><td>   Священник   Георгий Кочетков, духовный попечитель Преображенского братства, <a href="/2013-05-14-hanty/">поздравляет епископа Павла с Пасхой</a>. В поздравлении, в частности говорится: <i>«Светлый праздник   Христова Воскресения обновляет в нас память о крестном пути любви и свободы в   этом мире, ведь именно этот путь даёт силу преодолевать всякую неправду,   вражду и разделение. Не забудем и слов Спасителя: «Если же согрешит против   тебя брат твой, пойди и обличи его между тобою и им одним» (Мф 18:15), не   прибегая ни к какому лжесвидетельству посредников. Пусть же воскресший   Христос дарует Вам силы и вдохновение для участия в богочеловеческом деле   созидания истинной Церкви, преодоления всякого разделения и отчуждения,   всякой пустоты и богооставленности, а также для воплощения евангельского духа   Православия в жизни».</i>   </td></tr><tr></tr><tr><td className="bold">
                21 Мая 2013 года
            </td></tr><tr><td>   В   Нижневартовск из  Управления делами Московского патриархата приходит <a href="/2013-05-21-hanty/">ответ </a>на письмо группы прихожан с описанием  сложившейся ситуации. В ответе говорится: «В Ханты-Мансийскую епархию отосланы подобающие указания о том,   что отлучение от причастия может иметь место при должном обосновании,   которого в данном случае не усматривается».   Тем   не менее настоятель, давший членам братства разрешительную молитву, не   допускает их до причастия, сославшись на запрет епископа.   </td></tr><tr></tr><tr><td className="bold">
                20 июня 2013 года
            </td></tr><tr><td>   Александр   Королёв и Олеся Коломытцева встречаются с настоятелем, который передаёт им устное сообщение епископа Павла: члены братства должны 1. Прекратить   совместные собрания; 2. Не приглашать катехизаторов из других городов;&nbsp; 3. Не окормляться у священников за   пределами епархии; 4. Попросить прощения у всех, кого ввели в смущение. Тогда   их, может быть, допустят до причастия.   </td></tr><tr></tr><tr><td className="bold">
                27 августа 2013 года
            </td></tr><tr><td>   Настоятель отказывает отлучённым в предоставлении&nbsp;   письменного документа об отлучении от Причастия.   </td></tr><tr></tr><tr><td className="bold">
                29 августа 2013 года
            </td></tr><tr><td>   В Нижневартовск приезжает епископ Павел. В проповеди он обращается лично к Александру Королёву и <a href="http://ugraeparhia.ru/nizhnevartovsk-29-08-2013/">говорит, что не допустит ереси в Нижневартовске</a>, призывает не ходить на встречи к братчикам, не общаться с ними, а тем более не пускать к ним детей.    Александр Королёв после этого звонит свящ. Сергию Шевченко и спрашивает его, в какой ереси обвиняются братчики, так сам о. Сергий говорил, что ереси у них нет. На это о. Сергий отвечает, что сейчас он понял, что ересь есть, но сразу сказать, что за ересь, он не может, ему надо подумать и подготовиться.  </td></tr><tr></tr><tr><td className="bold">
                23 сентября 2013 года
            </td></tr><tr><td>   В Нижневартовск приходит ответ на второе письмо группы прихожан в Управление делами Московского патриархата. В ответе говорится, что епископу   Павлу сообщены необходимые рекомендации; в письме также содержится призыв к хранению единства и послушанию.   </td></tr><tr></tr><tr><td className="bold">
                сентябрь 2013 года
            </td></tr><tr><td>   В   епархию приезжает патриарх Кирилл и разговаривает с епископом Павлом о ситуации с непричащением. Глава администрации города Нижневартовска дарит от города патриарху гобелен с изображением храма, выполненный <a href="/hanty-deti/">Марией Никитиной</a>, дочерью  Елены Никитиной, уволенной из православной гимназии.   </td></tr><tr></tr><tr><td className="bold">
                9 октября 2013 года
            </td></tr><tr><td>   Настоятель храма, о. Сергий Шевченко встречается с Александром Королёвым и Еленой Никитиной и сообщает о решении причащать их по распоряжению патриарха. Как   временная мера принято решение разрешить братчикам исповедоваться и   причащаться только у двух священников. «Куратором» братчиков назначен о.   Владимир Захаров.    </td></tr><tr></tr><tr><td className="bold">
                20 октября 2013 года
            </td></tr><tr><td>
                Братчиков начинают причащать.
            </td></tr><tr></tr><tr><td className="bold">
                Ноябрь 2013 года
            </td></tr><tr><td>   «По   требованию прихода» Елена Никитина, которая в течение 5 лет была   председателем жюри муниципального этапа олимпиады по основам православной   культуры, исключена департаментом образования из состава жюри. Директору школы № 43, где работает Елена, поступают звонки с предложением уволить её с работы.   </td></tr><tr></tr><tr><td className="bold">
                12
                января 2014 года
                &nbsp;
            </td></tr><tr><td>   Настоятель в проповеди говорит, что не согласен с мнением патриарха о решении причащать братчиков, но вынужден подчиниться.    </td></tr><tr></tr></tbody></table>
            

            
            <p>С осени 2013
                года братчикам в Нижневартовске не отказывают в исповеди и причастии. Но
                условие, что они причащаются и исповедуются только у двух священников:
                настоятеля и «куратора», сохраняется. Правда, после нескольких случаев, когда
                оба священника уезжали, а другие священники не решались исповедовать членов
                братства, настоятель сказал, что в таких ситуациях братчики могут исповедоваться
                и причащаться у любого священника. Явных гонений на братство в последние годы в
                епархии нет. </p>
            

            
            <hr class="wp-block-separator"/>
            

            
            <p><a name="1" href="#r1">[1]</a><i> Содержание этой полемики см. в сборнике «Христианский вестник» № 4, т.2</i>.</p>
            

            
            <p><a name="2" href="#r2">[2]</a><i> Решением
                Священного Синода от 25 декабря 2014 г. (<a href="http://www.patriarchia.ru/db/text/3891448.html">журнал № 120</a>) назначен главой <a href="http://www.patriarchia.ru/db/text/3894956.html">Ханты-Мансийской митрополии</a>. 1 февраля 2015 г.
                за Божественной литургией в Храме Христа Спасителя в Москве Святейшим
                Патриархом Кириллом &lt;<a href="http://www.patriarchia.ru/db/text/3974945.html">возведен</a> в сан митрополита.</i></p>
            

            
            <p><a name="3" href="#r3">[3]</a><i> Ныне настоятель храма Архангела Михаила с. Червишево (Тобольская епархия)</i>.</p>
            

            
            <p><a name="4" href="#r4">[4]</a><i> В настоящее время готовится к катехизации</i>.</p>
            

            
            <p><a name="5" href="#r5">[5]</a><i> Этому изданию, известному своей антицерковной
                позицией, в сентябре 2011 года было отказано в грифе Синодального
                информационного отдела. Некоторые публикации этого журнала приводятся в сборнике
                «Христианский вестник» № 4.</i></p>
            

            
            <p><a name="6" href="#r6">[6]</a><i> Многие из этих материалов (так же как и ответы на них) приводятся в сборниках «Христианский вестник» № 3, 4</i>.</p>
            
        </div>
    );
};

export default Nizhnevartovsk;
