import * as React from 'react';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import './NextPreviousComponent.css';

export const paths = [
    {"name": "/", "number": 0, "caption": ""},
    {"name": "/nachalo/", "number": 1, "caption": ""},
    {"name": "/fatheradelgeim/", "number": 2, "caption": ""},
    {"name": "/chronologyadelgeim/", "number": 3, "caption": ""},
    {"name": "/strugglechurch/", "number": 4, "caption": ""},
    {"name": "/zaostrov/", "number": 5, "caption": ""},
    {"name": "/zaos1/", "number": 6, "caption": ""},
    {"name": "/zaos2/", "number": 7, "caption": ""},
    {"name": "/zaos3/", "number": 8, "caption": ""},
    {"name": "/zaos4/", "number": 9, "caption": ""},
    {"name": "/zaos5/", "number": 10, "caption": ""},
    {"name": "/zaos6/", "number": 11, "caption": ""},
    {"name": "/non-licet/", "number": 12, "caption": ""},
    {"name": "/tver/", "number": 13, "caption": ""},
    {"name": "/krasnousolsk/", "number": 14, "caption": ""},
    {"name": "/nizhnevartovsk/", "number": 15, "caption": ""},
    {"name": "/velsk/", "number": 16, "caption": ""},
    {"name": "/fast/", "number": 17, "caption": ""},
    // {"name": "/berdyansk/", "number": 18, "caption": ""},
    {"name": "/konec/", "number": 18, "caption": ""}
    // {"name": "/2015-08-16-velsk/", "number": 20, "caption": ""},
    // {"name": "/2014-08-01-velsk/", "number": 21, "caption": ""},
    // {"name": "/2014-12-06-velsk/", "number": 22, "caption": ""},
    // {"name": "/2015-04-28-velsk/", "number": 23, "caption": ""},
    // {"name": "/2015-06-22-velsk/", "number": 24, "caption": ""},
    // {"name": "/2014-07-08-velsk/", "number": 25, "caption": ""},
    // {"name": "/2014-04-08-velsk2/", "number": 26, "caption": ""},
    // {"name": "/2014-04-08-velsk/", "number": 27, "caption": ""},
    // {"name": "/hanty-deti/", "number": 28, "caption": ""},
    // {"name": "/2012-2-hanty/", "number": 29, "caption": ""},
    // {"name": "/2013-05-14-hanty/", "number": 30, "caption": ""},
    // {"name": "/dvorkin-here/", "number": 31, "caption": ""},
    // {"name": "/2012-11-03-ufa/", "number": 32, "caption": ""},
    // {"name": "/2010-04-1-ufa/", "number": 33, "caption": ""},
    // {"name": "/2012-04-04-1-ufa/", "number": 34, "caption": ""},
    // {"name": "/2012-04-04-ufa/", "number": 35, "caption": ""},
    // {"name": "/2010-12-14-ufa1/", "number": 36, "caption": ""},
    // {"name": "/2010-12-14-ufa/", "number": 37, "caption": ""},
    // {"name": "/2012-09-12/", "number": 38, "caption": ""},
    // {"name": "/6-11-2011/", "number": 39, "caption": ""},
    // {"name": "/2010-05-2/", "number": 40, "caption": ""},
    // {"name": "/2010-05-1/", "number": 41, "caption": ""},
    // {"name": "/2008-03/", "number": 42, "caption": ""},
    // {"name": "/2008-03-04/", "number": 43, "caption": ""},
    // {"name": "/2008-02-23-1/", "number": 44, "caption": ""},
    // {"name": "/2008-02-23-2/", "number": 45, "caption": ""},
    // {"name": "/22-02-2008/", "number": 46, "caption": ""},
    // {"name": "/13-03-2005/", "number": 47, "caption": ""},
    // {"name": "/1-03-2005/", "number": 48, "caption": ""},
    // {"name": "/18-04-2004/", "number": 49, "caption": ""},
    // {"name": "/2012-11-11-ufa/", "number": 50, "caption": ""},
    // {"name": "/2013-5-23-ufa/", "number": 51, "caption": ""},
    // {"name": "/2013-07-26-ufa/", "number": 52, "caption": ""},
    // {"name": "/2013-05-21-hanty/", "number": 53, "caption": ""},
    // {"name": "/2014-08-1-velsk/", "number": 54, "caption": ""}
]


class NextPrevious extends React.Component {
    constructor(props) {
        super(props)
        let filter = props.profiles.filter(path => path.name === window.location.pathname);
        let index
        if (filter.length > 0) {
            index = filter[0].number;
        } else {
            index = 0
        }
        let textNext;
        if (index === paths.length - 1) {
            textNext = this.props.profiles[0].caption
        }
        if (index < paths.length - 1) {
            textNext = this.props.profiles[index + 1].caption
        }
        if (index === 0) {
            textNext = this.props.profiles[1].caption;
        }

        let textPrevious;
        if (index === 0) {
            textPrevious = this.props.profiles[paths.length - 1].caption;
        } else {
            textPrevious = this.props.profiles[index - 1].caption;
        }
        textPrevious = 'Предыдущий: ' + textPrevious
        textNext = 'Следующий: ' + textNext
        this.state = {
            index: index,
            textPrevious: textPrevious,
            textNext: textNext
        }
    }

    togglePrev() {
        let index = this.props.profiles.filter(path => path.name === window.location.pathname)[0].number
        let textNext;
        if (index === paths.length - 1) {
            textNext = this.props.profiles[0].caption
        }
        if (index < paths.length - 1) {
            textNext = this.props.profiles[index + 1].caption
        }
        if (index === 0) {
            index = paths.length;
            textNext = this.props.profiles[1].caption;
        }
        index--
        let textPrevious = this.props.profiles[index].caption
        window.location = this.props.profiles[index].name
        textPrevious = 'Предыдущий: ' + textPrevious
        textNext = 'Следующий: ' + textNext
        this.setState({index: index, textPrevious: textPrevious, textNext: textNext})
    }

    toggleNext() {
        let index = this.props.profiles.filter(path => path.name === window.location.pathname)[0].number
        let textPrevious;
        if (index === 0) {
            textPrevious = this.props.profiles[paths.length - 1].caption
        }
        if (index > 0) {
            textPrevious = this.props.profiles[index].caption;
        }
        index++;
        if (index === paths.length) {
            textPrevious = this.props.profiles[paths.length - 1].caption
            index = 0
        }
        window.location = this.props.profiles[index].name
        let textNext = this.props.profiles[index].caption
        textPrevious = 'Предыдущий: ' + textPrevious
        textNext = 'Следующий: ' + textNext
        this.setState({index: index, textPrevious: textPrevious, textNext: textNext})
    }

    render() {
        const {index, textPrevious, textNext} = this.state
        const profile = this.props.profiles ? this.props.profiles[index] : null
        if (profile) {
            return (
                <div className="profile">
                    <table border="0" width="100%">
                        <tr>
                            <td align="left">
                                <p className="left-prev">
                                    <Link to={profile.name} onClick={() => this.togglePrev()}>
                                        <Button key="prev" variant="outlined">&laquo; Предыдущая</Button></Link>
                                </p>
                            </td>
                            <td align="right">
                                <p className="right-next">
                                    <Link to={profile.name} onClick={() => this.toggleNext()}>
                                        <Button key="next" variant="outlined">Следующая &raquo;</Button></Link>
                                </p>
                            </td>
                        </tr>
                    </table>
                </div>
            )
        } else {
            return <span>error</span>
        }
    }

}

export default function NextPreviousComponent() {
    return (
        <div>
            <NextPrevious profiles={paths}/>
        </div>);
}
