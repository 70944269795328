import React from "react";
import './text.css';
const Velsk9 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2014/08-1 Вельск</h2>
            <div align={'center'}>
                <img src={require('../images/image-12.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>

        </div>
    );
};

export default Velsk9;
