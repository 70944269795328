import React from "react";
import './text.css';
const Zaos5 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Захват Заостровья</h2>

                        <table>
                                <tbody>
                                <tr>
                                        <td className="bold">
                                                26
                                                декабря 2012 года <strong>&nbsp;</strong>
                                        </td></tr><tr>
                                        <td> Русская народная линия (далее - РНЛ) публикует статью «Заостровский раскол»<a
                                            name="r1" href="#1">[1]</a>. Буквально через несколько часов её
                                                перепечатывают сайты Православие.ру, Антимодернизм.ру, ИА Православие на
                                                Северной земле, позже - «Благодатный огонь» (в составе сводного текста);
                                                в целом перепечатки появились на нескольких десятках сайтов, из них
                                                почти 20 брали статью с сайта Сретенского монастыря Православие.ру. Вот
                                                как <a
                                                    href="https://gazetakifa.ru/content/view/4671/31/">прокомментировала</a> впоследствии
                                                начавшийся этой публикацией «информационный блицкриг» газета «Кифа»:
                                                «Массированные публикации о «заостровском расколе» начались 26 декабря,
                                                накануне новогодних праздников, когда подавляющее большинство изданий (в
                                                том числе и их интернет-версий) готовились закрыться на рождественские
                                                каникулы. За две недели - вплоть до епархиального совета, принявшего
                                                решение о переводе о. Иоанна - в изданиях фундаменталистской
                                                направленности, в основном на «Русской народной линии» одна за другой
                                                вышли <i>одиннадцать</i> статей со все более и более пугающей читателя
                                                интонацией: <i>«в Заостровском храме функционирует организация,
                                                        полностью закрытая для посторонних, жестко контролируемая
                                                        священником Георгием Кочетковым, негативно настроенная по
                                                        отношению ко всем остальным чадам Русской Православной Церкви»,
                                                        «деятельность этой общины - это крайняя степень ереси»</i> и
                                                т.п. Практически все публикации, кроме самых последних, были
                                                перепечатаны сайтом Сретенского монастыря «Православие.ру» и от него
                                                разошлись по нескольким десяткам православных сайтов (вряд ли решившихся
                                                бы перепечатать материалы скандальной РНЛ, не имеющей церковного
                                                статуса, напрямую). За это же время защитниками заостровского храма были
                                                опубликованы <i>две</i> статьи и одна небольшая новостная заметка, а
                                                также одно развернутое свидетельство о жизни прихода (на сайте поэта
                                                Ольги Седаковой) и свидетельство крупнейших деятелей культуры на портале
                                                «Православие и мир» (11 января, в день снятия о. Иоанна). Отчасти столь
                                                небольшое количество публикаций объяснялось повсеместным разъездом
                                                редакторов и журналистов на каникулы, отчасти - нежеланием стабильных по
                                                положению церковных изданий связываться с РНЛ, известной своей
                                                агрессивностью и, наконец, просто растерянностью так неожиданно и
                                                бездоказательно обвиненных людей».
                                        </td></tr><tr>
                                </tr>
                                <tr>
                                        <td className="bold">
                                                30 декабря 2012 года
                                                &nbsp;
                                        </td></tr><tr>
                                        <td> Литургию в Заостровском храме служат о. Иоанн и иг. Феодосий (Нестеров) (в
                                                дальнейшем - автор одной из «обличительных» статей на РНЛ) Начиная с
                                                этого момента практически все богослужения проводятся с участием
                                                присланных митрополитом из других храмов епархии
                                                священников.   &nbsp;   </td></tr><tr>
                                </tr>
                                <tr>
                                        <td className="bold"> 4 января 2013 года   &nbsp;   </td></tr><tr>
                                        <td>
                                                На
                                                сайте поэта Ольги Седаковой опубликовано её размышление «О Заостровье, об отце Иоанне
                                                Привалове, о том, что разрушается с разрушением Заостровского
                                                Сретенского
                                                братства», широко
                                                разошедшееся по социальным сетям<a name="r2" href="#2">[2]</a>.
                                        </td></tr><tr>
                                </tr>
                                <tr>
                                        <td className="bold">
                                                7 января 2013 года,
                                                Рождество Христово
                                                понедельник
                                        </td></tr><tr>
                                        <td> Праздничную литургию в Заостровском храме служат о. Иоанн и иг. Феодосий
                                                (Нестеров) со своим клиросом. Сохранились воспоминания прихожан об этой
                                                службе. Вот фрагмент одного из этих воспоминаний: «…служба шла своим
                                                чередом, но ясно ощущалось, что все рушится. Рушится здание любви и
                                                добра, построенное настоятелем храма - о. Иоанном Приваловым. Объяснить
                                                словами это трудно - никто никого и пальцем не тронул, но ощущение
                                                принародной порки о. Иоанна меня не покидало. Может потому, что
                                                причащали в этот раз посланный митрополитом о. Феодосий и назначенный им
                                                же второй священник о. Петр, а действующий настоятель о. Иоанн стоял в
                                                стороне?» &nbsp;   </td></tr><tr>
                                </tr>
                                <tr>
                                        <td className="bold">
                                                10 января 2013 года
                                        </td></tr><tr>
                                        <td> Прихожане заостовского храма пишут письмо Патриарху в отчаянной надежде на
                                                его помощь. Вот небольшая цитата: «Ваше Святейшество! Мы знаем, что Вам
                                                не привыкать к публичной травле, поэтому Вам не нужно объяснять, что
                                                ничто из того, что сейчас публикует в интернете "Русская народная
                                                линия", "Благодатный огонь", "Православие.ру" и другие подобные сайты не
                                                соответствует действительности, кроме трёх
                                                вещей:   &nbsp;&nbsp;   &nbsp; 1. На Заостровском приходе уже 20 лет
                                                        совершается обязательное наставление в православной вере,
                                                        молитве и жизни всех, кто готовится к крещению, к первому
                                                        причащению и венчанию.   &nbsp;&nbsp;   &nbsp;2. В
                                                                Заостровском приходе уже 20 лет частично используется
                                                                русский язык в богослужении. Это было поддержано
                                                                простыми верующими и находило сдержанное понимание у
                                                                прежних Архангельских епископов.   &nbsp;&nbsp;   &nbsp;
                                                                3. Заостровский приход уже 20 лет дружит со
                                                                        священником Георгием Кочетковым и опекаемыми им
                                                                        Преображенским братством и Свято-Филаретовским
                                                                        институтом. Благодаря этой дружбе несколько
                                                                        сотен человек прошли полноценную катехизацию,
                                                                        вошли в Православную церковь и трудятся почти на
                                                                        всех приходах Архангельска и
                                                                        Северодвинска.   &nbsp;&nbsp;   &nbsp; Нельзя
                                                                                сказать, что это двадцатилетие было
                                                                                безмятежным для Заостровского прихода.
                                                                                Время от времени протоиерей Евгений
                                                                                Соколов и другие священники "с ревностью
                                                                                не по разуму" объявляли войну
                                                                                "кочетковцам", но прежние Архангельские
                                                                                архипастыри эту войну мгновенно
                                                                                останавливали. Нужно сказать, что Ваши
                                                                                мудрые, взвешенные слова об о. Георгии
                                                                                Кочеткове и о тех, кого презрительно
                                                                                именуют "кочетковцами" долгие годы были
                                                                                для нас "охранной грамотой". Стоило
                                                                                только предъявить Ваши слова
                                                                                недоброжелателям, как все волнения
                                                                                сходили на нет. Так было годами, до тех
                                                                                пор, пока Архангельскую кафедру не
                                                                                возглавил епископ Даниил (Доровских). И
                                                                                вот тут стало понятно, что Ваши слова
                                                                                перестали действовать. А слова-то
                                                                                замечательные! Вот они: "Вы знаете, что
                                                                                самое страшное - это навешивать ярлыки:
                                                                                кочетковцы, никониане. Не надо этого
                                                                                делать, Боже вас упаси! Отец Георгий
                                                                                Кочетков - это канонический священник
                                                                                Русской Православной Церкви. Он
                                                                                находится в каноническом общении с
                                                                                Патриархатом и епископатом, причащается,
                                                                                исповедуется, служит. В той части его
                                                                                деятельности, которая вызывает критику у
                                                                                Церкви, мы его критикуем. Это нормально.
                                                                                Апостол Павел говорил: «Надлежит быть и
                                                                                разномыслиям между вами» (1 Кор. 11,
                                                                                19). Для чего? - «Чтобы открылись
                                                                                искусные». Но если мы с вами психологию
                                                                                внешнего мира принесем в Церковь, то
                                                                                погубим наших людей. Пока то или иное
                                                                                явление в Церкви не осуждено Собором, не
                                                                                предано критике и не отвержено, ни в
                                                                                коем случае не нужно выносить о нем
                                                                                своего критического радикального
                                                                                отношения. Что касается о. Георгия
                                                                                Кочеткова - он много доброго делает.
                                                                                Ведет большую миссионерскую работу, есть
                                                                                Филаретовская школа. Как же мы можем
                                                                                игнорировать эти добрые начинания?
                                                                                Поэтому я очень бы попросил всех тех
                                                                                людей, которые болеют за чистоту
                                                                                православия, понимать следующее: в нашей
                                                                                огромной стране, в нашей огромной Церкви
                                                                                мы никогда не будем иметь единомыслия по
                                                                                всем вопросам. Мы должны научиться жить
                                                                                в единой Церкви с некоторыми элементами
                                                                                разномыслия…"» Но передано письмо
                                                                                адресату лишь ближе к концу января –
                                                                                оказалось, что написано оно было уже
                                                                                слишком поздно: на следующий день
                                                                                ситуация окончательно
                                                                                «обрушилась».   &nbsp;</td></tr><tr>
                                </tr>
                                <tr>
                                        <td className="bold">
                                                11 января 2013 года,
                                                Пятница
                                                День памяти вифлеемских младенцев,
                                                от Ирода избиенных
                                                &nbsp;
                                        </td></tr><tr>
                                        <td> К 15 часам о. Иоанна Привалова вызывают на трехчасовой епархиальный совет,
                                                на котором на него сыплются обвинения одно жестче другого. Один из
                                                членов совета, постоянный автор РНЛ прот. Евгений Соколов<a name="r3"
                                                                                                            href="#3">[3]</a> и
                                                несколько других особенно жестки, а все происходящее, прежде всего
                                                ответы о. Иоанна, снимаются на камеру, поставленную прямо напротив
                                                него<a name="r4" href="#4">[4]</a>. В Заостровском храме прихожане
                                                молятся на коленях. После заседания о. Иоанну вручен указ о переводе его
                                                «под надзор» в кафедральный собор Архангельска. Настоятелем
                                                Заостровского храма становится назначенный туда меньше месяца назад
                                                священник Петр Кузнецов<a name="r5" href="#5">[5]</a>.   &nbsp;   </td></tr><tr>
                                </tr>
                                </tbody>
                        </table>
                
                
                <p><a name="1" href="#r1">[1]</a><i> Статья основана исключительно на обвинениях тех нескольких доносчиков, лично обиженных на настоятеля Заостровского храма, о которых уже упоминалось выше.</i></p>
                

                
                <p><a name="2" href="#r2">[2]</a><i> «Я еще не знала тогда о той системе
                        катехизации, которая разработана о. Георгием Кочетковым и проводится уже больше
                        20 лет, - пишет О.А. Седакова, вспоминая о своей первой поездке в Заостровье. -
                        Проходил ее и сам о. Иоанн, и все, кого я встретила в Заостровье. Если бы я
                        знала, меня бы все увиденное, вероятно, не так поразило. Позднее мне
                        приходилось встречаться с другими общинами-братствами - в Твери, в Воронеже, в
                        Питере, в Тульской молодой общине, с которой у о. Иоанна была особенно тесная
                        связь. И везде я видела тот же эффект катехизации: по-настоящему изменившиеся
                        люди, которые совершенно ясно понимают, что несовместимо с христианской жизнью.</i></p>
                

                
                <p><i>Отступая
                        от рассказа, позволю себе заметить: этот удивительный труд реального
                        воцерковления (одновременно очеловечивания) человека, который уже принес
                        столько плодов по всей стране - разве это не самое важное для нашей Церкви
                        сейчас? Разве те, кто разработали эту систему, опираясь на опыт
                        раннехристианских времен, не достойны самой глубокой благодарности? Ведь
                        катехизатору в современной России приходится работать даже не на земле старого
                        язычества, а на земле, отравленной страшной идеологической обработкой советских
                        десятилетий. Кто учитывает эту антропологическую катастрофу, которая у нас
                        произошла? Боюсь, всерьез и системно только те, кого презрительно именуют
                        «кочетковцами». У них нужно было бы учиться. Но получают они от своих
                        православных собратьев (которым и самим неплохо было бы пройти хотя бы начало
                        этого вводного курса) только обвинения и злобу. И одни и те же обвинения - в
                        ересях и сектантстве, в том, что они «противопоставляют себя всей остальной
                        церкви». </i></p>
                

                
                <p><i>Первый
                        раз, покидая Заостровье со всеми драгоценными подарками этих вместе проведенных
                        дней, я чувствовала, что покидаю какой-то «иной мир» - но не заповедник святого
                        прошлого, как мы привыкли думать, а скорее, рассадник будущего. Никогда у меня
                        не было такого ясного чувства, что Россия будет жива, что возможно чистое,
                        умное и человечное будущее на наших просторах. Вот это теперь и разрушается с
                        разрушением Заостровского Сретенского братства.</i></p>
                

                
                <p><a name="3" href="#r3">[3]</a><i> Через два года, 9 февраля 2015
                        года, протоиерей Евгений Соколов назначен настоятелем Сретенского храма с.
                        Заостровье.</i></p>
                

                
                <p><a name="4" href="#r4">[4]</a><i> Видимо, это было сделано в надежде смонтировать из фрагментов
                        разговора некое «Разоблачение-2». То, что такого фильма не вышло, говорит о
                        значительном самообладании о. Иоанна и об отсутствии даже эфемерной почвы для
                        обвинений.</i></p>
                

                
                <p><a name="5" href="#r5">[5]</a><i> Он служил в храме лишь до августа 2013 года (то есть 7 месяцев),
                        его сменил свящ. Дионисий Малинов, по факту служивший в Заостровье до декабря
                        (5 месяцев), а потом перешедший в городской храм и только числившийся
                        настоятелем Заостровья, потом фактически должность настоятеля занимал второй
                        священник Димитрий Костюченко, а в феврале 2015 года настоятелем был назначен
                        прот. Евгений Соколов.</i></p>
                
            </div>
    );
};

export default Zaos5;
