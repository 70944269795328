import React from "react";
import './text.css';
const Zaos4 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Канонада всё явственнее</h2>

                <table>
                    <tbody>
                    <tr>
                        <td className="bold">
                            Середина июля 2012 года
                        </td></tr><tr>
                        <td> Происходит встреча о. Иоанна Привалова с одним из первых лиц области. О. Иоанн выражает
                            беспокойство тем, что епархиальное начальство разгромило епархиальную школу<a name="r1"
                                                                                                          href="#1">[1]</a>,
                            говорит, что не хочет отдавать на разгром Заостровье и спрашивает, будет ли вмешиваться в
                            таком случае МВД. Собеседник отвечает, что МВД вмешиваться не будет, но будет ФСБ, т.к.
                            митрополит &nbsp;Даниил распространяет слухи, что в храме секта, и возглавляет ФСБ в
                            Архангельске хороший знакомый Серафимы (Голубки) генерал Степура<a name="r2"
                                                                                               href="#2">[2]</a>.
                        </td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">
                            14 декабря 2012 года
                        </td></tr><tr>
                        <td> На епархиальном собрании вторым священником в Заостровье назначен священник Петр
                            Кузнецов.&nbsp; Он ведет себя тихо; настоятель и прихожане принимают его без конфликта.
                        </td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">
                            15 декабря 2012 года
                        </td></tr><tr>
                        <td> О. Павел Адельгейм пишет в письме о. Иоанну Привалову<a name="r3" href="#3">[3]</a>:
                            «Дорогой отец Иоанн. Получив Ваше письмо, я никак не мог найти правильных слов, чтобы они
                            могли хоть как-то заменить действенную помощь. Наконец, совесть принудила меня решиться и я
                            написал, как сумел. Простите и помолитесь. Знаю, что епископ готовится и на вас тоже
                            обрушить свою неприязнь. Дай Вам Бог выстоять и не ответить ему злом на зло. С любовью во
                            Христе, свящ. Павел»
                        </td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">
                            16 декабря 2012 года
                        </td></tr><tr>
                        <td> Прихожане Заостровья пытаются поговорить с митрополитом, но тот, не останавливаясь, уходит
                            быстрым шагом. Впоследствии митрополит Даниил неоднократно с возмущением рассказывает об
                            этом эпизоде, утверждая, что его «хватали за рясу». Прихожане же <a
                                href="https://g-tatti-67.livejournal.com/986.html">вспоминают</a>: «Одна сестра подошла
                            под благословение и спросила Его Высокопреосвященство, когда вернут отца Павла и почему
                            назначили другого священника без учета просьбы прихожан. Владыка Даниил ничего не ответил.
                            Он просто нас игнорировал. Мы бежали за ним, а он не смог остановиться. Дорожка узкая и
                            скользкая, а протодьякон Алексий беспощадно отталкивал нас в сторону. Кто-то случайно
                            коснулся рукава рясы митрополита Даниила... Странно, что владыка Даниил потом стал ставить
                            нам это в вину, говоря везде, что его светскими методами хотели заставить остановиться…
                            Хорошо, что кто-то догадался включить камеру на телефоне и снять всю картину событий».
                            Завершались эти воспоминания, опубликованные в соцсетях, словами: «И хоть обидными
                            показались Его Высокопреосвященству слова нашего брата, что подобными действиями разрушается
                            Церковь, но это правда. И от этой правды никуда не уйдешь, даже если будешь идти быстрым
                            шагом». <strong>&nbsp;</strong></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">
                            17 декабря 2012 года
                        </td></tr><tr>
                        <td> О. Иоанна Привалова вызывают на заседание малого состава епархиального совета. В заседании
                            участвуют иг. Феодосий (Нестеров), иг. Даниил (Плотников), протодьяк. Алексий Кухта. В ходе
                            долгого, более чем двухчасового разговора о. Иоанн долго рассказывает о том, как сто лет
                            назад и архиерей, и благочиннические собрания Архангельской епархии при подготовке
                            Поместного собора 1917-1918 гг. высказались за богослужение на русском языке, как сам он
                            пришел к мысли о необходимости катехизации, как беспокоит его то, что о епархии и о церкви в
                            интернет-пространстве говорят плохо. Митрополит Даниил долго возмущается тем, как его
                            «хватали за рясу» и говорит две вещи, которые представляют интерес в контексте дальнейших
                            событий: 1) «Если бы я как-то хотел это сделать такое, ну, к примеру, заменить о. Иоанна, я
                            бы это сделал бы спокойно. У меня нет этой задачи, о. Иоанн». 2) «Церковь – воинская
                            часть». <strong>&nbsp;</strong></td></tr><tr>
                    </tr>
                    </tbody>
                </table>
            
            
            <p><a name="1" href="#r1">[1]</a><i> Школа
                существовала с 2000 года, когда брат покойного епископа Тихона игумен Серафим
                (Степанов) получил послушание её организовать и возглавить. Двенадцать лет
                трудов привели к серьезным результатам: более 100 учеников, восемь
                преподавателей, занятия по закону Божию, рисованию, краеведению, театральный
                кружок, два хора, старший из детей 10-15 лет и младший — из совсем маленьких
                детей. Домовый храм при школе, где образовался спаянный приход, возглавляемый
                все тем же игуменом Серафимом, которому помогали еще два священника, отец
                Александр и отец Дмитрий. 12 июля 2012 года в храм был неожиданно для всех назначен
                новый настоятель, отец Артемий Ведерников. Прихожане начали писать письма,
                собирать подписи, завели страничку «ВКонтакте»; учителя написали заявления об
                уходе — в знак протеста против смены настоятеля. Написали и в патриархию. Речь
                о новом настоятеле больше не шла, но игумену Серафиму пришлось написать рапорт
                с просьбой об увольнении (предварительно его заставили «благословить» учителей
                «вернуться и работать без всяких разговоров»). Два других священника
                попросились за штат — нервы не выдержали давления (один из них диабетик на
                инсулине, пару лет назад у него умерла жена, он один воспитывает троих детей).</i></p>
            

            
            <p><a name="2" href="#r2">[2]</a><i> Игуменья Серафима (Голубка), прозванная в епархии «Хиросима», была бессменным казначеем епархии при митр. Данииле и фактически распоряжалась всеми средствами приходов. Степура в настоящее время переведен с поста главы местного ФСБ.</i></p>
            

            
            <p> <a name="3" href="#r3">[3]</a><i> В письме речь шла о преследовании одного из священников Архангельской митрополии со стороны митр. Даниила. </i> </p>
            
           </div>
    );
};

export default Zaos4;
