import React from "react";
import './text.css';
const Zaos6 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>После битвы</h2>

                <table>
                    <tbody>
                    <tr>
                        <td className="bold">11 января 2013 года</td></tr><tr>
                        <td>На сайте «Православие и мир» опубликована подборка под заглавием <a
                            href="http://www.pravmir.ru/zaostrove-cvidetelstva/">«Заостровье: Cвидетельства»</a>.
                            Перепечатана сайтом Я.ру «Те, кто был и видел своими глазами» и чьи свидетельства
                            опубликованы – четыре человека: Ольга Седакова, Анна Шмаина-Великанова<i>, журналист
                                Александр Архангельский<a href="#1" name="r1">[1]</a> и Сергей Юрский</i><a href="#2"
                                                                                                            name="r2">[2]</a>
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">12 и 13 января 2013 года</td></tr><tr>
                        <td>В Архангельск приезжает протодьякон Андрей Кураев. В своих выступлениях он распространяет
                            недостоверные сведения о происходящем в Заостровье, а в блоге публикует провокационные
                            посты, вызвавшие большую дискуссию <br/><strong><i>См.: </i></strong><a
                                href="http://gazetakifa.ru/content/view/4658/196/">«Нагайками их…»</a></td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">14 января 2013 года</td></tr><tr>
                        <td>Состояние здоровья о. Иоанна Привалова резко ухудшается, он госпитализирован</td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">14 января 2013 года</td></tr><tr>
                        <td><a href="http://www.pravmir.ru/protoierej-aleksij-uminskij-o-zaostrovskom-konflikte/">На
                            портале «Православие и мир» опубликована статья «Протоиерей Алексий Уминский о Заостровском
                            конфликте»</a> Главный смысл статьи: причины событий в Заостровье связаны со свящ. Георгием
                            Кочетковым. Необходимо устроить дискуссию, например, такую, как та, которую проводил о.
                            Владимир Воробьев двадцать лет назад (судя по контексту, имеется в виду конференция
                            «Единство церкви»<a href="#3" name="r3">[3]</a>, хотя она и не названа прямо).
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">15 января 2013 года</td></tr><tr>
                        <td>На сайте Преображенского братства опубликовано заявление пресс-службы братства «Сретение»
                            «<a href="http://www.psmb.ru/aktualnye-temy/problemy-zhizni-cerkvi/statja/o-vazhnosti-vnutricerkovnogo-dialoga/">О
                                важности внутрицерковного диалога</a>», в котором, в частности, говорится: «В свете
                            последних публикаций ряда нецерковных изданий (таких как РНЛ), направленных против
                            Преображенского братства и его духовного попечителя, свящ. Георгия Кочеткова, нам кажется
                            исключительно важной статья прот. Алексия Уминского, предлагающего решать проблему
                            существования в церкви «разномыслий» путем конструктивного диалога. ...мы всегда были
                            сторонниками ведения диалога по актуальным богословским проблемам, по которым в церкви
                            существует широкий спектр мнений... Важно, однако, помнить, что для пользы церковной такое
                            обсуждение требует особенного климата, который предполагает свободу высказываний при
                            воздержании от оценочных суждений, когда предметом обсуждения оказываются лица, а не сами
                            проблемы».
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">16 января 2013 года</td></tr><tr>
                        <td>На консервативном сайте "Агентство политических новостей" опубликована статья "<a
                            href="http://www.apn.ru/publications/article28195.htm">Иоанн Привалов: миссия под ударом</a>",
                            заканчивающаяся словами: "На о. Иоанна наехали контрмиссионеры. Есть опасность, что вскоре
                            атакам подвергнутся и другие миссионерские приходы в других епархиях. А у священников,
                            готовых заниматься положительной миссией, опустятся руки. Поэтому события в Заостровье можно
                            оценить как неприкрытый наезд не только на о. Иоанна Привалова, но и на патриарха
                            Московского и всея Руси Кирилла.
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td>Середина января</td></tr><tr>
                        <td>О. Иоанна Привалова предупреждает его знакомый, близкий к епархиальному управлению, что вся
                            кампания направлена против Преображенского братства в целом и приурочена к предстоящему
                            Архиерейскому собору
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">17 января 2013 года</td></tr><tr>
                        <td>На сайте Архангельской епархии появляется интервью митрополита Даниила: «Я всех вас призываю
                            к миру». Кроме всего прочего в статье перечисляется набор тех, кто выступает «с не менее
                            жесткой критикой учения и деятельности отца Георгия Кочеткова» Среди них упоминаются, в
                            частности, митрополит Псковский и Великолукский Евсевий, митрополит Уфимский и
                            Стерлитамакский Никон, архимандрит<a href="#4" name="r4">[4]</a> Тихон (Шевкунов),
                            протоиерей Димитрий Смирнов, иерей Олег Давыденков, протодиакон Андрей Кураев, А.Л. Дворкин,
                            В.И. Петрушко<a href="#5" name="r5">[5]</a></td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">31 января 2013 года</td></tr><tr>
                        <td>На сайте газеты «Кифа» опубликован <a href="http://gazetakifa.ru/content/view/4675/196/">анализ
                            реакции блогосферы</a> на «Заостровское дело». Один из наиболее интересных выводов: Из 80
                            авторских (остальные представляли собой перепечатки) постов, посвященных событиям в
                            Заостровье (все они со ссылками перечислены <a
                                href="http://gazetakifa.ru/content/view/4648/196/">здесь</a>) подавляющее большинство с
                            сочувствием, с любовью, с желанием защитить разрушенный приход говорит о бывшем настоятеле
                            заостровского храма и его прихожанах: <a href="http://kir-a-m.livejournal.com/765243.html">«Как
                                ни назови солнце, хоть зеленым, хоть коричневым, оно всегда будет золотое»</a>, <a
                                href="http://kir-a-m.livejournal.com/766162.html">«Еретик - не тот, кто горит на костре,
                                а тот, кто зажигает костер»</a>,  «Зачем мы часто
                                относимся к Православию так, словно оно - тяжелая дубина?..» Происходящее разрушение
                            прихода оценивается как <a href="http://adelgeim.livejournal.com/117599.html">«Жертвоприношение
                                Маммонну»</a>, «Погром
                                Заостровья». Только два поста отражают прямо противоположное отношение - осуждение,
                            насмешку, обвинение. Оба они принадлежат протодьякону Андрею Кураеву<a href="#6"
                                                                                                   name="r6">[6]</a>.
                            Еще два поста содержат нейтральное заявление о желании разобраться. В статье приводится
                            также статистический анализ дискуссии в блоге протодьяк. А. Кураева<a href="#7"
                                                                                                  name="r7">[7]</a>.
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">2-5 февраля 2013 года</td></tr><tr>
                        <td>Проходит Архиерейский собор Русской православной церкви. Несмотря на то, что митр.
                            Архангельский Даниил (по его собственному свидетельству на радиостанции «Радонеж») всем
                            жалуется на «кочетковцев»<a href="#8" name="r8">[8]</a>, никаких решений по Преображенскому
                            братству в целом и по о. Георгию Кочеткову собором не принято.
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">7 февраля 2013 года</td></tr><tr>
                        <td>На сайте «Богослов» опубликована статья иером. Иова (Гумерова) , насельника Сретенского
                            монастыря ««Нет ничего среднего между истиной и ложью» (cв. Марк Эфесский), или к какой
                            Церкви принадлежит община священника Георгия Кочеткова?» Достаточно объемный пересказ уже
                            звучавших прежде обвинений в адрес о. Георгия скомпонован с резкими заявлениями в начале и
                            конце статьи.
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">8 февраля 2013 года</td></tr><tr>
                        <td>На сайте «Богослов» опубликован ответ профессора СФИ, члена Межсоборного присутствия Д.М.
                            Гзгзяна иером. Иову (Гумерову) <a href="https://bogoslov.ru/article/3128514">«Неоправданные
                                ожидания?»</a> В статье высказано сожаление, что несмотря на настойчивую просьбу
                            священноначалия хранить церковное единство, оппоненты о. Георгия по-прежнему прибегают к
                            недостойным методам и провоцируют жесткое противостояние. На ряде примеров автор статьи
                            показывает, как происходит подтасовка при аргументации у о. Иова (Гумерова), и заключает
                            выводом: церковная полемика в этом контексте невозможна. В дальнейшем стороны обмениваются
                            на портале «Богослов» еще несколькими публикациями.
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">12 марта 2013 года</td></tr><tr>
                        <td>На сайте газеты «Кифа» опубликована статья Александры Колымагиной <a
                            href="http://gazetakifa.ru/content/view/4696/">«Научимся ли мы разговаривать? О так
                            называемой дискуссии в связи с Преображенским братством и профессором священником Георгием
                            Кочетковым»</a>, где приводится аналитика дискуссии на сайте «Богослов» (включающая
                            статистическую обработку комментариев к статьям)
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    <tr>
                        <td className="bold">Июль 2013 года</td></tr><tr>
                        <td>На личном сайте священника Георгия Кочеткова опубликованы одна за другой четыре части
                            большого интервью по итогам полемики на сайте Богослов.ру – «Мы просто делаем то, что должны
                            делать»: <a
                                href="https://www.ogkochetkov.ru/publikacii/detail/my-prosto-delaem-to-chto-dolzhny-delat-chast-1/#.XpWqfs1S8cB">часть
                                1</a>, <a
                                href="https://www.ogkochetkov.ru/publikacii/detail/my-prosto-delaem-to-chto-dolzhny-delat-chast-2/#.XpWqo81S8cB">часть
                                2</a>, <a
                                href="https://www.ogkochetkov.ru/publikacii/detail/my-prosto-delaem-to-chto-dolzhny-delat-chast-3/#.XpWr8M1S98J">часть
                                3</a>, <a
                                href="https://www.ogkochetkov.ru/publikacii/detail/my-prosto-delaem-to-chto-dolzhny-delat-chast-4/#.XpWs0s1S98J">часть
                                4</a>.
                        </td></tr><tr>
                        <td></td></tr><tr>
                    </tr>
                    </tbody>
                </table>
            
            
            <p>Послесловие</p>
            

            
            <p>В
                защиту чести и достоинства священника Иоанна Привалова выступили более 1000 человек, в том числе Наталия Солженицына,
                Елена Чуковская, Сергей Юрский, Елена Камбурова, Ольга Седакова, Александр
                Архангельский и многие другие. Но это не изменило судьбу опального священника.
                После снятия с настоятельства он перенес семь госпитализаций, две операции (на
                сосудах и на сердце), был признан инвалидом и в середине сентября 2013 года
                послал митрополиту Даниилу прошение об увольнении за штат по состоянию
                здоровья. Прошение и поныне осталось без ответа.</p>
            

            
            <p>Приход
                Заостровского храма, лишившийся всего прежнего клира (и настоятеля, и второго
                священника) и практически всех прихожан, в течение последовавших за этим
                событием нескольких лет понес еще несколько потерь. Летом 2013 года с одного из
                куполов храма ураганом был снесен деревянный крест (и долгие месяцы, не
                поднятый на купол, лежал во дворе). Была отменена ставка сторожа, из-за чего в
                сентябре из храма украли иконы. И самое страшное – в октябре 2013 года был до
                основания снесен дом, принадлежавший когда-то протоиерею Феодору Михайловичу
                Тамицкому, который был настоятелем Заостровского прихода с 1914 по 1930-й год.
                В 1930-м году отец Феодор был арестован и отправлен в концлагерь под
                Сыктывкаром. В 1933-м году его расстреляли<a name="r9" href="#9">[9]</a>. До
                того как прихожан и настоятеля заостровского храма без всяких оснований объявили
                «раскольниками», прихожане успели договориться с областной и городской
                администрацией о создании исторической зоны, куда входил и дом отца Феодора.
                Документы были в стадии оформления, когда разразилось «Заостровское дело».
                После разгрома прихода постоянных прихожан практически не осталось, сохранением
                и самого храма, и его исторической памяти заниматься стало некому. </p>
            

            
            <p>В
                храме сменилось несколько настоятелей. В августе 2013 года свящ. Петра
                Кузнецова сменил свящ. Дионисий Малинов, по факту служивший в Заостровье до
                декабря, а потом перешедший в городской храм и только числившийся настоятелем
                Заостровья, потом фактически должность настоятеля занимал второй священник Димитрий
                Костюченко, а в феврале 2015 года настоятелем был назначен прот. Евгений
                Соколов. По свидетельству прихожан настоятельство о. Евгения также остается
                номинальным, в храме он почти не появляется. В богослужениях обычно участвует
                около 20 человек. </p>
            

            
            <p>Бывшие
                прихожане заостровского храма стали прихожанами различных храмов Архангельска и
                Северодвинска и при этом продолжают вместе свое церковное служение. В полутора десятках
                городов Архангельской области благодаря их усилиям прошла выставка «Неперемолотые
                на Северной Земле», посвященная подвигу новомучеников и исповедников
                Российских. Проводятся и другие просветительские встречи, посвященные прежде
                всего церковно-исторической тематике. Братства, родившиеся из заостровской
                катехизации, сохранились, хотя жизнь их сложно назвать лёгкой.</p>
            

            
            <hr class="wp-block-separator"/>
            

            
            <p><a name="1" href="#r1">[1]</a><i> Формат позволяет нам привести
                здесь это свидетельство полностью: «Когда я узнал об отце Иоанне
                Привалове? Задолго до того, как мой старший друг и коллега Жорж Нива съездил к
                нему в Архангельск и с сентиментальной трезвостью рассказал о том, какие бывают
                православные священники и как многому можно у них научиться.</i></p>
            

            
            <p><i>Еще
                в середине 90-х, на какой-то конференции ныне покойный академик Сергей
                Сергеевич Аверинцев обмолвился о разговоре с молодым священником, тонко
                понимающем богослужение; речь шла о том, что трудно придется этому священнику -
                только потом я сообразил, что речь шла об отце Иоанне Привалове; тогда был
                первый приступ доносительной ярости на всех, имеющих дерзость служить
                по-русски, даже сборник какой-то вышел против ереси русификации.</i></p>
            

            
            <p><i>Потом
                на каком-то полулюбительском диске мне попалось интервью, которое берет отец
                Иоанн у Сергея Сергеевича; вроде бы ну интервью, и интервью: мало ли мы их
                видели? А это запомнилось. Не только ответами, но и тоном вопросов - спокойным,
                смиренным, без выпячивания себя. Собеседник впитывал размышления великого
                ученого, как губка; он не себя подавал в кадр, а осознанно НЕ МЕШАЛ Аверинцеву
                проговорить важные вещи.</i></p>
            

            
            <p><i>И
                потом, уже повстречавшись с отцом Иоанном в Москве, я с радостью понял, что
                этот «образ под камеру» ничуть, ни единым зазором, не отличается от образа -
                реального. Таков этот человек и священник, спокойный, смиренный, умный, твердый
                в вере и мягкий в чувствах. Побольше бы таких - может, и мы были бы получше».</i></p>
            

            
            <p><a name="2" href="#r2">[2]</a><i> Из его развернутого
                свидетельства-размышления мы приводим здесь лишь небольшую часть:«В
                XIX веке в России мыслящая часть общества всё больше расходилась с Церковью,
                ставшей окончательно официальной и государственной. На рубеже с веком ХХ обе
                стороны попытались войти в диалог. Было нечто вроде собора мнений, началось
                многотомное издание докладов, статей, опытов с двух сторон. Примирения не
                находилось, и Победоносцев закрыл прения, остановил издание - слишком опасно.
                Чем это кончилось через полтора десятка лет, нам всем известно.</i></p>
            

            
            <p><i>Храм
                Сретенья в Заостровье привлёк&nbsp; многочисленную паству из близлежащих&nbsp;
                больших городов - Архангельска и Северодвинска. Это довольно особенный&nbsp;
                слой людей. «Ты сам, Владыко,... сподоби мя&nbsp; истинным Твоим светом и
                просвещённым сердцем творити волю Твою...» - слова из часто повторяемой молитвы
                святого Макария Великого. Эти люди шли с верою к свету и хотели просвещать своё
                сердце. И сам о. Иоанн наглядно становился всё более просвещённым человеком с
                непрерывно расширяющимся кругозором. Вглубь - через писания святых отцов и
                богословские труды, вширь - через общение с культурой, с биением сегодняшней
                мысли. Два имени витали постоянно, во многом определяя темы общения - Александр
                Солженицын и Сергей Аверинцев. Оба имени столь значимые для современной России.
                Поэтому сюда, в сельский Храм приехал профессор Жорж Нива из Франции, автор
                одной из интереснейших книг о Солженицыне, чтобы вместе помолиться и прочесть
                лекцию о великом сыне России. Поэтому здесь побывала и осталась в постоянном
                общении с о. Иоанном Ольга Седакова - выдающийся филолог, философ и поэт нашей
                страны.&nbsp; Храм стал не чужим для замечательной писательницы Елены Цезаревны
                Чуковской. Здесь выступала яркая индивидуальность нашей сцены - певица Елена
                Камбурова.</i></p>
            

            
            <p><i>Не
                раз на моих выступлениях я&nbsp; видел в первых рядах священнослужителей, и это
                меня крайне радовало. Была памятная для меня встреча и беседа с епископом
                Архангельским и Холмогорским Владыкой Тихоном, ныне покойным. Мы встречались в
                его резиденции. В последний мой приезд (сентябрь 2012) среди зрителей в рясе и
                с крестом на груди был один о. Иоанн. Я узнал,&nbsp; что в руководстве епархии
                к о. Иоанну появились претензии, и что они нарастают. Друзья сообщили мне, что
                критике подвергается само служение о. Иоанна, его самоотверженная и результативная
                деятельность, как священника, что даже возможен церковный суд над ним.</i></p>
            

            
            <p><i>Что
                происходит? Я и всё сообщество моих друзей крайне встревожены. Если это
                расхождение взглядов по частностям, то возможно обсуждение. Отсюда моя&nbsp;
                надежда. Если же это окрик, как наказание&nbsp; за инициативу, много лет
                приносящую благотворные плоды, то это беда». </i></p>
            

            
            <p><a name="3" href="#r3">[3]</a><i> О ней см. сборник «Христианский вестник» №2</i></p>
            

            
            <p><a name="4" href="#r4">[4]</a><i> Ныне митрополит Псковский</i></p>
            

            
            <p><a name="5" href="#r5">[5]</a><i>Большинство этих людей являются фигурантами сборников
                «Христианский вестник» №3 и №4</i></p>
            

            
            <p><a name="6" href="#r6">[6]</a><i> Первый из этих постов, появившийся 12 января, представлял собой набор не
                соответствующих действительности сюжетов: «Отцу Иоанну Привалову,
                бывшему настоятелю, предложили отслужить "прощальную" службу -
                проститься приходом и храмом. Он отказался. Конечно, чужая душа - потемки. Но
                мне все же трудно понять такой отказ. За десятилетия службы храм, каждая его
                иконка и трещинка должны стать родными для священника. Отказаться от
                прощального прикосновения странно... Впрочем, всю мебель из приходских домов
                кочетковцы вывезли до приезда нового настоятеля. Неделю назад кочетковцы
                устроили детское представление в местном доме культуры. Сюжет - война добрых и
                злых букв. По ходу дела общинные дети бросали в костер, изображенный на сцене,
                "плохие буквы" - то есть те церковно-славянские буквы, которых нет в
                современном русском алфавите» - писал протодьяк. А. Кураев. На самом же деле
                о. Иоанн попрощался с бабушками, а потом, совсем больной, ушел домой; мебель
                передали по описи, включавшей чуть не 400 пунктов; на детском спектакле глупый
                волк, не желавший учиться, бросил в печку азбуку и т.п. – см. </i><a href="http://gazetakifa.ru/content/view/4648/196/"><i>http://gazetakifa.ru/content/view/4648/196/</i></a></p>
            

            
            <p><i>Второй пост,
                озаглавленный «Племенная спайка кочетковцев» выражал возмущение автора тем
                фактом, что Добролюбовская библиотека в Архангельске после его выступления
                приняла решение больше не сотрудничать с Русской православной церковью. Решение
                было связано с тем, что, по словам распространенного библиотекой заявления,
                протодьяк. А. Кураевым «в некорректной форме обсуждалась ситуация вокруг
                Заостровского прихода, что нашло отражение в последовавших публикациях в сети
                Интернет и бросает тень на доброе имя библиотеки».</i></p>
            

            
            <p><a name="7" href="#r7">[7]</a><i> Из наиболее интересных выводов: в
                дискуссии с почти полутора тысячами комментариев реально участвовало меньше 200
                человек, то есть меньше чем, скажем, прихожан разоренного заостровского
                прихода. Причем примерно трети были глубоко безразличны споры между теми, кто
                является членами Преображенского братства или готов их защищать и теми, кто
                хотел бы их уничтожить, а остальные две трети делились на «уничтожителей» и
                «защитников» вовсе не поровну: защитников (если учесть тех, кому травля
                «кочетковцев» оказалась глубоко противна) было примерно в полтора раза больше.
                Причем, что любопытно, среди них было сравнительно много священнослужителей
                Русской православной церкви (если пройти по ссылке, можно посмотреть подробные
                цифры).</i></p>
            

            
            <p><i>Из
                наиболее интересных цитат:</i></p>
            

            
            <p><i>«<strong>prot_vjacheslav
                14 января 2013, 02:30:07</strong> </i></p>
            

            
            <p><i>Прочёл
                я все ветки. Хочу услышать аргументы против Иоанна Привалова. Во всём
                обсуждении одни эмоции: от начала до конца никакой аргументации. С одной
                стороны я вижу, что священник не запрещён, с другой - вроде как наказан. Вопрос
                за что? Только не надо про абстрактный "сектантский дух" или
                априорную "прелесть", а также аргументы типа: "говорил мне один
                кочетковец..."</i></p>
            

            
            <p><i>У
                них, насколько я понял есть методички, есть аудиозаписи всех лекций (лично мои
                лекции и проповеди записывают все 100%. А такой "культовый" батюшка
                как о. Иоанн Привалов наверняка имеет в записи свидетельства&nbsp; своей веры и
                методов "вербовки в православие".&nbsp; Ну не в 8-м веке ведь живём!
                Отец Андрей на лекции говорил про крысу под полом, которая воняет. Нельзя ли
                хотя-бы пункта два? Что не так? Что воняет?</i></p>
            

            
            <p><i>Поверьте,
                мне в Одессе ни холодно, ни жарко от судьбы заостровского храма. Но нельзя же
                всё сводить к пропагандистским лозунгам!</i></p>
            

            
            <p><i>От
                такого бесспорно умного и умеющего обосновать свою позицию полемиста как о.
                Андрей я надеюсь услышать что-то конкретное. Одна его расправа над рериховцами
                чего стоит! Но там всё по полочкам, да на тарелочке, за подписью
                подозреваемых.&nbsp; А тут... со стороны выглядит как заказ
                "замочить" (возможно и виновного).</i></p>
            

            
            <p><i>И
                ещё: не давайте ссылок на огромные статьи, резюмируйте сами в 2-3 аргумента».</i></p>
            

            
            <p><i>(Ответа на этот вопрос не
                последовало).</i></p>
            

            
            <p><a name="8" href="#r8">[8]</a><i> Вот фрагмент из анонса этой передачи на сайте «Радонежа»: «Митрополит Архангельский и Холмогорский Даниил выступит в понедельник 4 февраля в 21.00 в прямом эфире Радио "Радонеж". Предполагается, что среди множества вопросов, связанных с современной приходской жизнью, владыка коснется и ситуации, связанной со скандальным поведением группы кочетковцев, нашедших приют в приходе священника Иоанна Привалова в Заостровье архангельской епархии. Также владыка расскажет о только что прошедшем Архиерейском соборе РПЦ».&nbsp; </i></p>
            

            
            <p><a name="9" href="#r9">[9]</a><i> В своем обращении бывшие
                прихожане Заостровья с болью писали об этом: «С начала 1990-х годов всем мiром
                нам удалось вернуть Заостровскому приходу два дореволюционных здания:
                церковно-приходское сельское училище и дом отца Феодора Тамицкого. За 12 лет
                пользования этим домом мы ни разу не решились сделать в нём реконструкцию,
                помня, что стены этого дома хранят тепло рук отца Феодора. Небольшие переделки
                касались крыши и поздней пристройки. Даже сейчас, когда дом разрушен, видно,
                что в нём не было ни одного гнилого бревна. Нам, конечно, могут объяснить, что
                на месте старого здания появится новый прекрасный дом для священнослужителей
                или воскресная школа, но тогда как согласовать уничтожение добротного дома
                настоящего новомученика Российского с громкими акциями по сбору средств для
                открытия музея в память этих же новомучеников?» </i><a href="http://sretenie-portal.livejournal.com/32509.html"><i>http://sretenie-portal.livejournal.com/32509.html</i></a></p>
            
            
            </div>
    );
};

export default Zaos6;
