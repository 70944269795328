import React from "react";
import './text.css';
const Letter8 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2008/02/23-2</h2>
            
            <p>МОСКОВСКАЯ
                ПАТРИАРХИЯ
                    ПСКОВСКАЯ ЕПАРХИЯ
                    ХРАМ СВЯТЫХ ЖЕН-МИРОНОСИЦ</p>
            

            
            <p>180016
                г.Псков
                    № 1723 февраля 2008г.
                    ул.Коммунальная д.25а</p>
            

            
            <p>Его
                Высокопреосвященству
                    Высокопреосвященному
                    Евсевию, архиепископу&nbsp;
                    Псковскому и Великолукскому.</p>
            

            
            <p>Ваше
                Высокопреосвященство!</p>
            

            
            <p>Указ
                №7 от 22.02.08 г. продолжает необоснованные репрессии, которым Вы подвергаете
                меня уже 15 лет. Большевики без вины казнили моих дедов и родителей. Без вины
                меня осудили и изувечили в тюрьме. "Дополняйте же меру отцов ваших"
                (Мф.23, 32). Всех нас теперь оправдали, одних посмертно, меня реабилитировали
                через 40 лет.</p>
            

            
            <p>Ваш
                Указ с годами осудят, как образец беззакония.Он содержит такую же клевету, как
                и мой судебный приговор. Вы без исследования объявили меня виновником
                "нетерпимой обстановки", хотя я защищён множеством письменных
                свидетельств и живых свидетелей.</p>
            

            
            <p>1.Вы
                не смогли указать мой конкретный проступок. В храме Святых Жен Мироносиц не
                было скандалов.Полгода моего ежедневного служения проходят в мирной обстановке.
                СвященникВладимир Будилин прекратил евхаристическое общение с настоятелем, не
                причащался со мной, искажал чин Литургии и других Таинств, опаздывал на
                богослужения, полгода не появляется в храме без уважительной причины. Это
                изложено в докладных,которые Вы игнорировали несколько лет. Вызывает недоумение
                отношение епархиального руководства к нарушениям священнического долга и
                христианской этики отцом В. Будилиным. Грех о. Владимира Указ № 7 назвал
                "нетерпимой обстановкой в храме". За его грех Вы осудили и сняли настоятеля.</p>
            

            
            <p>Отказ
                явиться в епархиальный суд был канонически обоснован:хотели меня осудить без
                конкретного обвинения. "Положение о епархиальном суде" не допускает
                суда без события преступления и без обвинения, поскольку епархиальный суд
                рассматривает обвинения, влекущие лишение сана и отлучение от Церкви (5,1).
                Вместо беззаконного суда Вы могли пригласить меня и о. Владимира для беседы, но
                не захотели.
                    "Открытая неприязнь к предшествующим клирикам" -ложь. За 20 лет моего
                    настоятельства в Мироносицком храме служили:
                    Архим. Елевферий Попов, ныне настоятель Успенского храма.
                    Свящ. Владимир Георгиев ныне настоятель двух городских храмов.
                    Прот. Михаил Мельников, ныне настоятель Воскресенского храма
                    Прот. Евгений Найдин, ныне настоятель Матвеевского храма.</p>
            

            
            <p>Мироносицкий
                храм стал кузницей настоятелей. С каждым я сохранил добрые отношения.</p>
            

            
            <p>Вы
                отняли храм, который я построил в Богданово в 1997 г.</p>
            

            
            <p>Вы
                уволили меня без вины из Матвеевского храма в 2001 г.</p>
            

            
            <p>Не
                установив виновность, Вы отняли в 2008 г. третий, поднятый из руин храм. Не дав
                ничего, Вы отняли у меня всё.</p>
            

            
            <p>Личную
                неприязнь Вы мне высказали на первой аудиенции в марте 1993 г., не скрывая
                последствий, которые преследуют меня 15 лет. Лишение настоятельства – последний
                удар, за ним последует уничтожение? За что?</p>
            

            
            <p>Разве
                можно осудить невиновного? Величайший пример - Христос Спаситель. Он спрашивал:
                "кто из вас обличит Меня в неправде?" (Ин.8, 46). И всё же,
                "искали архиереи и книжники, как бы взять его хитростью и убить"
                (Мк.14,1).</p>
            

            
            <p>Вот
                и я восхожу на свою маленькую Голгофу и повторяю о Вашем Высокопреосвященстве
                ту великую молитву, которую Господь произнёс о своих распинателях: "Отче,
                отпусти им, ибо не ведают что творят" (Лк.23, 34).</p>
            

            
            <p>Несмотря
                на все понесённые от Вас обиды, с любовью во Христе,</p>
            

            
            <p>свящ.
                Павел Адельгейм.</p>
            
        </div>
    );
};

export default Letter8;
