import React from "react";
import './text.css';
const Hanty1 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2012/2 - Нижневартовск</h2>
            
            <p class="has-text-align-right">Его Преподобию
                иерею Максиму Дурневу</p>
            

            
            <p class="has-text-align-right">благочинному
                нижневартовского благочиния,</p>
            

            
            <p class="has-text-align-right">настоятелю храма
                Рождества Христова </p>
            

            
            <p class="has-text-align-right">прихожанина храма
                в честь Рождества св. Иоанна Предтечи</p>
            

            
            <p class="has-text-align-right">города
                Нижневартовска </p>
            

            
            <p class="has-text-align-right">Королева А. А.</p>
            

            
            <p class="has-text-align-center">Прошение</p>
            

            
            <p>Ваше преподобие,
                прошу Вас способствовать разрешению ситуации, сложившейся в связи со статьями в
                приходском листке храма Рождества Христова г. Нижневартовска.</p>
            

            
            <p>&nbsp;С конца прошлого года на приходе храма
                Рождества Христова стал издаваться по благословению епископа Павла приходской
                листок, в котором имеются статьи, посвященные священнику Московской епархии о. Георгию
                Кочеткову, институту, где данный священник является ректором и православному
                братству, где о. Георгий осуществляет духовное попечительство. В данных статьях
                содержится ошибочная информация по отношению к каноническому православному
                священнику, православному институту и Преображенскому содружеству малых
                православных братств.</p>
            

            
            <p>Прежде всего,
                хотелось бы подчеркнуть, что о. Георгий Кочетков является каноническим
                православным священником, который осуществляет служение в Новодевичьем
                монастыре, сослужа Ювеналию митрополиту Крутицкому и Коломенскому.</p>
            

            
            <p>Свято-Филаретовский
                православно-христианский институт, основанный еще в 1988 году, имеет лицензию
                Отдела религиозного образования и катехизации РПЦ МП за номером 09-5635-5. Также
                данный институт имеет государственную аккредитацию и лицензию федеральной
                службы по надзору в сфере образования и науки на право ведения образовательной
                деятельности. </p>
            

            
            <p>Входящий в
                братство Культурно-просветительский центр «Преображение» официально состоит в
                Совете православных общественных объединений при Синодальном отделе по
                взаимоотношениям Церкви и общества, возглавляемом протоиереем Всеволодом
                Чаплиным. Зав. кафедрой догматики СФИ проф. Д.М. Гзгзян является членом Межсоборного
                присутствия РПЦ МП. Труды о. Георгия Кочеткова использовались при составлении
                учебника «Миссиология», изданного Московским патриархатом в 2010 году. Ксерокопии
                документов, подтверждающих вышеизложенную информацию, можем Вам предъявить.
                Также вы можете обратиться в соответствующие отделы патриархии для
                подтверждения и уточнения всей данной информации.</p>
            

            
            <p>Далее мы бы хотели
                детально по пунктам ответить на все обвинения и недостоверную и ложную
                информацию, изложенную в статьях приходского листка, чтобы остановить
                распространяющейся нашем приходе грех клеветы </p>
            

            
            <p>В самом начале
                статьи первого выпуска листка говорится: <em>«…они
                    являются последователями вероучения священника Георгия Кочеткова, хотя об этом
                    открыто не заявляют». </em></p>
            

            
            <p>Мы об этом не
                заявляем ни в открытой, ни в закрытой форме, потому что нет какого-то
                отдельного вероучения о. Георгия Кочеткова, имеющего расхождения с догматами и
                канонами Православной Церкви. При этом, мы хотели бы отметить, что не скрываем
                и не стесняемся того факта, что о. Георгий Кочетков является нашим духовным
                отцом и наставником, и всегда об этом открыто говорим. </p>
            

            
            <p>Далее в статье
                сказано: <em>«он (о. Георгий – А.К.)
                    провозглашает свои идеи, как истину в последней инстанции, при этом постоянно
                    ссылаясь на самого себя, как на высший авторитет». </em></p>
            

            
            <p>Данное обвинение является
                заведомо ложным, не подкреплено никакими фактами и доказательствами,
                следовательно, является клеветой и оговором православного священника.</p>
            

            
            <p><em>«…Рассматриваемые статьи и книги предназначены в
                качестве учебных пособий для катехизаторов и катехизируемых». </em></p>
            

            
            <p>В катехизисе «В
                начале было слово» отображен многолетний опыт в катехизации проводимый о. Георгием
                и катехизаторами Преображенского братства. В основе системы катехизации,
                предложенной о. Георгием лежит его магистерская диссертация «Таинственное
                введение в православную катехетику», защищенная в Свято-Сергиевском
                православном богословском институте в Париже. Цель катехизиса – дать
                возможность катехизаторам построить свой катехизаторский материал, опираясь на уже
                имеющийся опыт. Данная система оглашения имеет свои корни в богатейшей
                святоотеческой традиции Православной Церкви. Последние два года в СФИ
                проводится конференция, посвященная святоотеческим основаниям оглашения. На
                этой конференции всегда присутствуют десятки священников из других епархий,
                катехизаторы, миссионеры.</p>
            

            
            <p><em>«Такие основополагающие богословские термины понятия,
                как «Святой Дух», «Церковь», «древо познания добра и зла», «диавол» и т.д.
                истолковываются о. Георгием в новом, в далеком от святоотеческого понимания
                смысле».</em></p>
            

            
            <p>Все термины и
                понятия, представленные в катехизисе и трудах о. Георгия объясняются, и всегда
                дается ссылка на того или иного святого отца, трактовка которого в данном
                случае используется. Святые отцы по-разному объясняли данные понятия в
                зависимости от целевой аудитории, культурно-исторической традиции, в которой
                они писали и говорили, с целью достичь максимальной глубины и быть актуальными
                для своих современников. Поэтому какая-то унификация в данном случае представляется
                абсолютно иллюзорной, вредной, уводящей от истинны. В данном обвинении в
                очередной раз не предоставлено никаких подтверждений, в виде конкретных ссылок
                и цитат из трудов о. Георгия. Просто декларативно заявляется о несоответствии
                святым отцам. Но так можно сказать про что угодно, что, мол, то или иное
                высказывание не соответствует святоотеческой традиции. Что же при этом критик
                подразумевает под святоотеческой традицией и насколько он сам ее адекватно воспринимает,
                остается абсолютно не ясным.</p>
            

            
            <p><em>«В своей диссертации священник Георгий дает краткий и
                пространный СВОЙ СИМВОЛ ВЕРЫ, в котором нет учения о Святой Троице».</em></p>
            

            
            <p>О. Георгий
                Кочетков, как и каждый православный христианин, придерживается православного Никео-Цареградского
                символа веры. Никаких «своих» символов веры в диссертации о. Георгия нет. Но в
                катехизисе уже во второй теме со стр. 59 приводится и подробно разбирается
                Никео-Цареградский символ веры и дается задание выучить его наизусть.</p>
            

            
            <p>То, что автором
                именуется «символом веры о. Георгия» является описанием веры человека на разных
                ступенях духовного возрастания. Так как оглашение – это путь, на котором вера
                человека постепенно расширяется и углубляется. Это сейчас на собеседовании
                перед крещением на нашем приходе желающему креститься истолковывают Символ веры
                в заменяющей оглашение предкрещальной беседе. Святоотеческая традиция гораздо
                богаче. Символ веры человеку «доверяли» только в конце длительного оглашения (см.
                о. Павел Гаврилюк «История катехизации древней церкви»).</p>
            

            
            <p><em>«По свящ. Г.Кочеткову, история Рождества есть мифо-поэтический
                вымысел, сочиненный авторами Евангелий – Матфеем и Лукой».</em></p>
            

            
            <p>Нигде о .Георгий
                Кочетков не говорит, что история Рождества – «мифо-поэтический вымысел». Опять
                автор статьи не приводит не одной ссылки или цитаты. О. Георгий говорит о <strong>мифо-поэтическом языке</strong> Писания. К
                сожалению, современный человек, даже верующий, бывает мало знаком с библейской
                экзегетикой, текстологией и герменевтикой. (Данный термин о. Георгием
                используется с тем расчетом, что потенциальный катехизатор будет иметь
                соответствующее образование). Вышеперечисленные дисциплины оперируют таким
                термином как «мифо-поэтический язык» для обозначения тех мест Св. Писания,
                которые описывают события духовного значения. Мифо-поэтический язык очень
                широко распространен в Писании, такие события, как создание, мира, потоп, исход
                Израиля и пр. описываются именно мифо-поэтическим языком, при этом утверждается
                их полная достоверность и истинность.</p>
            

            
            <p><em>&nbsp;«Св. Георгий
                Кочетков считает, что Причастие в Церкви происходит освященным хлебом и вином,
                а не Пречистым Телом и Честною Кровью Господа и Бога и Спаса нашего Иисуса
                Христа».</em></p>
            

            
            <p>Где о. Георгий это
                утверждает? Где ссылки, цитаты? Удивляет только, что о. Георгию не
                приписываются высказывания о джихаде против неверных! Создается ощущение, что автор
                вообще просто сидел и выдумывал все возможное и невозможное, с целью оговорить
                человека, православного священника. Но одно дело подобные вещи читать в
                светской желтой газете, другое дело в православном издании, авторами которого
                являются православные люди, обязанные помнить девятую заповедь декалога «Не
                произноси ложного свидетельства на ближнего твоего» (Исх.20:16).</p>
            

            
            <p><em>«Женское священство не только возможно, но и
                прозревается о. Георгием в ближайшем будущем».</em></p>
            

            
            <p>Такого о. Георгий
                нигде и никогда не говорил.</p>
            

            
            <p><em>«Важно заметить, что свящ. Г. Кочетков и его
                последователи практически не считают себя членами Православной Церкви».</em></p>
            

            
            <p>Это клевета. В
                принципах Преображенского содружества малых православных братств первым
                приниципом является</p>
            

            
            <p>I. Основные
                принципы жизни Преображенского братства</p>
            

            
            <p><strong>Церковность</strong></p>
            

            
            <p><strong>Принципиальная и неразрывная, внутренняя и внешняя
                связь с Церковью</strong>,
                которая «есть Тело Христово», и, в <strong>частности,
                    с Русской православной церковью</strong>, а также решимость не отделяться от
                Православия ни при каких условиях, ибо «любой грех в церкви не есть грех
                Церкви, но грех против Церкви» (свящ. Валентин Свенцицкий). Итак, это —
                верность Церкви Христовой и понимание того, что братство — только её часть. <strong>Для нас принадлежность к Церкви важнее
                    принадлежности к братству</strong>, а ещё важнее — наша любовь к Богу и ближним
                (выделено мной А.К.). </p>
            

            
            <p><em>«Непризнание Господа нашего Иисуса Христа «единосущным
                Отцу», а Святого Духа Господом, лицом Святой Троицы, единосущной и
                нераздельной, делает вероучение свящ. Георгия Кочеткова неправославным».</em></p>
            

            
            <p>Это клевета на
                человека, находящегося в сущем сане. Здесь напрямую говорится о том, что о.
                Георгий Кочетков не признает Христа Сыном Божьим. Человек, придерживающийся
                взглядов, изложенных выше, не то что православным, даже просто христианином
                именоваться не может. Но это клевета наводит тень не только на о. Георгия но и
                на его правящего архиерея патриарха Кирилла, на митрополита Ювеналиия, архиепископа
                Григория, которым о. Георгий сослужит, монахинь и мирян, молящихся в
                Новодевичьем монастыре, вообще на священноначалие РПЦ МП, которое позволяет и
                благословляет деятельность о. Георгия, СФИ, братства. Как патриарх и архиереи
                могут позволять служить священнику, исповедующему нехристианские взгляды? Как
                священноначалие может благословлять деятельность учебного заведения, выдавать
                лицензию на его деятельность, когда руководство и сотрудники данного института
                придерживаются и распространяют нехристианские, еретические взгляды? Поэтому
                данная информация - это уже клевета не только на о. Георгия, институт и
                братство, но и на упомянутое выше священноначалие Церкви, которое по мнению
                анонимного автора статьи дерзко нарушает 45 апостольское правило, гласящее:</p>
            

            
            <p>&nbsp;«Епископ, пресвитер или диакон, только
                молившийся с еретиками, да будет отлучен. Если же позволит им действовать
                как-либо, как служителям Церкви, то да будет извержен».</p>
            

            
            <p>«<em>Святоотеческое наследие в книгах свящ.
                Георгия Кочеткова либо остается невостребованным, либо подвергается критике</em>».</p>
            

            
            <p>Вся система
                катехизации о. Георгия строится именно на святоотеческой традиции. </p>
            

            
            <p>Мы не будем здесь
                отвечать на критику о. Константина Буфеева и Дворкина. На это уйдет слишком
                много времени. Хотелось бы только выделить одно предложение А.Л. Дворкина: «Они
                приводят людей, прежде всего, в свою общину». Это ложь. Согласно статистике в
                братстве остаются от 10% тех, кто прошел в нем оглашение. Остальные люди
                остаются в церкви, при этом не являясь членами братства. Например, в
                Екатеринбургской епархии более 10 священнослужителей пришли к Богу и в Церковь
                через оглашение в Преображенском братстве. Все те, кто становится членами
                братства ходят в православные храмы, участвуют в церковных Таинствах. Также и в
                Нижневартовске люди, прошедшее оглашение в братстве, ходят в православные
                храмы, где их, к сожалению, встречают не по-братски: клеветническими статьями о
                них самих и об их духовном отце.</p>
            

            
            <p>Из второго выпуска
                приходской газеты.</p>
            

            
            <p><em>«Эти люди называют себя православными, однако ведут
                свою деятельность без благословения Правящего архиерея».</em></p>
            

            
            <p>Вся наша
                деятельность заключается в том, что мы хотим поделиться верою с нашими родными,
                друзьями и знакомыми. Это право и святой долг каждого христианина, исполнение
                заповеди Господней: «Идите, научите все народы…» (Матф.28:19)». На исполнение
                заповедей, как известно, не надо брать благословение. Мы проводим встречи с
                нашими близкими, друзьями, рассказывая им о нашей вере, призываем читать
                Св.Писание, соблюдать заповеди, ходить в храмы. Конечная цель этих встреч –
                обретение и исповедание людьми Православной веры. Крайне печально, что приходы
                города Нижневартовска, как получается, противодействуют этому, осуществляя, по
                сути, контр-миссию. К сожалению, в Нижневартовске мы наблюдаем печальную
                картину, когда множество крещенных людей, хотя себя и называют православными, по
                сути являются неверующими или язычниками. И таких людей большинство среди наших
                родных и друзей. Многие из тех, кто все-таки приходит в храм, чувствуя какой-то
                призыв Божий, надолго там не задерживаются, потому что у нас в городе пока, к
                сожалению, нет опыта длительного оглашения, который помогает воцерковлению
                человека. Среди наших знакомых есть такие, которые делали первые шаги к вере,
                но дальше не пошли, потому что не нашли какой-то опоры, помощи, просто не
                знали, как и куда идти, где взять сил. Много таких, кто вообще никогда не
                задумывался о вере, есть те, кто отрицательно относится к церкви, как к «религиозной
                структуре», говоря, что «Бог у него/нее в душе и никакие посредники в виде
                церкви им не нужны». И вот всем этим людям, повторюсь, нашим родным, близким,
                друзьям, мы пытаемся говорить о Боге, о христианстве. </p>
            

            
            <p><em>«Поэтому их встречи проходят на частных квартирах, а
                приглашаются туда лишь избранные».</em></p>
            

            
            <p>Но вот именно потому,
                что эти встречи частные, они и проводятся на частных квартирах. И, конечно же,
                приглашаются только «избранные», то есть родные, личные друзья и знакомые. Еще
                раз повторюсь, что цели этих встреч – привести людей в храм. Сами мы, кто
                организуют эти встречи, регулярно ходим в храм, причащаемся и исповедуемся.
                Священники знают, кого они исповедуют и причащают, поэтому нас также смущает
                фраза «эти люди». Автор статьи прекрасно знает, о ком он пишет. </p>
            

            
            <p><em>«Для укрепления гордыни эта элита именуются «полными»
                членами церкви, представителями «высокого» православия».</em></p>
            

            
            <p>Автор статьи
                обвиняет нас в гордыни. Безусловно, гордыня - страсть, с которой христианин борется
                всю жизнь. Но насколько эта страсть пустила корни в каждом конкретном человеке,
                тем более в группе людей, знает только Бог и духовник этих людей/ человека; это
                может увидеть прозорливым оком человек святой жизни, у которого есть дар
                старчества. На каком из вышеприведенных оснований автор обвиняет членов
                братства в гордыне – не понятно. </p>
            

            
            <p>Что касается
                «полных» членов церкви. Да, любой член церкви, ведущий христианский образ
                жизни, регулярно участвующий в Таинствах, на мой взгляд, может считаться полным
                членом Церкви. В очередной раз автор пишет просто голословные обвинения, не
                подкрепленные никакими доказательствами. </p>
            

            
            <p>Что же касается
                «избранности». Все христиане являются родом избранным: «Но вы - род избранный,
                царственное священство, народ святой, люди, взятые в удел»(1Пет.2); «Вы - свет
                мира. Не может укрыться город, стоящий на верху горы.(Матф.5:14). Поэтому – да,
                христиане – это действительная избранная часть человечества, что, конечно, не
                повод для гордости, а призыв к смирению и особой ответственности.</p>
            

            
            <p><em>«Кочетковцы считают своего кумира, затмившего для них
                собою авторитет Церкви, человеком незаурядным».</em></p>
            

            
            <p>Мы считаем невежливым
                по отношению к православным братьям и сестрам из уст других православных
                наименование «кочетковцы». Представьте, о. Максим, если бы Ваших духовных чад и
                друзей называли «дурневцами». В Церкви нет «кочетковцев» и «дурневцев», в Церкви
                есть только христиане, братья и сестры, дети Одного Отца. И все мы, члены
                Преображенского содружества малых православных братства считаем себя и именуем
                православными христианами, последователями Господа Иисуса Христа. О. Георгий же
                является, согласно православной традиции, нашим духовным отцом и попечителем
                братства. И к нему мы относимся, как и полагается в Церкви относиться к
                духовным наставникам, с любовью и почтением.</p>
            

            
            <p>Наименование
                православного священника «кумиром» мы считаем просто оскорбительным и
                непозволительным в издании православного прихода. Кумир – слово, обозначающее
                ложного бога, идола. Поэтому назвать так священника – это неуважение к
                священному сану, а следовательно, и к самой Церкви, в которой данной священник
                рукоположен и осуществляет свое служение. </p>
            

            
            <p><em>«Так ведь все создатели заметных ересей были людьми
                незаурядными, поэтому им и удалось многих увлечь за собой в ад!».</em></p>
            

            
            <p>Все создатели
                ересей известны поименно. Их участь решалась на Церковных соборах. Существуют
                канонические документы, называющее имя еретика и ересь, которой он
                придерживался. Автор статьи, таким образом, присваивает себе честь и право
                Церковного собора: он обвиняет о. Георгия в создании «заметной ереси»! Это
                просто откровенная клевета на православного священника, переходящая постепенно («удалось
                многих увлечь за собой в ад») просто в непристойную брань. Крайне печально
                читать клевету и оскорбления со страниц православного печатного издания.</p>
            

            
            <p><em>«Эти люди прельщены уверенностью, что они - лучшая часть
                Церкви Христовой». </em></p>
            

            
            <p>Опять голословное
                обвинение. На каком основании автор сделал такое заключение, что все члены
                братства находятся в данном прельщении? Каким образом он так безапелляционно
                тысячи человек обвиняет, по сути, в духовной прелести? </p>
            

            
            <p><em>«В организации кочетковцев используется осуществляется
                специальная психологическая обработка, обеспечивающая объединение людей, якобы
                на основе любви, но на самом деле – на основе сознания своей исключительности».</em></p>
            

            
            <p>Вот подобные
                высказывания и можно вполне назвать «психологической обработкой». Автор просто
                начинает запугивать людей, в очередной раз говоря клевету. Какие аргументы и
                факты автор приводит в доказательство утверждения об использовании «психологической
                обработки» в братстве?</p>
            

            
            <p><em>«Свобода же для них – это в первую очередь полная
                независимость от священноначалия».</em></p>
            

            
            <p>Нигде ни о.
                Георгий, ни какой-либо другой член братства не говорил о «полной независимости
                от священноначалия». Хотя добродетель полной или частичной зависимости от
                священноначалия нам тоже неизвестна. </p>
            

            
            <p>Братством была
                проведена целая конференция на тему «Старшинство и иерархичность в церкви и
                обществе».&nbsp;</p>
            

            
            <p><em>«Они отвергают законно установленное в Церкви
                иерархическое священство (т.е. диаконов, священников, епископов)».</em></p>
            

            
            <p>Как мы можем
                отвергать все это, если духовным попечителем братства является священник? В
                братстве есть другие священники. У братства много друзей священников, которые
                не являются членами братства, но которые принимают участие в разных
                мероприятиях СФИ и братства. </p>
            
        </div>
    );
};

export default Hanty1;
