import React from "react";
import './text.css';
const Ufa3 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2012/04-04/1 Уфа</h2>
            <div align={'center'}>
                <img src={require('../images/image-3.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
            <p></p>
            <div align={'center'}>
                <img src={require('../images/image-4.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>
        </div>
    );
};

export default Ufa3;
