import React from "react";
import './text.css';
const Velsk8 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2014/04-08 Вельск</h2>
            
            <p class="has-text-align-right">Его Высокопреподобию, прот. Андрею
                Ермилову,</p>
            

            
            <p class="has-text-align-right">настоятелю Вельского
                Свято-Успенского храма</p>
            

            
            <p class="has-text-align-right">Котласской и Вельской епархии</p>
            

            
            <p class="has-text-align-center">ПРОШЕНИЕ.</p>
            

            
            <p>Ваше Высокопреподобие, уважаемый
                отец Андрей!</p>
            

            
            <p>30
                марта 2014 года, в неделю преподобного Иоанна Лествичника, в своём слове после
                литургии Вы объявили, что отныне в Вельском храме Успения Пресвятой Богородицы
                «запрещено причащаться всем последователям священника Георгия Кочеткова».</p>
            

            
            <p>Поскольку
                Ваши слова вызвали смущение среди многих прихожан, то мы, желая мира церковного,
                хотим прояснить ситуацию.</p>
            

            
            <p>Прежде
                всего хотим Вам сказать, что мы сами, как и многие наши знакомые, пришли ко
                Христу и в Православную Церковь благодаря пастырским трудам и заботам отца
                Георгия Кочеткова и Преображенского братства. Отрицать этот факт с нашей
                стороны было бы неблагодарно и нелепо. Вместе с тем мы ответственно заявляем,
                что не являемся «последователями учения священника Георгия Кочеткова». Мы —
                православные христиане, чада Русской Православной Церкви Московского
                Патриархата.</p>
            

            
            <p>Поэтому
                во избежание недоразумений и дальнейших нестроений просим Вас:</p>
            

            
            <ol><li>Официально
                опубликовать список тех лиц, кому запрещено причащаться в Свято-Успенском
                храме города Вельска. </li><li>Поскольку
                мы не можем предположить, что Ваше решение принималось с пренебрежением к
                каноническому преданию Матери-Церкви, то просим Вас сделать подробное
                каноническое обоснование данного запрета, а именно:</li></ol>
            

            
            <p>а)
                Чётко сформулировать, какие конкретные каноны, догматы и традиции Православной
                Церкви нарушаются на Вельском приходе людьми, пришедшими в Церковь благодаря
                пастырской деятельности священника Георгия Кочеткова.</p>
            

            
            <p>б)
                Какие епитимии и прещения канонически налагаются церковью за эти нарушения и на
                какие сроки?</p>
            

            
            <p><em>С пожеланием благодатного
                завершения Великого поста и радостной встречи Пасхи Христовой, прихожане
                Свято-Успенского храма г. Вельска:</em></p>
            

            
            <p><em>Привалова Т.</em></p>
            

            
            <p><em>Привалова А.</em></p>
            

            
            <p><em>Холзакова В.</em></p>
            

            
            <p><em>Кондакова Т.</em></p>
            

            
            <p>8 апреля 2014 года</p>
            
        </div>
    );
};

export default Velsk8;
