import React from "react";
import './text.css';
const Ufa6 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Методическое пособие по миссионерской деятельности в Уфимской епархии</h2>
            
            <p>Уфа-2010</p>
            

            
            <p>По благословению Высокопреосвященнейшего Никона</p>
            

            
            <p>Архиепископа Уфимского и Стерлитамакского</p>
            

            
            <p>Авторы - составители:</p>
            

            
            <p>М.Ларионова</p>
            

            
            <p>Под редакцией игумена Игнатия (Климова)</p>
            

            
            <p><em>Миссионерский учебно-методический центр</em>: г. Уфа, ул.
                Лесопильная 4, тел 8-927-0877739, 8-917-3498814<strong>
                </strong></p>
            

            
            <p><strong>Часть 1. Задачи миссионерской деятельности на приходах
                Уфимской епархии.</strong></p>
            

            
            <p>Православная миссия – это активная деятельность по
                распространению православной веры, воцерковлению людей и передаче опыта
                богообщения. Поэтому главная цель миссионерской деятельности на уровне прихода
                - <strong>созидание евхаристической общины</strong>
                на территории пастырской ответственности данного прихода. Это возможно лишь
                тогда, когда и настоятель и члены прихода осознают свою миссионерскую
                ответственность.</p>
            

            
            <p>В соответствии с Концепцией миссионерской деятельности
                РПЦ <strong>миссионерская ответственность
                    настоятеля прихода</strong> подразумевает:</p>
            

            
            <p>1)
                Привлечение активных прихожан к миссионерскому служению.</p>
            

            
            <p>2)
                Ориентирование воскресных школ и молодежных организаций на миссионерскую
                деятельность</p>
            

            
            <p>3)
                Воспитание в приходском активе миссионерского духа посредством личного примера</p>
            

            
            <p><strong>Миссионерская ответственность мирянина</strong> подразумевает:</p>
            

            
            <ol><li>Знание основ православного вероучения и активное
                участие в жизни прихода. </li><li>Свидетельствование об истине Православия в
                соответствии с образованием, способностями и профессиональными навыками.</li></ol>
            

            
            <p>Формы
                миссионерской деятельности на приходе:</p>
            

            
            <ol><li><strong>Проведение миссионерских богослужений</strong></li></ol>
            

            
            <p>Многие прихожане православных храмов, как
                новоначальные, так и имеющие многолетний опыт участия в таинствах, не понимают
                смысла большинства элементов литургии и вечернего богослужения, что приводит к возникновению&nbsp; различных суеверий и искажений, а так же
                невнимательного и неблагочестивого поведения во время богослужения.</p>
            

            
            <p>Поэтому необходимо регулярное проведение
                специальных миссионерских богослужений, на которых богослужение сочетается с
                элементами катехизации, доступным объяснением исторического и символического
                значения элементов богослужения, подробным объяснением богослужебных текстов, чтением
                русского варианта текстов. </p>
            

            
            <p>Информацию о методике проведения
                богослужений можно &nbsp;найти здесь: <a href="http://www.pravmir.ru/article_2664.html">http://www.pravmir.ru/article_2664.html</a></p>
            

            
            <p>Частота проведения миссионерских
                богослужений – не менее 2 раз в год.</p>
            

            
            <ul><li><strong>Распространение среди прихожан бесплатной и
                достоверной&nbsp; печатной информации об
                основах православной веры, значении таинств и православных праздников.</strong></li></ul>
            

            
            <p>Не все прихожане могут приобрести литературу в церковных
                лавках, и не вся литература соответствует миссионерским нуждам прихода. По
                благословению архиепископа Никона Миссионерским Центром Уфимской епархии будут
                разработаны специальные брошюры и разосланы по всем приходам.</p>
            

            
            <p>Настоятели храмов могут сами составлять
                и распечатывать такие брошюры. Наиболее актуальны следующие вопросы: подготовка
                к таинствам&nbsp; крещения, исповеди,
                причастия, венчания, объяснение различных суеверий и т.д.</p>
            

            
            <p>Собственные разработки необходимо присылать
                в Миссионерский центр для утверждения у архиерея. Обязательные требования к
                таким распечаткам: а) они должны быть краткие; б) раздаваться бесплатно; в)
                содержать телефон или электронный адрес для консультаций&nbsp; и перечень источников, по которым можно узнать
                дополнительную информацию (адреса интернет-сайтов и т.п.)</p>
            

            
            <p>В дни праздничных и воскресных дней необходимо
                наладить подготовку материалов с информацией о богослужении, включающей <strong>тексты Евангелия и Апостола на русском
                    языке</strong>, а также их краткое объяснение и рассказ об отмечаемом событии.</p>
            

            
            <ul><li><strong>Распространение информации о деятельности сект в общеобразовательных
                школах и других учреждениях </strong></li></ul>
            

            
            <p>С помощью
                активных мирян, члены семей которых учатся в общеобразовательных школах и
                ВУЗах, посещают различные кружки и секции, настоятелю необходимо наладить
                контакт с теми учреждениями, где действуют секты под прикрытием общественных
                организаций, торговых компаний, психологических консультаций или новых
                педагогических методик. Список сект, действующих на территории РБ, приведен в Приложении
                № 1.</p>
            

            
            <p>Настоятель
                или другое ответственное лицо может самостоятельно определить наличие секты или
                обратиться за консультацией в Миссионерский Отдел Уфимской епархии к иерею
                Никите Семенову по адресу: 450103,
                г. Уфа, ул. Сочинская, 29, 2-й корпус епархиального
                управления; тел. (347) 256-34-17, сообщив необходимые сведения о признаках
                секты.</p>
            

            
            <p>Сообщать
                администрации учреждений о деятельности сект возможно только неофициально, без
                составления документов и отражения в прессе во избежание конфликтов и обращения
                в судебные органы со стороны руководства сект.</p>
            

            
            <p><strong>4) Содействие обучению миссионеров</strong></p>
            

            
            <p>В крупных
                приходах Уфимской епархии необходимо наиболее активных прихожан рекомендовать к
                обучению на богословских курсах по специальности «миссионерство». Если после
                окончания обучения настоятель принимает его в штат постоянных сотрудников
                храма, то миссионерская работа на приходе будет проходить максимально
                эффективно.</p>
            

            
            <p><strong>Часть 2. Задачи миссионерского Отдела Уфимской епархии</strong></p>
            

            
            <p>&nbsp;В
                настоящее время в епархии неплохо развита система сбора информации о
                сектантских организациях, действующих на территории РБ, и противодействия им. Налажено
                тесное сотрудничество с Информационно - консультативным центром по изучению
                проблем духовно-нравственного воспитания молодежи при филиале МГГУ им. Шолохова
                и с миссионерским Отделом Томской епархии. Успешно функционирует миссионерско-апологетический
                Интернет-проект «К истине». &nbsp;В епархии
                ведется прием по вопросам пагубного влияния сект и оккультизма.</p>
            

            
            <p>Однако мы видим, что сектантские
                организации по-прежнему действуют гораздо активнее, чем РПЦ. Сейчас практически
                во всех школах и ВУЗах среди студентов и преподавателей имеются активные адепты
                не только протестантских организаций, но и деструктивных сект. В БГУ и УГАТУ –
                засилие саейнтологов, в БГПУ – рериховцев. Свидетели Иеговы много лет успешно
                работают в обществе инвалидов и с глухонемыми школьного возраста.</p>
            

            
            <p>Становится понятным, что<strong> одно лишь накопление и пассивное
                распространение информации не может заменить активную проповедь.</strong> Уфимская
                епархия нуждается в «положительном миссионерстве», в разработке методики
                внутренней и внешней миссии. </p>
            

            
            <p>Учитывая сложившуюся ситуацию,
                эффективную активную проповедь не может вести один человек, а только <strong>сплоченная группа</strong> активных и специально
                обученных людей. С началом работы епархиальных богословских курсов появилась
                возможность целенаправленного обучения молодых миссионерских кадров для работы
                на уровне приходов и благочиний.</p>
            

            
            <p>Необходимы следующие направления
                деятельности Миссионерского отдела Уфимской епархии:</p>
            

            
            <ol><li><strong>Создание
                миссионерских команд</strong> из учащихся
                богословских курсов: 3-4 уфимских команды из вечернего отделения и 2-3 команды
                епархиальные из соседних благочиний (примерно через полгода, поскольку
                встречаться будут редко). Общее руководство командами будет вести о. Артемий
                Ларионов. В каждой команде&nbsp; необходим
                ответственный - лидер. Обучение&nbsp; команд и
                подготовка конкретных мероприятий будет проходить в рамках &nbsp;богословских курсов.</li></ol>
            

            
            <p>Поскольку миссионерская команда состоит из членов
                разных приходов соседних благочиний, то находится не в распоряжении одного
                благочинного, а в непосредственном подчинении Миссионерскому Отделу епархии.
                Отдел определяет зону миссионерской ответственности каждой группы и уровень ее
                готовности к тем или иным акциям.</p>
            

            
            <ul><li><strong>Организация
                регулярных</strong> <strong>миссионерских поездок</strong> силами миссионерских команд (методику
                организации поездок разрабатывает Центр). Как конечный результат – постепенное полноценное
                возрождение приходов.</li><li>Дневные выезды в деревню (молебен, оглашение,
                крещение, причастие)</li><li>Многодневные экспедиции (хор, концерт, проповедь,
                крещение, причастие, раздача литературы)</li></ul>
            

            
            <p>Необходимость проведения миссионерских акций в тех или
                иных населенных пунктах определяется помощником благочинного по миссионерству
                после согласования с благочинным. </p>
            

            
            <ul><li><strong>Подготовка
                почвы для нормальной работы других отделов</strong> &nbsp;- первоначальные разовые визиты
                или реализация развернутых миссионерских программ<ul><li>Тюрьмы</li></ul><ul><li>Больницы</li></ul><ul><li>Социальные приюты</li></ul><ul><li>Кадетский корпус</li></ul><ul><li>Молодежные клубы</li></ul></li></ul>
            

            
            <ul><li>Разработка и введение программы <strong>более плотной работы с невоцерковленной молодежью</strong></li></ul>
            

            
            <p>Особое внимание необходимо обратить на сотрудничество
                и создание совместных программ с активной нецерковной молодежью (молодежные
                организации, клубы, движения т.д.) в области организации социального служения.
                Рассматривать различные молодежные и студенческие проекты, ориентированные на
                работу с социально неблагополучными категориями населения или в области
                патриотического воспитания подрастающего поколения перспективным миссионерским
                направлением. Стремиться организовывать подобные мероприятия с целью
                привлечения в Церковь активной молодежи<strong>.
                </strong></p>
            

            
            <ul><li>Проведение миссионерскими группами <strong>циклов бесед духовно-нравственного
                содержания</strong> в средних и высших учебных заведениях в районах своей
                миссионерской ответственности. </li><li>Против абортов (за семью)</li><li>Против наркомании (о смысле жизни)</li><li>О выборе профессии (нравственный аспект)</li></ul>
            

            
            <ul><li><strong>Организация
                миссионерских концертов в крупных городах епархии</strong> (музыкальные группы + театр + реклама)</li><li>Рождество и Пасха</li><li>День защиты детей</li><li>День национального единства</li><li>День города</li><li>День молодежи и т.д.</li></ul>
            

            
            <ul><li><strong>Создание хотя
                бы одного миссионерского прихода</strong>
                (регулярные богослужения с объяснениями и т.д.)</li></ul>
            

            
            <ul><li><strong>Проведение
                регулярных миссионерских семинаров </strong>с
                целью:</li><li>Изучения миссионерского поля епархии</li><li>Оценка эффективности работы миссионерских групп</li></ul>
            

            
            <ul><li><strong>Создание
                специальных видео программ</strong>,
                ориентированных на нецерковную аудиторию. Распространение видео материалов
                через Интернет и телеканалы.</li></ul>
            

            
            <p><strong>Часть 3.
                Задачи помощников благочинных по миссионерству.</strong></p>
            

            
            <ol><li><strong>Предоставление информации</strong> <strong>Миссионерскому
                Отделу</strong> о необходимости проведения тех или иных миссионерских акций:<ol><li>В населенных
                пунктах благочиния, редко посещаемых священником – миссионерских экспедиций</li></ol><ol><li>В более крупных
                населенных пунктах – миссионерских программ для тюрем, больниц, молодежных
                клубов и т.д.</li></ol><ol><li>Миссионерских
                концертов</li></ol></li></ol>
            

            
            <ul><li><strong>Обеспечение
                нормальной работы миссионерской группы в благочинии</strong>:<ul><li>Предварительная официальная договоренность с
                администрацией района или тех учреждений, где проводятся акции или концерты</li></ul><ul><li>Обеспечение миссионерских и концертных групп
                транспортом, жильем, питанием на время проведения акций или концертов</li></ul></li></ul>
            

            
            <ul><li><strong>Сбор
                информации о деятельности сект на территории благочиния</strong> и предоставление этой информации Миссионерскому
                Отделу</li></ul>
            

            
            <ul><li><strong>Распространение методических пособий, листовок и брошюр,</strong> подготовленных Миссионерским Центром по всем приходам благочиния.<ul><li>В электронном виде – для тех приходов, где имеется возможность распечатать большое количество экземпляров</li></ul><ul><li>В готовом печатном виде – для тех приходов, где такой возможности нет</li></ul></li></ul>
            
        </div>
    );
};

export default Ufa6;
