import React from "react";
import './text.css';
const Ufa7 = () => {
    return (
        <div align={'start'} className="text1">
            <h2>2012/11-11 Красноусольск</h2>
            <div align={'center'}>
                <img src={require('../images/image-5.png')} alt={"title"} width={"50%"} align={"center"}/>
            </div>

        </div>
    );
};

export default Ufa7;
