import React from "react";
import './text.css';
const HantyDeti = () => {
    return (
        <div align={'start'} className="text1">
            <h2>Из воспоминаний Марии Никитиной</h2>
            
            <p><strong>Вместо вступления.</strong></p>
            

            
            <p>Итак, приступив к
                написанию этого сочинения, я начала жалеть о том, что в то время не вела
                систематический дневник. Теперь многое подзабылось, да и описать могу неточно.</p>
            

            
            <p>На создание этой повести
                меня вдохновил мой учитель и пример для подражания Владимир Ильич Лавренов, за
                что я теперь не знаю, благодарить его или нет. Но я начала, и это главное.</p>
            

            
            <p>Здесь я расскажу о том,
                как я видела те события, которые происходили в моём городе, на приходе храма
                Рождества Христова, а также в православной гимназии, в течение двух лет. В
                процессе повествования мне придётся имена некоторых действующих лиц заменить на
                вымышленные, чтобы потом моя повесть не стала для них компрометирующим материалом.
                Тем более, что некоторым из них ещё целый год предстоит обучаться или работать
                в той же самой гимназии.</p>
            

            
            <p>Заранее прошу прощения за
                художественный стиль повествования, но, видно, так мне излагать свои мысли
                легче.</p>
            

            
            <p>...</p>
            

            
            <p>С первого по девятый
                класс я училась в православной гимназии, которую до сих пор люблю и по ней же
                скучаю. В ней же учился мой младший брат Иван и работала моя мама – завучем по
                воспитательной работе и учителем истории. </p>
            

            
            <p>Всё началось тогда, когда
                в нашу школу пришёл новый учитель по английскому языку – Александр Анатольевич.
                Он был молодым и горячим, и с первых дней начал активно участвовать в жизни
                гимназии. Он много помогал моей маме, и его работа имела наглядный результат.
                Мой класс тогда был на пороге выпуска из начальной школы, и мы, в буквальном
                смысле, молились о том, чтобы нам достался именно он. И, к нашей великой
                радости, в новом учебном году на пороге нашего классного кабинета нас ждал
                Александр Анатольевич. Но мы его совсем не берегли, и, думаю, именно поэтому он
                до сих пор туго общается с детьми.</p>
            

            
            <p>…&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
            

            
            <p>Моя мама с Александром
                Анатольевичем (если позволите, я и дальше буду называть его так) постоянно и
                много общалась как на работе, так и вне её. Ей нравился этот молодой человек, и
                она говорила мне, что её поражает его культура речи и глубокие размышления, что
                ей стоит многому учиться у него. Наверное, именно поэтому тогда, когда он решил
                проводить встречи, на которых можно было бы говорить о Боге, вере и обсуждать Писание,
                мама поддержала его. Собралась небольшая компания: мама, Александр Анатольевич,
                Олеся, Артём и Полина, которая, как и я, училась в православной гимназии, только
                в 9 классе. Они собирались в кабинете истории во внерабочее время, и просили
                меня никому об этом не рассказывать. Мне было не интересно, чем они там
                занимались. Они не трогали меня, я не выдавала их, и меня это устраивало. Пока…</p>
            

            
            <p>Пока эти встречи не стали
                проходить у нас дома. Мне нравились все эти люди, но меня настораживали их
                действия. Папа же маму не поддерживал, ворчал о том, что «не нравится ему всё
                это», но проводить встречи у нас не запрещал. Даже интересно: в Церковь привёл
                папа маму, а в братство мама папу привела.… Но это потом.</p>
            

            
            <p>Спустя какое-то время эта
                «компания» начала проходить оглашение. Они сидели в зале, разговаривали с
                кем-то по скайпу, а потом вместе молились. Но они читали не обычное вечернее
                правило, а какие-то другие молитвы на РУССКОМ ЯЗЫКЕ, которые мне казались
                уродливыми. Я не знала, что такое оглашение и что на нём изучали, я ни с кем об
                этом не разговаривала, но внутри себя твёрдо решила, что моя мама ПОПАЛА В
                СЕКТУ. </p>
            

            
            <p>Я поняла, что маму
                спасать уже поздно, но самой нужно бороться до конца. Мама не единожды пыталась
                позвать меня молиться вместе с «ними», но я сразу же давала такой отпор, что
                маме приходилось почти сбегать из моей с Ваней комнаты. Но, даже если маме и
                удавалось «затащить» меня на вечерню, я ставила условие, что в любой момент могу
                уйти, если мне что-то не понравилось. И я уходила скоро, так как от молитв на
                русском меня начинало просто тошнить. Ваня поддерживал мою войну, хотя мы ни
                разу с ним не разговаривали об этой ситуации и ни о чём не договаривались. Нам
                просто вместе это не нравилось, и всё.
            </p>
            

            
            <p>….</p>
            

            
            <p>Через какое-то время мама
                сообщила мне, что к нам приезжает их катехизатор – человек, проводящий встречи
                по оглашению, и я смогу ему задать вопросы, которые, может быть, меня
                интересуют… О, мне было действительно интересно увидеть того человека, который
                учит мою маму всякой «дури» и высказать ему всё, что я думаю о нём и об «их»
                встречах! Я сказала маме, что у меня будут к нему вопросы, и всю следующую
                неделю я готовилась к встрече.</p>
            

            
            <p>…</p>
            

            
            <p>Катехизатором у маминой
                группы тогда был дядя Володя Иванов. Он приехал в город рано утром и
                остановился у нас дома, но встретиться с ним я смогла только вечером. Сначала
                была встреча группы, на которую меня не пустили, что ещё больше возбуждало моё
                любопытство. Я помню, как взрослые выходили из комнаты, на их лицах было
                выражение, будто произошло нечто… необычное. Их можно понять: впервые они
                смогли увидеть человека, которого они полгода могли только слышать по скайпу.
                Но мне это было не важно. Я готовилась к войне. </p>
            

            
            <p>В конце концов, вышла
                мама и сказала, что катехизатор меня ждёт. Я кивнула и вошла.</p>
            

            
            <p>Передо мной на диване
                сидел мужчина с таким простодушным лицом, что я опешила. Неужели такой, ТАКОЙ
                человек может держать на себе внимание четырёх взрослых человек целый вечер?! Я
                сразу расслабилась, и очень даже зря. </p>
            

            
            <p>Наш разговор длился
                недолго, от силы минут пятнадцать, но этого мне хватило, чтобы почувствовать,
                что передо мной сидит очень «глубокий» человек, что он гораздо интереснее, чем
                кажется на первый взгляд. Но самое главное – морально он НАМНОГО сильнее меня.
                И даже сильнее мамы (хотя на то время мне таких людей было в принципе сложно
                представить)!</p>
            

            
            <p>С того дня я не
                сопротивлялась «этим маминым занятиям», но и не участвовала в них.</p>
            

            
            <p>. . .</p>
            

            
            <p>Ближе к лету снова приехал
                катехизатор, но уже другой – Олег Глаголев. Мне не стали объяснять причину
                смены катехизатора, но мне это было тогда не сильно важно – я изучала его. Он
                отличался от предыдущего манерой поведения, общения, но от него исходила все та
                же непонятная мне сила, внушающая уважение и страх. Сейчас я назову это
                присутствием Духа, но тогда мне не было известно это понятие.</p>
            

            
            <p>Мы вместе выехали на
                природу, устроили скромную трапезу, и, «преломив» арбуз, Олег прочитал «Отче
                наш» на русском языке. Может, до этого я и слышала этот перевод на «сборищах»
                маминой группы, но в этот момент она для меня открылась как-то по-новому.
                Наверное, я впервые осознала смысл молитвы, которую знала наизусть с
                дошкольного возраста, может, были и ещё причины. Но я поймала себя на мысли,
                что русский перевод уже не кажется мне таким уродливым и не вызывает старого
                чувства отвращения.</p>
            

            
            <p>. . .</p>
            

            
            <p>В середине июня нам с
                братом сообщили, что мы едем в подростковый лагерь Содружества. Эта новость
                была встречена бурным протестом и боем подушками. Принимать увлечения родителей
                еще куда ни шло. Хотели нашего участия в ваших молитвах? Пожалуйста, и даже
                сами приходим! Но ехать в ИХ лагерь – ни за что. Хватит с нас. Нас не так
                просто будет заманить в вашу секту, даже поместив в её среду. Но нам сказали,
                что менять уже что-либо поздно, и мы начали готовиться…</p>
            

            
            <p>Разумеется, я пыталась
                себе представить, каким будет этот лагерь. До этого мы с братом два года ездили
                в православный лагерь Анатолия Гармаева, поэтому «православный лагерь» у меня
                ассоциировался с юбкой в пол, платочками, молитвенным правилом и строгим
                делением отрядов на мальчишеские и девичьи. В соответствии с этим опытом я
                создала в воображении определённый образ, который мне казался довольно приемлемым.</p>
            

            
            <p>ЮСЛ проходил под
                Екатеринбургом, на живописном полуострове, отделённом рекой от села Аракаево. Я
                с замиранием сердца ожидала первой встречи с обитателями лагеря. На переправе
                через реку нас встречала девушка. В майке. В порванных джинсах. С распущенными
                волосами и прядью-расточкой. Выше я упоминала своё примерное представление об
                этом лагере… В общем, если у меня и были какие-то капли доверия к «этим» людям,
                то они сразу испарились.</p>
            

            
            <p>Я стала наблюдать. Первые
                дни я ожидала от каждого участника лагеря какого-нибудь подвоха, «промывки
                мозгов» под видом доброжелательных отношений. Общаясь с ребятами, я старалась
                фильтровать каждое их слово на предмет «их сектантской пропаганды» и прочих
                опасных вещей. Но послушав, как и о чем они говорят, я поняла, что многие мои
                выводы были неверны, а страхи – беспочвенны. Постепенно я сблизилась с
                обитателями лагеря, выучила некоторые молитвы на русском и даже пела вместе с
                хором. Наверное, лагерь сыграл одну из главных ролей в том, что я приняла
                «мамину идеологию»</p>
            

            
            <p>. . .</p>
            

            
            <p>Весь остаток лета я
                провела в братской среде, что позволило мне окончательно убедиться в том, что
                мамино решение быть там не было ошибочным. В полном восторге я приехала домой,
                жалея только об одном – я не могла поделиться этим с одноклассниками. Но этого
                и не нужно было – после этих поездок моя жизнь сильно изменилась, что
                отразилось и на них тоже.</p>
            

            
            <p>На уроках Основ
                Православной Культуры Александр Анатольевич стал давать нам по молитве на
                домашнее задание – переводить на русский, как сами понимаем их смысл. Это
                оказалось очень интересным занятием, особенно было занятно сравнивать, у кого
                перевод окажется самым близким к истине. Многие молитвы открывались заново,
                некоторые переводы были кардинальной противоположностью нашим предположением. Это
                заставляло задуматься: как и о чем мы молились всю нашу жизнь? С каждым уроком
                становилось все интереснее, вопросов возникало все больше.</p>
            

            
            <p>Лично я с сентября пошла
                на подростковое оглашение и начала участвовать в вечернях вместе со взрослой
                группой (уже по собственной воле). Моя жизнь стала намного интереснее, всё шло
                здорово… До одного злополучного разговора.</p>
            

            
            <p>. . .</p>
            

            
            <p>Я помню тот вечер в
                мельчайших подробностях. Я укладывалсь спать в зале, мама работала в компьютере
                - готовилась к урокам на следующий день. Вдруг она резко поворачивается ко мне:</p>
            

            
            <p>&nbsp;- Маш, у меня есть серьезный разговор...</p>
            

            
            <p>Обычно это были кодовые
                слова, означавшие, что я где-то провинилась и со мной будет проведена
                воспитательная беседа. Но по тону мамы я поняла, что дело касается не только
                меня, и, возможно, я даже не имею к нему никакого отношения. И дело это очень
                важное.</p>
            

            
            <p>&nbsp;Несмотря на то, что я уже
                "отрубалась", я села на кровати:</p>
            

            
            <p>&nbsp;- Ну, и?..</p>
            

            
            <p>&nbsp;- Я должна уйти из гимназии.</p>
            

            
            <p>&nbsp;- В смысле... Это шутка такая?</p>
            

            
            <p>&nbsp;- Нет, Маш, это совсем серьезно.</p>
            

            
            <p>&nbsp;- Что.. Почему?!! Тебе не нравится работать в
                гимназии?</p>
            

            
            <p>&nbsp;- Нравится, ты же знаешь... </p>
            

            
            <p>&nbsp;- Предложили работу лучше?</p>
            

            
            <p>&nbsp;- Нет, откуда бы.</p>
            

            
            <p>&nbsp;- Ну тогда что?!</p>
            

            
            <p>В моей голове каша
                мешалась с ядерными взрывами. Действительно, ЧТО могло случиться, чтобы мама
                ушла из гимназии? Тем более, что уже готовится рождественский праздник...</p>
            

            
            <p>&nbsp;- Мне нужно отречься от братства.</p>
            

            
            <p>Я потеряла не только дар
                речи, но и дар думать. Мама продолжала, но голос ее начал дрожать:</p>
            

            
            <p>&nbsp;- Мне поставили условие: либо я остаюсь в
                братстве и меня выгоняют из гимназии, либо я отрекаюсь от отца Георгия, признаю
                его еретиком, но остаюсь только в качестве учителя. Вот так.</p>
            

            
            <p>&nbsp;- А Александр Анатольевич?</p>
            

            
            <p>&nbsp;- Он уже уволен. </p>
            

            
            <p>Тут я не выдержала и
                разрыдалась. Весь мой маленький мирок, в котором я жила и в котором все было
                хорошо, рушился с грохотом - я впервые столкнулась с ужасом реальной жизни.
                Душа разрывалась от непонимания и отчаяния. Я не была готова встретиться с этой
                болью, но разве к этому можно быть готовым?!</p>
            

            
            <p>&nbsp;Я долго плакала, не имея сил остановиться.
                Плакала и мама. Возможно, она впервые смогла высказать кому-то свои
                переживания, боль; думаю, она долго вынашивала в себе это решение, и этот вечер
                должен был быть решающим для нее. </p>
            

            
            <p>&nbsp;Странно, но я не винила братство. Ни в чем. У
                меня даже не возникло мысли обвинять братство в мамином горе. Но не могла я
                испытывать неприязнь и к гимназии - просто не могла поверить в предательство,
                а, может, и не хотела. </p>
            

            
            <p>&nbsp;А сердце разрывалось между двумя несоединимыми
                мирами, в которых мне было хорошо.</p>
            

            
            <p>&nbsp;Через какое-то время я пришла в себя и задала,
                на мой взгляд, самый разумных вопрос из всех, что крутились у меня в голове:</p>
            

            
            <p>&nbsp;- Ну, и что ты сама решила?</p>
            

            
            <p>Мама глубоко вздохнула:</p>
            

            
            <p>&nbsp;- Я решила уйти из гимназии. </p>
            

            
            <p>Я уже было набрала
                воздуха, чтобы возразить, но она меня остановила:</p>
            

            
            <p>&nbsp;- Погоди, не перебивай. Я объясню свой выбор.
                Просто... Понимаешь, я подумала так: если я уйду из гимназии, то докажу, что я
                права (ведь ты и сама понимаешь, что моей вины здесь нет), и моя совесть будет
                чиста. А если останусь в гимназии, то и братство предам, и здесь ко мне люди с
                уважением относиться не будут. И смысл тогда мне оставаться в гимназии?</p>
            

            
            <p>&nbsp;Я глубоко вздохнула. Логика железная, не
                поспоришь. И почему все так сложно?!</p>
            

            
            <p>&nbsp;- Хорошо, пускай будет так. Думаю, это самое
                верное решение.</p>
            

            
            <p>. . .</p>
            

            
            <p>&nbsp;Как оказалось, все началось с отделения нашей,
                Ханты-Мансийской, епархии, от Тобольско-Тюменской, и назначения к нам нового
                епископа - владыки Павла. К нему-то и полетели письма от всех недовольных
                прихожан, начитавшихся всякой "желтой прессы" про братство. Он почитал
                "доносы", почитал упоминаемые в них сайты, и принял решение об
                "удалении неблагоприятных элементов". </p>
            

            
            <p>&nbsp;Если до этого момента мамины оппоненты еще
                как-то сдерживавшись, то после этого вердикта события повалились одни за
                другими, как лава при извержении вулкана. Родители учеников устроили собрание
                (причем маму туда даже не пригласили), где повесили на маму просто самые
                нереальные прегрешения: вот-де она в пост заставляли детей сыр на уроках есть,
                вместо истории рассказывала всякие антицерковные тексты; она опасна для детей,
                мучает их всякими пытками... Наша завхоз, мамина хорошая подруга, металась от
                оппонентов к защите мамы и наоборот, не понимая, что ей делать. Директору
                (который раньше нас поддерживал и с интересом относился к братскому опыту жизни
                в церкви) еще раньше пришлось встать на сторону оппонентов - а что делать? Ему
                уже намекнули, что у него большая семья, сам человек подневольный... А вдруг
                как на какой дальний приход переведут, куда за просто так и не доберешься?..
                Вердикт собрания - уволить Елену Николаевну, и как можно скорее. </p>
            

            
            <p>&nbsp;Ребята же, узнав о случившимся, сразу стали
                горячо вступаться за маму, ведь она была одной из любимых учительниц во всей
                старшей школе. "Что вообще за ерунду тут несут?", "Что на Елену
                Николаевну понавешали? Ведь это все - ложь сплошная!" Все пытались хоть
                что-то сказать если не учителям, то хотя бы родителям. Да куда уж там! Кто
                детей будет слушать! Кому интересно то, что Елена Николаевна никого не пытает,
                что ничего плохого она никогда не рассказывала и что у нее интересные уроки,
                если они все за детей знают?! А через какое-то время и вовсе запретили ребятам
                поднимать эту тему под угрозой наказания.</p>
            

            
            <p>&nbsp;Никто из них до этого не сталкивался с такой
                стороной "взрослой" жизни. Как и я, но к этому времени я уже
                воспринимала все происходящее как должное. Мы все были напуганы тем, с какой
                яростью кидались на маму разъяренные мамаши, еще несколько дней назад бывшие с
                ней в теплых дружеских отношениях. Никто не знал, что взрослые люди могут быть
                так глухи к словам и так слепы к очевидным вещам, которые видели даже малыши.
                Мы страдали от нежелания слушать нас со стороны взрослых и от полной
                беспомощности, но могли только наблюдать, как рушится наша привычная жизнь в
                школе. </p>
            

            
            <p>&nbsp;Многие ребята поддерживали меня. Как-то мы
                ехали на одном автобусе в храм с Витей - парнем на класс меня старше. В обычное
                время мы почти не общались - только по надобности. Но вдруг он ко мне подходит:</p>
            

            
            <p>- Маш, я хочу поговорить
                с тобой. Можно? </p>
            

            
            <p>Я была очень удивлена, но
                сразу же подвинулась, чтобы уступить место рядом с собой. </p>
            

            
            <p>&nbsp;- Я хотел поговорить о Елене Николаевне...</p>
            

            
            <p>Он говорил долго.
                Рассказал, что его класс собрался вместе (а это почти невозможно!) и ходил к
                директору, чтобы заступиться за маму. Они пытались что-то объяснить, но их не
                захотели слушать. А до них ходили другие ребята, но их даже не приняли. С
                родителями говорить бесполезно - они начинают кричать, и говорят, что моя мама
                ужасный человек, и что ее срочно нужно гнать из гимназии, а почему - толком
                объяснить не могут даже.</p>
            

            
            <p>&nbsp;В какой-то момент он воскликнул:</p>
            

            
            <p>&nbsp;- Но почему ее так гонят?! Она же ничего не
                сделала! Да она же лучше их всех, и уроки у нее классные - что их, зависть
                заела?!</p>
            

            
            <p>И столько было боли и
                отчаяния в этих словах, что я почувствовала, что я не одинока, что они все со
                мной.</p>
            

            
            <p>&nbsp;- Мы все за твою маму, - сказал Витя на выходе
                из автобуса. - И готовы все горой встать. Даже если мы ничего не сможем
                сделать, мы будем на ее стороне. Передай ей это.</p>
            

            
            <p>&nbsp;Рождественский праздник, который мама
                готовила, мы уже провели без нее. Что за странное условие: мама должна была
                подготовить все к рождественскому мероприятию, но ей уже было запрещено на нем
                появляться! Оно прошло с ноткой легкой грусти - все ребята уже смирились с тем,
                что ничего не изменить.</p>
            

            
            <p>. . .</p>
            

            
            <p>&nbsp;Наша же группа на Рождество устроила
                миссионерский праздник в ДК "Октябрь". Туда мы пригласили всех своих
                друзей, с которыми хотели бы поделиться радостью - как православных, так и
                неверующих. Сначала был спектакль, а потом - чаепитие и беседа о празднике и
                Вере. Мы с Ваней сидели в это время с детьми гостей. Вдруг к нам приходит мама,
                и по ее лицу было видно, что что-то случилось, а она не знает: плакать ей или
                смеяться. Разумеется, мне стало интересно, что же такое произошло. А она
                сказала, что ей только что звонил директор православной гимназии и
                интересовался, чем это таким мама занимается, ибо ему донесли, что в ДК
                "Октябрь" собрали православных гимназистов, чтобы поздравить с
                Рождеством кочетковцев!!!</p>
            

            
            <p>. . .</p>
            

            
            <p>&nbsp;В марте мы узнали, что к нам в город приезжает
                Александр Дворкин - крупный специалист по сектам. Насколько мне известно, его
                пригласили наши прихожане и родители гимназистов специально по нашему вопросу.
                Ну мы и решили - раз встреча про нашу честь, то и нам туда сходить следует. </p>
            

            
            <p>&nbsp;Встреча проходила в актовом зале православной
                гимназии, так что мне туда вход был беспрепятственным, тем более, что учителя
                настаивали на присутствии гимназистов на этом мероприятии. Взрослые тоже смогли
                пройти, в частности Александр Анатольевич, но я думаю, их пустили с
                определенными умыслами.</p>
            

            
            <p>&nbsp;С самого начала встречи я старалась
                внимательно следить за каждым словом Александра Леонидовича, даже записывала
                некоторые выдержки из его речи - ведь я понимала, что любое его слово может
                быть впоследствии использовано против нас. Хотя, честное слово, на то время мне
                это давалось колоссальными усилиями! Да и вскоре я оставила это дело, потому
                что оно оказалось ненужным. </p>
            

            
            <p>&nbsp;В его речи часто отсутствовали логические
                связи между положениями и он допускал множество серьезных промахов, которые
                заметили даже пятиклашки. К примеру, он говорил, что лидеры большинства сект
                больны нарциссизмом, много говорят о себе, опираясь на "якобы
                поддержку" знаменитых и уважаемых людей; спустя какое-то время он
                сообщает, что выпустил свою автобиографию в нескольких томах, которая оценена
                многими видными иерархами и знаменитыми людьми... Уже даже по лицам тех, кто
                его пригласил с выступлением, было видно, что они глубоко раскаиваются в
                содеянном и потерпели полный провал. </p>
            

            
            <p>&nbsp;Во время, когда задавались вопросы, Александр
                Анатольевич встал и задал вопрос относительно претензий Дворкина к
                Преображенскому братству и отцу Георгию в частности. Александр Леонидович
                попытался свести ответ к высмеиванию как братства, так и задавшего вопрос, но
                его положения были настолько нелепы и несвязны, что поставили его самого в еще
                худшее положение. </p>
            

            
            <p>&nbsp;В итоге встреча окончилась конфузом для тех,
                кто ее собрал, но и нам не удалось на ней отстоять свою позицию.</p>
            

            
            <p>. . .</p>
            

            
            <p>В апреле нашей группе
                запретили ходить в храм Иоанна Предтечи, потому что прихожане сильно возмущались
                на этот счет, да и настоятель, сменивший отца Серафима - отец Андрей, уже не
                мог поддерживать с нами доброжелательные отношения; а вскоре туда же перевели
                отца Даниила, нашего главного противника, так что вся группа перешла во второй
                храм города (тогда их было два) - храм Рождества Христова. На мне это не сильно
                отразилось - я так же продолжала звонить на колокольне и участвовать в жизни
                прихода, просто немного задерживалась на "послелитургический" обед
                группы. Да и вообще до конца учебного года нас с Ваней никто не трогал - как в
                храме, так и в гимназии.</p>
            

            
            <p>. . .</p>
            

            
            <p>В общем, мне казалось,
                что жизнь наладилась. Мама устроилась работать в обычную школу, группа просто
                стала ходить на литургии в другой храм, все волнения вокруг нас улеглись и все
                было замечательно... Но это было лишь маленькое затишье перед большой бурей.</p>
            

            
            <p>&nbsp;Одним ноябрьским днем я пришла "в
                гости" к одному из младших классов - гимназия у нас маленькая, так что мы
                все там друг дружку знали и хорошо общались. Но мои подружки встретили меня со
                странными выражениями лиц, будто они чем-то напуганы, но не могут понять
                причину своего страха, и предложили поболтать на улице. Я согласилась, тем
                более была большая перемена и я сама хотела прогуляться. На улице девчонки
                расслабились, мы хорошо поговорили, но когда мы стали прощаться, они вдруг
                смолкли и тихо сказали: "Маш, ты только не говори никому, что мы с тобой
                общались хорошо?" "Да, хорошо. А к чему такая скрытность, если не
                секрет?" "Если наши мамы узнают, нас накажут. Они говорят, что твоя
                мама - преступница, и с тобой тоже нельзя разговаривать". Я невольно
                прислонилась к стене, чтобы не упасть, и ошарашенно выдавила: "Да...
                Хорошо, я никому не скажу".</p>
            

            
            <p>&nbsp;Через дня два я забежала к одной из самых
                близких подруг в школе, на 2 класса старше тех малышей, с которыми я общалась
                накануне. Объяснила ей, что нужно поговорить. Она сразу нахмурилась и коротко
                ответила: </p>
            

            
            <p>&nbsp;- Идем на заброшку. </p>
            

            
            <p>"ЗабрОшкой" мы
                называли старый лестничный пролет, идущий только до второго этажа - к медпункту
                - задуманный, по-видимому, в целях пожарной эвакуации. Учителя туда не
                заходили, а ученики использовали его для важных личных переговоров.</p>
            

            
            <p>&nbsp;Как только дверь за нами закрылась, я спросила
                в лоб:</p>
            

            
            <p>&nbsp;- Кать, ты не знаешь, что здесь происходит?
                Почему малыши со мной не разговаривают?</p>
            

            
            <p>&nbsp;Я всегда ценила эту девочку за не по годам
                развитых аналитической склад ума и здравомыслие; поэтому адекватнее всего про
                исходящую ситуацию могла объяснить только она.</p>
            

            
            <p>&nbsp;- Им просто учителя запрещают с тобой
                разговаривать. Вот и все. Угрожают выговорами, директором и вызовами родителей.
                Типо ты опасная, всю жизнь нас располагала к себе, чтобы запудрить мозги и так
                далее. Но лично я считаю, что это чушь полная, потому что если бы ты хотела это
                сделать, то сделала бы уже давно; а я до сих пор ни о чем толком не знаю.</p>
            

            
            <p>&nbsp;- Значит, вот как... Тогда я постараюсь тебя
                не подставить...</p>
            

            
            <p>&nbsp;- Не боюсь я их, нашла о чем беспокоиться. Ты
                же знаешь, что не виновата, так чего боишься? Да и Елена Николаевна ничего не
                сделала плохого. Забудь о них, я на твоей стороне.</p>
            

            
            <p>. . .</p>
            

            
            <p>&nbsp;В ноябре мы с Ваней ездили на подростковый
                выезд в Екатеринбург. Вернулись под большим впечатлением, наполненные радостью
                и новыми силами. Видно, впрок запасались.</p>
            

            
            <p>&nbsp;Мы приехали в субботу вечером, а в воскресенье
                утром я, как обычно, пошла на литургию в храм Иоанна Предтечи. На часах
                поднялась на колокольню, отзвонила так, что сердце замирало; такое ощущение
                было, что я за веревки полгода не держалась, а не неделю - так я соскучилась.
                Спускаюсь вниз, а меня уже мальчишки- пономари внизу ждут:</p>
            

            
            <p>&nbsp;- Ты чего так долго?! Батюшка сказал тебя
                немедленно вниз спустить!</p>
            

            
            <p>&nbsp;- А что случилось-то? - и правда, я ведь и
                звонила меньше положенного времени.</p>
            

            
            <p>&nbsp;- Говорит, ты без благословения звонить
                полезла. Передал, чтобы ты немедленно вниз спускалась, и после службы подошла к
                нему объясняться.</p>
            

            
            <p>&nbsp;- В смысле?! Звонила, звонила, вас учила, и
                все было нормально, а тут раз - и без благословения? Он же мне на начало года
                его давал! Просрочилось, что ли?!!</p>
            

            
            <p>&nbsp;- Да мы сами ничего не поняли. И чего это
                он... Ну, не знаем, он что сказал, то мы и передали. </p>
            

            
            <p>&nbsp;Я половину службы пробыла в прострации, а
                потом решила во что бы то ни стало разобраться во всем. Встала специально на то
                место, куда обычно батюшка на исповедь выходит - раз сказал подойти, так надо
                так и сделать. Но батюшка не вышел, хотя он всегда выходит перед причастием.
                Тогда я встала на главном входе и решила перехватить его там. </p>
            

            
            <p>&nbsp;Окончилась служба, окончился водосвятный
                молебен, а настоятель домой не собирался. Но я решила упорно его ждать, до
                победного. Через какое-то время мимо меня прошел отец Даниил, бросив на меня
                горделиво-презрительный взгляд. И в этот момент я сразу поняла, кто стоит за
                всей этой неразберихой, хотя мне очень не хотелось вы это верить. Я выбежала на
                улицу, чтобы откликнуться его, но он даже не замедлил шаг, будто и не слышал
                меня; хотя я была уверена, что крикнула достаточно громко. Тогда я вернулась в
                храм и простояла еще минут двадцать, ожидая отца Андрея. Потом ко мне подошла
                тетя Аня из церковной лавки:</p>
            

            
            <p>&nbsp;- Ты кого, Маш, ждешь?</p>
            

            
            <p>&nbsp;- Да отца Андрея, долго его нет...</p>
            

            
            <p>&nbsp;- Так зачем зря стоишь-то? Он же давно уже
                через боковой вход ушел. Я ж не знала, что тебе он нужен, а то сказала бы...</p>
            

            
            <p>&nbsp;Я поблагодарила тетю Аню и пошла домой. Ну,
                это уже не лезло ни в какие ворота! Он от меня что, еще и прятаться начал?</p>
            

            
            <p>&nbsp;В гимназии я специально узнала, в каких
                классах и в какие дни недели отец Андрей ведет ОПК (основы православной
                культуры); в день его уроков отменила все дела, которые могли бы задержать меня
                на перемене, и, как только прозвенел звонок с 3 урока, побежала к нужному мне
                кабинету. Когда я вылетела из-за поворота, отец Андрей только что вышел из
                кабинета и направлялся в мою сторону. Увидев меня, он отшатнулся, но так как
                другого пути для него не было, то он просто ускорил шаг, намереваясь пройти
                мимо меня; я окликнула его, но он только еще ускорил шаг. Тогда мне пришлось
                встать ровно по середине прохода и, когда батюшка был в двух шагах от меня,
                сделать выпад и схватить его за руку. </p>
            

            
            <p>&nbsp;- О, здравствуй, Маша, - скороговоркой
                проговорил он, не сбавляя хода. -Ты что-то хотела? </p>
            

            
            <p>&nbsp;- Батюшка, я хотела с Вам поговорить о...</p>
            

            
            <p>&nbsp;- Прости, но я сейчас занят и не могу с тобой
                разговаривать. Давай в другой раз.</p>
            

            
            <p>&nbsp;- Когда?</p>
            

            
            <p>&nbsp;- Не знаю. На следующей неделе, - он
                наконец-то смог вырвать свою руку из моей и почти побежал к выходу.</p>
            

            
            <p>&nbsp;На следующей неделе мне сообщили, что отец
                Андрей заболел, и уроки проводить не будет; потом он поменялся уроками с другим
                учителем... Таким образом я ловила его почти месяц. В один прекрасный момент я
                поймала его в маленькой трапезной, в которой кушают завучи и батюшки. Комнатка
                три на три, только один вход, который я сразу загородила - в этот раз выбора я
                ему не предоставила.</p>
            

            
            <p>&nbsp;- Маш, я сейчас не могу, давай потом...</p>
            

            
            <p>&nbsp;- Я не смогу потом. Послушайте, я с вами уже
                месяц поговорить не могу, а дело важное.</p>
            

            
            <p>&nbsp;Батюшка глубоко вздохнул, и с выражением
                глубокого страдания на лице спросил:</p>
            

            
            <p>&nbsp;- Ну, что ты хотела?</p>
            

            
            <p>&nbsp;- Месяц назад по Вашему указанию мне запретили
                подниматься на колокольню, на которой я звоню уже второй год, якобы по причине
                того, что у меня нет на это благословения. Но ведь Вы сами мне его давали в
                сентябре, поэтому я не совсем понимаю, что происходит. </p>
            

            
            <p>&nbsp;- Я такого не помню. </p>
            

            
            <p>&nbsp;- Это очень странно, потому что это может
                каждый пономарь подтвердить... Хорошо, пусть так. Тогда дайте мне благословение
                сейчас, ведь звонить все равно некому, пока я мальчишек не обучу.</p>
            

            
            <p>&nbsp;- Ну, я не могу, сейчас там колокольня не в
                лучшем состоянии...</p>
            

            
            <p>&nbsp;- Там все в порядке. Единственное, чего там не
                хватает - так это наушников, но мы с ребятами звоним и без них.</p>
            

            
            <p>&nbsp;- Тогда, пока их там нет, звонить там нельзя. </p>
            

            
            <p>&nbsp;- Вы их можете закупить?</p>
            

            
            <p>&nbsp;- Да, конечно, я на этой неделе сделаю
                заказ...</p>
            

            
            <p>&nbsp;- И тогда я смогу звонить?</p>
            

            
            <p>&nbsp;- Да, конечно.</p>
            

            
            <p>&nbsp;Удовлетворенная, я вышла из трапезной, думая,
                что дело улажено. Ко мне подходили прихожане храма и спрашивали, когда я снова
                начну звонить, и я их уверяла, что скоро будут куплены наушники и я вернусь. Но
                через неделю оказалось, что отец Андрей забыл из заказать, еще через неделю в
                бюджете храма не были выделены деньги на это.... Я уже даже предложила купить
                наушники за свои деньги, но он сказал, что это излишне. А еще через неделю
                батюшка сказал, что я его достала и тема закрыта. Так я осталась без колокольни,
                и, так как меня теперь в том храме ничего не держало, я стала ходить вместе с
                группой в большой храм; но еще долгое время, когда я слышала колокольный звон,
                на мои глаза наворачивались слезы.</p>
            

            
            <p>&nbsp;. . .</p>
            

            
            <p>На старшую часть группы в
                это время шли гонения полным ходом. Уже несколько раз выпускались в газету
                заметки о братстве и членах нашей группы (в которых были написаны не только
                неверные, но во многом просто глупые обвинения), в храме люди сторонились нас,
                некоторые прихожане высказывали свои претензии по поводу литургических книжек и
                братской жизни прямо в лицо. Особо активные прихожане принялись
                "спасать" людей, которые ходили к нам на предоглашение, и с каждой
                встречей приходило человек все меньше. Ситуация складывалась напряженная.</p>
            

            
            <p>&nbsp;В декабре в храме Рождества Христова сменился
                настоятель - отец Сергий. Мы очень надеялись, что эти перемены к лучшему, ведь
                он - человек со стороны и сможет незамыленным взглядом посмотреть на нашу
                проблему. Но наши надежды оказались напрасными. Под конец пятичасовой встречи с
                нами настоятель заявил, что мы - еретики, и объяснить, почему, он не может, но
                он "так чувствует"; а так как мы еретики, то ничего общего с нами он
                иметь не хочет. И вот тут-то началась настоящая "война". </p>
            

            
            <p>. . . </p>
            

            
            <p>&nbsp;Восемнадцатого января в храме царила
                возбужденная суматоха, которая обыкновенно бывает перед большими праздниками.
                Вся наша группа пришла на исповедь, на следующий день должна была быть агапа, и
                все мы пребывали в радостном ожидании. Но вдруг Александр Анатольевич как-то
                быстро вернулся с исповеди, и среди взрослых началось какое-то волнение. Мы
                спрашивали у них, что произошло, но нам отвечали, что ничего; но их бледные
                лица раскрывали их волнение, так что оно невольно передалось и нам с Ваней. В
                конце концов из разговора мамы и Олеси я поняла, что Александра Анатольевича
                отказались исповедовать, сказав, что "все вопросы - к настоятелю".
                Настоятель же находится на Оби, у купелей, но старшие решили его дождаться. Мне
                предложили пойти домой, но я отказалась, чувствуя, что происходит нечто важное.
                Так мы стояли еще минут сорок, люди проходили мимо нас на исповедь, а мы все
                ждали. Вот, наконец-то появился настоятель. Но, увидев нас, он быстро прошел в
                алтарь, отказав нам в переговорах.</p>
            

            
            <p>&nbsp;Наутро мы попытались что-нибудь прояснить, но
                безрезультатно. Тогда было решено все равно идти к причастию, к чаше отца
                Сергия. Началось причастие. Люди проходили мимо нас, принимали Святые Дары и
                уходили, а мы все ждали. В конце концов, у чаши осталась только наша группа.
                Отец Сергий посмотрел на нас... и ушел в алтарь. На следующий день настоятель
                прямо заявил нам, что отлучает нас от причастия.</p>
            

            
            <p>. . .</p>
            

            
            <p>&nbsp;Разумеется, новость о том, что произошло в
                храме, быстро разлетелась среди прихожан, и если до этого родители гимназистов
                еще как-то соблюдали нейтралитет в отношении меня, то с этого момента стали
                меня явно избегать, как прокаженной. Как-то какой-то мальчишка из первых
                классов прокричал мне вслед: "Отступница!", - и, хотя учительница
                угрожающе шикнула на него, было видно, что мальчик просто озвучил то, что было
                у всех взрослых на уме, просто они об этом умалчивали. Вокруг меня нависло
                такое затишье, которое случается в последние секунды до взрыва, и это очень
                подавляло. </p>
            

            
            <p>. . .</p>
            

            
            <p>&nbsp;Во второй половине февраля часть группы, в их
                числе и моя мама, уехала в Москву, на сессию Богословского колледжа. Взрослые
                рассчитывали, что это на время принесет "разрядку" в нашу
                "политическую ситуацию": священники смогут немного вздохнуть в
                отсутствии самых сильных "оппонентов", последние же "подкрепят
                силы" в братской среде. В принципе, так оно и было.</p>
            

            
            <p>&nbsp;И вот, в один из дней, когда папа был на
                работе "в день" (то есть мы с Ваней на целый день остались одни), ко
                мне в класс забегает дежурный и сообщает, что меня срочно, прямо с урока,
                вызывают к директору. Все ребята сразу посмотрели на меня с искорками в глазах.
                Я пожала плечами, в шутливом тоне ответила: "Я ничего не делала", - и
                выбежала из класса вслед за дежурным. </p>
            

            
            <p>&nbsp;У нас в гимназии никто не боялся идти ни к
                завучу, ни к директору (возможно, одной из причин было то, что к директору мы
                каждый день подбегали за благословением, а может, дело в общей семейной
                обстановке в гимназии), но, по какой-то причине, чем ближе я подходила к
                кабинету отца Серафима, тем сильнее меня охватывало волнение. Я изо всех сил
                успокаивала себя, что я действительно ничего не натворила и в кабинете этом уже
                была несколько раз (а это было правдой), но когда мы подошли к самым дверям,
                ноги у меня были уже совсем ватными, как перед моим первым выходом на сцену. Я
                терялась в догадках и пыталась обдумать, что я, в случае чего, буду говорить.
                Но вдруг мне пришли в голову слова Писания: "Не заботьтесь, как или что
                сказать, ... ибо Дух Отца Вашего Небесного будет говорить через вас", - и
                это меня немного успокоило.</p>
            

            
            <p>&nbsp;Мне пришлось еще минут 10 подождать, прежде
                чем секретарь пригласила меня к директору. Собрав всю свою волю в кулак, я
                вошла в открытую передо мной дверь.</p>
            

            
            <p>&nbsp;Отец Серафим встретил меня кивком головы и
                предложил сесть. Я подвинула стул, он сильно заскрипел, что еще усилило мою
                неловкость. Батюшка терпеливо подождал, пока я усядусь, и тихо начал разговор:</p>
            

            
            <p>&nbsp;- Маша, мне нужно серьезно с тобой поговорить.
                О твоих родителях.</p>
            

            
            <p>Я вся напряглась. </p>
            

            
            <p>&nbsp;- Твои родители... Сильно заблуждаются, и
                делают многие неправильные вещи, которыми вводят в соблазн окружающих людей.
                Эта ситуация очень серьезная. Так как я директор этой школы, а тем более
                священник, я обязан тебе с Ваней помочь. Вы можете не до конца все понимать и
                оказаться впутанными во все это; я не могу этого допустить. Поэтому я позвал
                тебя сюда.</p>
            

            
            <p>&nbsp;Во-первых, ты должна оберечь Ваню от влияния
                этих людей, в особенности от Александра Анатольевича. Я понимаю, что вы ему
                доверяете, тем более он бывает у вас часто дома, но именно поэтому вам нужно
                быть наиболее осторожными - взрослые люди могут говорить не всегда правильные вещи.
                Александр Анатольевич сейчас не прав, а вы, ничего не понимая, можете принимать
                его слова за истину. Тебе уже пришлось столкнуться с этими вещами, но Ваню пока
                что все это не затронуло, и ты, как старшая сестра, должна спасти его.</p>
            

            
            <p>&nbsp;Во-вторых, я бы хотел, чтобы ты перестала
                читать Библию на литургии...</p>
            

            
            <p>&nbsp;И тут я, впервые за этот разговор, ответила:</p>
            

            
            <p>&nbsp;- Но почему? Что плохого в Писании? </p>
            

            
            <p>&nbsp;- Нет, ничего плохого в нем нет, но...</p>
            

            
            <p>&nbsp;- Тогда почему нельзя? В храме не всегда
                слышно, что читают, часто невнятно. </p>
            

            
            <p>&nbsp;- Но вы читаете Евангелие на русском языке! </p>
            

            
            <p>&nbsp;- Что плохого в том, что я хочу понимать смысл
                читаемого?</p>
            

            
            <p>&nbsp;- И на церковнославянском все понятно. И
                звучит это красивее и возвышеннее. Я часто читаю Евангелие на
                церковнославянском и восхищаюсь красотой этого языка! Сколько в нем духа!</p>
            

            
            <p>&nbsp;- Чем же русский язык плох? В конце концов, я
                ведь читаю тот же самый текст...</p>
            

            
            <p>&nbsp;- Если ты хочешь разобрать смысл, тогда
                прочитывай заранее дома, а в храме уже нормально слушай.</p>
            

            
            <p>&nbsp;- Но почему мне нельзя читать в храме?</p>
            

            
            <p>&nbsp;- Потому что чтение по книжке отвлекает тебя
                от слушания Писания! Зачем соблазнять окружающих прихожан, они молиться Богу
                пришли, а вы их книжками своими сбиваете. Просто заранее читай дома, чтение
                Писания дома никогда не возбранялось. Хорошо?</p>
            

            
            <p>&nbsp;Я кивнула головой, хотя понимала всю
                странность этой просьбы.</p>
            

            
            <p>&nbsp;- И последнее. Я бы попросил тебя о том, чтобы
                ты не читала с родителями вечерню и не участвовала в остальных ваших молитвах.</p>
            

            
            <p>&nbsp;И тут я сказала слова, которые никогда бы мне
                самой не пришли в голову; я до сих пор не понимаю, откуда они взялись:</p>
            

            
            <p>&nbsp;- Тогда давайте мы с Ваней переедем жить к
                Вам, - и я посмотрела отцу Серафиму прямо в глаза. В мгновение ока страх
                куда-то исчез, а в голове воцарилась необыкновенная ясность.</p>
            

            
            <p>&nbsp;На несколько секунд директор потерял дар речи,
                затем, запинаясь, проговорил:</p>
            

            
            <p>&nbsp;- А... Это... Оно... Зачем?!</p>
            

            
            <p>&nbsp;- Ну как мы сможем жить в одном доме с
                родителями, но при этом быть против них? Это же невозможно! Вы же хотите нас
                спасти, а если мы будем жить у Вас, то будем спасены точно.</p>
            

            
            <p>&nbsp;- Нет, но почему же сразу уходить из дома? Вы
                во всем будете вместе, просто молиться с ними не будете. Будете в другую
                комнату уходить и читать нормальное вечернее правило...</p>
            

            
            <p>&nbsp;- Неет, так не получится. Если мы одна семья,
                то и вся жизнь у нас должна быть общая. </p>
            

            
            <p>&nbsp;Может, я вижу и не все, но в том, что я вижу -
                что делают мои родители, какие молитвы читают - я не вижу ничего плохого. Да и
                пока мне не исполнится восемнадцать, я должна быть послушна родителям и верить
                им, разве не этому Вы меня учили? Поэтому не могу выполнить Ваших просьб.</p>
            

            
            <p>&nbsp;Отец Серафим долго ошарашенно осмотрел на
                меня, потом тихо сказал:</p>
            

            
            <p>&nbsp;- Хорошо, можешь идти, - и начал искать
                какие-то документы.</p>
            

            
            <p>Я попрощалась и вышла.</p>
            

            
            <p>&nbsp;Коридор был тих и пустынен - только что
                начался следующий урок. Я прошла почти до лестницы, потом тихо прошептала:
                "Ибо Дух Отца вашего будет говорить через вас", - и упала на пол.
                Меня начал колотить озноб, воздуха было мало, ноги отказывались слушаться и мое
                сердце все сильнее сковывал запоздалый ужас... До этого, разумеется, я ослушалась
                учителей, дерзила директору - это несложно, это каждый ученик делал. Я часто
                нарушала какие-нибудь правила поведения и уже спокойно могла за это держать
                ответ. Но вопросы, касающиеся моей жизни, моих моральных устоев - глобальные
                вопросы - за меня все-таки решали родители. Эти вопросы всегда были уделом
                взрослых, и я туда не вмешивалась. Теперь же мне пришлось вступить в
                идеологический спор со взрослым человеком, а помощи или хотя бы поддержки ждать
                было неоткуда. Здесь бы не получилось отсидеться за чьими-нибудь спинами,
                сбросить ответственность на более опытных людей - я в одиночестве столкнулась с
                жестокой реальностью. Да еще ладно, если б я отвечала только за себя - от моего
                решения зависела и судьба моего младшего брата...</p>
            

            
            <p>&nbsp;Так я и сидела посреди коридора, уставившись в
                одну точку, дрожа всем телом и глотая широко раскрытым ртом воздух... Как вдруг
                в кармане сарафана зазвонил телефон. Я деревянной рукой достала мобильный, не
                глядя нажала "принять".</p>
            

            
            <p>&nbsp;- Алло, Маша, у тебя все нормально?</p>
            

            
            <p>Голос Артема, старшего
                группы на время отсутствия Александра Анатольевича...</p>
            

            
            <p>&nbsp;- Артем, как ты...</p>
            

            
            <p>&nbsp;- Я не знаю, просто решил на всякий случай
                узнать. У тебя там ничего не случилось?</p>
            

            
            <p>&nbsp;К концу моего рассказа Артем уже не мог
                скрывать своего негодования. </p>
            

            
            <p>&nbsp;- Давай-ка я к отцу Серафиму схожу и поговорю
                с ним. </p>
            

            
            <p>&nbsp;- Я уже ничего не знаю...</p>
            

            
            <p>&nbsp;- Он же просто не имел на это права, ты этот
                понимаешь? Ты несовершеннолетняя... И вообще на него можно подавать в суд!</p>
            

            
            <p>&nbsp;- Нет, не надо в суд! Можешь с ним поговорить,
                да и нужно, наверное, а дальше посмотрим...</p>
            

            
            <p>. . .</p>
            

            
            <p>&nbsp;Через некоторое время, на уроке ОПК отец
                Серафим начал говорить про ереси и еретиков, потом резко перешел на отца
                Георгия, на братство и его опыт. Назвав несколько спорных мест по догматическим
                вопросам, батюшка спросил:</p>
            

            
            <p>&nbsp;- А ты, Маша, что думаешь по этому поводу? У
                тебя же мама в этом участвует! </p>
            

            
            <p>Сказал, как выстрелил.</p>
            

            
            <p>&nbsp;- Ну, я жду.</p>
            

            
            <p>&nbsp;Я растерянно встала и посмотрела директору в
                глаза. Как разительна и пугающая была перемена в его общении со мной! Он
                спросил меня так, как обычно спрашивает домашнее задание, но каждая фраза
                ощущалась как пощечина. Хотя, я ведь сама встала на эту дорогу, и поворачивать
                было поздно.</p>
            

            
            <p>&nbsp;Одноклассники не знали о моем с директором
                разговоре и с недоумением смотрели то на меня, то на батюшку. Они понимали, что
                происходит что-то, выходящее за рамки нормального, но не могли осознать сути
                происходящего. </p>
            

            
            <p>&nbsp;Я собрала всю свою волю в кулак и ответила,
                глядя на руки батюшки, лежавшие на столе:</p>
            

            
            <p>&nbsp;- Я не могу ответить на Ваш вопрос, потому что
                еще многого не знаю. Вы спрашиваете меня о тех богословских вопросах, о которых
                спорят взрослые, я же даже не знаю их сути. Вы можете спросить у моих
                родителей, наверное, они вам объяснят лучше.</p>
            

            
            <p>&nbsp;Батюшка смерил меня взглядом:</p>
            

            
            <p>&nbsp;- Можешь садиться.</p>
            

            
            <p>&nbsp;Я села, готовая заплакать. Одноклассница тихо
                взяла меня за руку. </p>
            

            
            <p>&nbsp;- Юля, села ровно и руки на стол!</p>
            

            
            <p>&nbsp;Когда прозвенел звонок, одноклассники сразу
                окружили меня:</p>
            

            
            <p>&nbsp;- Маша что он к тебе пристал?</p>
            

            
            <p>&nbsp;- Да что это с ним случилось!</p>
            

            
            <p>&nbsp;- Почему они снова трогают Елену Николаевну?</p>
            

            
            <p>&nbsp;- А ты тут причем? </p>
            

            
            <p>&nbsp;Меня ласково гладили по плечу, кто-то забрал
                мой рюкзак; в глазах ребят я видела испуг и сочувствие.</p>
            

            
            <p>&nbsp;- Я не знаю. Но, по ходу, теперь они и за меня
                принялись...</p>
            
        </div>
    );
};

export default HantyDeti;
